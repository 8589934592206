import { Node, Edge, Elements as FlowElements } from 'react-flow-renderer';
import { TBlockWithPosition } from '@conversed/shared/src/central/types/block.type';
import { useCallback } from 'react';
import { TNodeType } from '../../interfaces/TDesigner';
import { DEFAULT_NODE_POSITION } from '../../constants/flow';
import { useFlow } from './flow/useFlow';
import { EFlowDirection } from '../../interfaces/EFlowDirection';
import { parseEdge } from '../../helpers/flowHelper';
import { ENTRY_BLOCK_ID } from '@conversed/shared/src/central/constants/blocks';

const getNodeTypeByBlockType = (block: TBlockWithPosition): TNodeType => {
  switch (block.type) {
    case 'text':
    case 'textWithButtons':
      return 'textNode';
    case 'button':
      return 'buttonNode';
    case 'databaseUpdate':
    case 'databaseRead':
    case 'databaseDelete':
      return 'dataNode';
    case 'Decider':
      return 'routeNode';
    case 'entry':
      return 'entryNode';
    case 'image':
    case 'StandAloneImage':
      return 'imageNode';
    default:
      return 'flowNode';
  }
};

export const useFlowConversion = () => {
  const { flowDirection } = useFlow();

  const getBlockPositionByFlowDirection = useCallback(
    (block: TBlockWithPosition) => {
      if (flowDirection === EFlowDirection.Vertical) {
        return block.verticalPosition ?? null;
      }
      return block.horizontalPosition ?? null;
    },
    [flowDirection],
  );

  const translateBlockToReactFlowNode = useCallback(
    (block: TBlockWithPosition): Node<TBlockWithPosition> => {
      const { id } = block;

      return {
        id: id,
        type: getNodeTypeByBlockType(block),
        data: block,
        position: getBlockPositionByFlowDirection(block) ?? DEFAULT_NODE_POSITION,
      };
    },
    [getBlockPositionByFlowDirection],
  );

  const convertBlocksToNodes = useCallback(
    (blocks: TBlockWithPosition[]) =>
      blocks.filter((block) => block.type !== 'version').map(translateBlockToReactFlowNode),
    [translateBlockToReactFlowNode],
  );

  const createEdgesBetweenNodes = useCallback((blocks: TBlockWithPosition[]) => {
    const edges: Edge[] = [];
    blocks.forEach((block) => {
      const { id } = block;

      // * Add entry to flow editor and connect to block with isEntryBlock
      if (
        block.type !== 'version' &&
        block.type !== 'button' &&
        block.type !== 'entry' &&
        block.isEntryBlock === true
      ) {
        edges.push(parseEdge(`${ENTRY_BLOCK_ID}-${id}`, ENTRY_BLOCK_ID, id, 'entry'));
      }

      if ('destination' in block) {
        block?.destination?.forEach((destination: string) => {
          if (!destination) return;
          edges.push(parseEdge(`${id}-${destination}`, id, destination, 'destination'));
        });
      }

      if ('contains' in block) {
        block?.contains?.forEach((destination, index) => {
          if (!destination) return;
          edges.push(parseEdge(`${id}-${destination}-${index}`, id, destination, 'contains'));
        });
      }

      // Handle decider routing
      if (block.type === 'Decider') {
        block.params.possibleDestinations.map((possibleDestination, index) => {
          const destination = possibleDestination.blockId;
          edges.push(parseEdge(`${id}-${destination}-${index}`, id, destination, 'decider'));
        });
      }

      if ('validator' in block) {
        const { errorDestination } = block.validator;

        if (!errorDestination) {
          return;
        }

        if (typeof errorDestination !== 'object' || !Array.isArray(errorDestination)) {
          return;
        }

        if (Array.isArray(errorDestination)) {
          return errorDestination.map((destination: string | null, index: number) => {
            if (!destination) return;
            edges.push(parseEdge(`${id}-${destination}-${index}`, id, destination, 'validator', 'mid'));
          });
        }

        if (typeof errorDestination === 'object') {
          return Object.values(errorDestination).map((destination: unknown, index: number) => {
            if (!destination) return;
            if (typeof destination !== 'string') return;
            edges.push(parseEdge(`${id}-${destination}-${index}`, id, destination, 'validator', 'mid'));
          });
        }
      }
      // TODO: Handle other routing methods
    });

    return edges;
  }, []);

  const convertBlocksToNodesWithEdges = useCallback(
    (blocks: TBlockWithPosition[]): FlowElements<TBlockWithPosition> => [
      ...convertBlocksToNodes(blocks),
      ...createEdgesBetweenNodes(blocks),
    ],
    [convertBlocksToNodes, createEdgesBetweenNodes],
  );

  return { translateBlockToReactFlowNode, convertBlocksToNodesWithEdges };
};
