import React from 'react';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/SettingsCard.module.scss';

export type TSettingsDetails = {
  title: string;
  subtitle?: string;
  avatar?: string;
  label?: string;
};

export const SettingsCardDetails: React.FC<TSettingsDetails> = ({ title, subtitle, avatar, label }) => {
  const { cardImage, detailsWrapper, labelColor, imageDetailsWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={imageDetailsWrapper}>
      {avatar && <img src={avatar} className={cardImage} />}
      {label && <div className={labelColor} style={{ background: label }}></div>}
      {(title || subtitle) && (
        <div className={detailsWrapper}>
          {title && <h5>{title}</h5>}
          {subtitle && <p>{subtitle}</p>}
        </div>
      )}
    </div>
  );
};
