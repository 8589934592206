import React from 'react';
import { TRole } from '@conversed/shared/src/central/types/role.types';
import useRoleActions from '../../../hooks/settings/roles/useRoleActions';
import style from '../../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';

interface Props {
  permission: string;
  role: TRole;
}

export const RolePermissionColumn: React.FC<Props> = ({ permission, role }) => {
  const { togglePermissionForRole } = useRoleActions();
  const { cvSwitch, cvSlider } = convertKeysToCamelCase(style);
  const permissionPresentInRole = !!role.permissions.find((permissionInRole) => permission === permissionInRole);

  return (
    <td>
      <label className={cvSwitch}>
        <input
          type="checkbox"
          checked={permissionPresentInRole}
          onChange={() => togglePermissionForRole(permission, role)}
        />
        <span className={cvSlider}></span>
      </label>
    </td>
  );
};

export default RolePermissionColumn;
