import { useCallback, useMemo } from 'react';
import { API_ENDPOINT } from '../constants/env';
import { useMainSelector } from './useMainSelector';

export const useFileMedia = () => {
  const {
    user: { currentUser },
  } = useMainSelector();

  const companyId = useMemo(() => {
    return currentUser?.selectedCompanyId;
  }, [currentUser]);

  const getImageByURL = useCallback(
    (imageUrl?: string | null, explicitCompanyId?: string) => {
      if (!imageUrl) {
        return '';
      }

      if (imageUrl?.startsWith('media/')) {
        return `${API_ENDPOINT}/${imageUrl}?companyId=${explicitCompanyId || companyId}`;
      }

      return imageUrl;
    },
    [companyId],
  );

  return {
    getImageByURL,
  };
};
