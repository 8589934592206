import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useInboxes } from '../../../../hooks/settings/inboxes/useInboxes';
import { useInboxActions } from '../../../../hooks/settings/inboxes/useInboxActions';
import { useTranslation } from 'react-i18next';

export const DeleteInboxModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { selectedInbox } = useInboxes();
  const { deleteInbox } = useInboxActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {selectedInbox && (
        <Modal.Body>
          <p className={cvRow}>
            {t('settings.inbox.delete.confirmation')} <b>{selectedInbox.name}</b>?
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteInbox(selectedInbox.identifier);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteInboxModal;
