import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { Label } from '../Label';
import style from '../../../assets/scss/components/chat/chat-panel-sidebar/ChatPanelSidebar.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useLabelSelector } from '../../../hooks/chat/useLabelSelector';
import { LabelSelectorDropdown } from './LabelSelectorDropdown';
import { useTranslation } from 'react-i18next';
import { useConversationLabelActions } from '../../../hooks/chat/useConversationLabelActions';

export const LabelSelector: React.FC = () => {
  const { selectedLabels } = useLabelSelector();
  const { removeLabelFromConversation } = useConversationLabelActions();

  const { t } = useTranslation();

  const { conversationLabels } = convertKeysToCamelCase(style);
  return (
    <div className={conversationLabels}>
      <div>
        <MaterialIcon icon={'bookmark'} />
        <p>{t('chat.panel.sidebar.label-selector.title')}</p>
        <LabelSelectorDropdown />
      </div>
      <div className="d-flex flex-wrap my-2">
        {selectedLabels?.length > 0 ? (
          selectedLabels.map((label) => (
            <Label
              key={label.identifier}
              labelName={label.labelName}
              labelColor={label.labelColor}
              onRemove={() => removeLabelFromConversation(label.identifier)}
            />
          ))
        ) : (
          <span>
            <small>{t('chat.panel.sidebar.label-selector.no-label')}</small>
          </span>
        )}
      </div>
    </div>
  );
};
