import React from 'react';

type TProps = {
  title: string;
  onClickInfo?: CallableFunction;
};

export const ContentSubTitle: React.FC<TProps> = ({ title }) => {
  return (
    <div className="d-flex flex-col align-items-center mt-2">
      <h5 className="mb-4">{title}</h5>
    </div>
  );
};

export default ContentSubTitle;
