import React from 'react';
import ContentTitle from './common/ContentTitle';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/pages/LegalPages.module.scss';
import { useTranslation } from 'react-i18next';

export const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation();

  const { legalPageContent, listTitle } = convertKeysToCamelCase(style);

  return (
    <>
      <ContentTitle
        title={t('terms-and-conditions.title')}
        onClickInfo={() =>
          // TODO: Implement terms and conditions info modal
          alert('Implement terms and conditions info modal')
        }
      />
      <div className={legalPageContent}>
        <h5>{t('lorem.heading')}</h5>
        <p>{t('lorem.paragraph')}</p>
        <p className={listTitle}>{t('lorem.heading')}</p>
        <ul>
          <li>{t('lorem.paragraph')}</li>
          <li>{t('lorem.paragraph')}</li>
          <li>{t('lorem.paragraph')}</li>
          <li>{t('lorem.paragraph')}</li>
        </ul>
      </div>
    </>
  );
};
export default TermsAndConditions;
