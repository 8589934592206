import React from 'react';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/settings-page/SettingsCard.module.scss';
import { SettingsCardActions, TSettingsActions } from './SettingsCardActions';
import { SettingsCardDetails, TSettingsDetails } from './SettingsCardDetails';
import { useTranslation } from 'react-i18next';

type TSettingsCard = TSettingsDetails &
  TSettingsActions & {
    button?: {
      title: string;
      action?: CallableFunction;
    };
  };

export const SettingsCard: React.FC<TSettingsCard> = ({ title, subtitle, avatar, label, actions, button }) => {
  const { settingsCard } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={settingsCard}>
      <SettingsCardDetails title={title} subtitle={subtitle} avatar={avatar} label={label} />
      {button && <button className="btn btn-primary">{button?.title ?? t('settings.card.button.default')}</button>}
      <SettingsCardActions actions={actions} />
    </div>
  );
};

export default SettingsCard;
