import union from 'lodash/union';

export const replaceValueInList = <T>(value: T, list: T[], condition: (value: T) => boolean) => {
  const valueIndex = list.findIndex(condition);

  // * Add or replace a user based on whether they exist in the chat list
  if (valueIndex > -1) {
    // * Replace user with the one from the backend
    const newList = [...list.slice(0, valueIndex), value, ...list.slice(valueIndex + 1)];
    return newList;
  }

  return addValueToList<T>(value, list);
};

export const addValueToList = <T>(value: T, list?: T[]) => {
  if (!list) {
    return [value];
  }

  return [...list, value];
};

export const removeValueFromList = <T>(condition: (value: T) => boolean, list?: T[]) => {
  if (!list) {
    return [];
  }

  const valueIndex = list.findIndex(condition);

  // * Add or replace a user based on whether they exist in the chat list
  if (valueIndex > -1) {
    // * Replace user with the one from the backend
    const newList = [...list.slice(0, valueIndex), ...list.slice(valueIndex + 1)];
    return newList;
  }

  return list;
};

export const pushUnique = <T>(value: T, list?: T[]) => {
  if (!list) {
    return [value];
  }

  if (list.indexOf(value) === -1) {
    return [...list, value];
  }

  return list;
};

export const pushUniques = <T>(values: T[], list?: T[]) => union(list, values);

export const removeObjectValuesFromList = <T>(
  valueIds: Array<string>,
  list: T[],
  property: keyof T,
) => {
  if (!list) return [];
  return list.filter((listValue) => {
    const propertyValue = listValue[property];

    if (typeof propertyValue !== 'string') return true;
    return !valueIds.includes(propertyValue);
  });
};

export const replaceValuesInList = <T>(values: Array<T>, list: T[], property: keyof T) => {
  if (!list) return values;

  const replacedValues = list.map((listValue) => {
    const possibleValue = values.find((value) => value[property] === listValue[property]);
    if (!possibleValue) return listValue;
    return possibleValue;
  });

  // Check all elements in values that don't exist on list and add them
  const restList = values.filter(
    (value) => !list.some((listValue) => value[property] === listValue[property]),
  );

  // Combine the lists
  return [replacedValues, restList].flat();
};

export const mapObject = <T>(
  obj: Record<string, T>,
  mapFunction: (entry: [string, T]) => unknown,
) => Object.assign({}, ...Object.entries(obj).map(mapFunction));

type Spec = Record<string, (...args: readonly any[]) => (...args: readonly any[]) => any>;

// * Apply spec method from ramdag
export const applySpec = <T extends Spec>(
  spec: T,
  arg: any,
): { [Key in keyof T]: ReturnType<T[Key]> } =>
  mapObject(spec, ([keyOfSpec, specFunction]) => {
    return { [keyOfSpec]: specFunction(arg) };
  });

export const turnMapToObject = (map: Map<string, unknown>): Record<string, unknown> => {
  const obj = Array.from(map).reduce<Record<string, unknown>>((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
  return obj;
};

export const turnObjectToMap = (obj: Record<string, unknown>): Map<string, unknown> => {
  return new Map(Object.entries(obj));
};

export const removeObjectKey = (obj: any, key: string) => {
  const objCopy = { ...obj };
  delete objCopy[key];
  return objCopy;
};

export function callsites() {
  // eslint-disable-next-line no-underscore-dangle
  const _prepareStackTrace = Error.prepareStackTrace;
  Error.prepareStackTrace = (_, stack) => stack;
  const stack = new Error().stack?.slice(1);
  Error.prepareStackTrace = _prepareStackTrace;
  return stack;
}
