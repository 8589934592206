import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { useMfaSettings } from '../../../../hooks/auth/useMFASettings';
import { useMfaEnableForm } from '../../../../hooks/settings/account/useMFAEnableForm';

export const Enable2FaModal: React.FC = () => {
  const { qrCode, name, key } = useMfaSettings();
  const { register, handleSubmit } = useMfaEnableForm();

  const { t } = useTranslation();

  const { cvRow, cvFooter, input } = convertKeysToCamelCase(style);

  return (
    <Modal.Body>
      <p>{t('account-settings.2fa.modals.enable.instructions')}</p>
      <Row>
        <Col md={4}>
          <img src={qrCode}></img>
        </Col>
        <Col md={8}>
          <Row>
            <Col xs={2}>
              <p>{t('account-settings.2fa.modals.enable.labels.name')}:</p>
            </Col>
            <Col xs={10}>
              <p>{name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <p>{t('account-settings.2fa.modals.enable.labels.type')}:</p>
            </Col>
            <Col xs={10}>
              <p>{t('account-settings.2fa.modals.enable.time-based')}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <p>{t('account-settings.2fa.modals.enable.labels.key')}:</p>
            </Col>
            <Col xs={10}>
              <p className="break-word">{key}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group className={cvRow}>
            <p>{t('account-settings.2fa.modals.enable.token')}</p>
            <Form.Control className={input} required {...register('token')} minLength={6} maxLength={6} />
          </Form.Group>
        </Row>
        <Modal.Footer className={cvFooter}>
          <Button type="submit">{t('account-settings.2fa.modals.enable.enable')}</Button>
        </Modal.Footer>
      </Form>
    </Modal.Body>
  );
};

export default Enable2FaModal;
