import { objectDeepMapPromise } from './objectHelpers';

export function loadFileFromReader(files: File | FileList): Promise<string | ArrayBuffer> {
  if (files instanceof FileList) {
    return loadFileFromReader(files[0]);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(files);

    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      }
    };

    reader.onerror = () => reject(new Error('There was an error uploading the file'));
  });
}

export const fileToBase64File = async (file: File) => ({
  name: file.name,
  type: file.type,
  size: file.size,
  base64: await loadFileFromReader(file),
});

export const fileListToBase64File = (files: FileList) => Promise.all(Array.from(files).map(fileToBase64File));

export const convertDataFilesToBase64Format = async (data: any) => {
  return objectDeepMapPromise(data, async (value: any) => {
    if (value instanceof FileList) {
      const file = await fileToBase64File(value[0]);
      return file;
    }

    return value;
  });
};
