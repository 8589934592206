import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useCannedResponses } from '../../hooks/chat/useCannedResponses';
import { useCannedResponsesInit } from '../../hooks/chat/useCannedResponsesInit';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const CannedResponsesSettings: React.FC = () => {
  useCannedResponsesInit();
  const { cannedResponses, setSelectedCannedResponse } = useCannedResponses();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  return (
    <>
      <ContentTitle title={t('canned-response-settings.title')} onClickInfo={() => showModal('canned-response-info')} />
      {cannedResponses?.length ? (
        <>
          {hasPermissions([PERMISSIONS.CANNED_RESPONSE.CANNED_RESPONSE_CREATE]) && (
            <AddButton
              text={t('canned-response-settings.add-response')}
              onClickAdd={() => showModal('add-canned-response')}
            />
          )}
          <SettingsCardContainer>
            {cannedResponses.map((response, index) => {
              const removeAction = hasPermissions([PERMISSIONS.CANNED_RESPONSE.CANNED_RESPONSE_REMOVE])
                ? [
                    {
                      icon: 'delete',
                      action: () => {
                        showModal('delete-canned-response');
                        setSelectedCannedResponse(response);
                      },
                    },
                  ]
                : [];
              const editAction = hasPermissions([PERMISSIONS.CANNED_RESPONSE.CANNED_RESPONSE_EDIT])
                ? [
                    {
                      icon: 'border_color',
                      action: () => {
                        showModal('edit-canned-response');
                        setSelectedCannedResponse(response);
                      },
                    },
                  ]
                : [];
              return (
                <SettingsCard
                  key={index}
                  title={`/${response.shorthand}`}
                  subtitle={response.message}
                  actions={[...editAction, ...removeAction]}
                />
              );
            })}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={t('canned-response-settings.empty-state')}
          addButton={() => showModal('add-canned-response')}
        />
      )}
    </>
  );
};
export default CannedResponsesSettings;
