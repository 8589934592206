import React from 'react';
import { Control } from 'react-hook-form';
import TextInput from '../../../../input/TextInput';
import DropDownInput from '../../../../input/DropDownInput';
import { useTranslation } from 'react-i18next';
import { MenuTemplate } from '@conversed/shared/src/central/types/webclient-config.type';
import { useMainSelector } from '../../../../../hooks/useMainSelector';

type Props = {
  name: string;
  watch: any;
  control: Control<MenuTemplate>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const WebclientMenuList: React.FC<Props> = ({ name, watch, control, ...rest }) => {
  const { t } = useTranslation();
  const payloadType: 'payload' | 'webLink' = watch(`${name}.type`);
  const {
    designer: { entryPoints: validDestinations },
  } = useMainSelector();

  return (
    <div {...rest}>
      <DropDownInput
        horizontal
        label={t('settings.webclient.config.form.config.menu.type')}
        name={`${name}.type`}
        register={control.register}
        values={['payload', 'webLink']}
      />
      <TextInput
        horizontal
        label={t('settings.webclient.config.form.config.menu.title')}
        name={`${name}.title.en`}
        register={control.register}
      />
      {payloadType === 'webLink' ? (
        <TextInput
          horizontal
          label={t('settings.webclient.config.form.config.menu.payload')}
          name={`${name}.payload`}
          register={control.register}
        />
      ) : (
        <DropDownInput
          horizontal
          isEnabled={!!validDestinations?.length}
          label={t('settings.webclient.config.form.config.menu.payload')}
          name={`${name}.payload`}
          register={control.register}
          values={validDestinations}
        />
      )}
    </div>
  );
};

export default WebclientMenuList;
