import { useEffect } from 'react';
import { useCompanySelector } from '../../useCompanySelector';
import { useCompanySettingsActions } from './useCompanyActions';

export const useGeneralSettingsInit = () => {
  const { fetchGeneralSettings } = useCompanySettingsActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchGeneralSettings();
  }, [fetchGeneralSettings, selectedCompany]);
};
