import { TChatMode } from '../../interfaces/TChat';
import { useQueryState } from '../useQueryState';

const CHAT_MODE_QUERY_PARAM_KEY = 'chatMode';

export const useChatMode = () => {
  const { query, setQuery } = useQueryState(CHAT_MODE_QUERY_PARAM_KEY, 'reply');

  return {
    chatMode: query as TChatMode,
    setChatMode: (chatMode: TChatMode) => setQuery(chatMode),
  };
};
