import { TSettingsSidebarElement } from '../../interfaces/ISettings';
import { AgentSettings } from '../settings-page/AgentSettings';
import { InboxSettings } from '../settings-page/InboxSettings';
import { LabelSettings } from '../settings-page/LabelSettings';
import { CannedResponsesSettings } from '../settings-page/CannedResponsesSettings';
import WebclientSettings from '../settings-page/WebclientSettings';
import FacebookSettings from '../settings-page/FacebookSettings';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import MemberSettings from '../settings-page/MemberSettings';
import GeneralSettings from '../settings-page/GeneralSettings';
import ConnectionSettings from '../settings-page/ConnectionSettings';
import { RolesSettings } from '../settings-page/RolesSettings';
import LinkSettings from '../settings-page/LinkSettings';

export const SettingsSidebarRoutes: TSettingsSidebarElement[] = [
  {
    icon: 'chat',
    title: 'settings.sidebar.conversation',
    path: 'conversations',
    elements: [
      {
        text: 'settings.sidebar.conversation.agents',
        path: 'agents',
        component: AgentSettings,
        permissions: [PERMISSIONS.AGENT.AGENT_LIST],
      },
      {
        text: 'settings.sidebar.conversation.inboxes',
        path: 'inboxes',
        component: InboxSettings,
        permissions: [PERMISSIONS.INBOX.INBOX_LIST],
      },
      {
        text: 'settings.sidebar.conversation.labels',
        path: 'labels',
        component: LabelSettings,
        permissions: [PERMISSIONS.LABEL.LABEL_LIST],
      },
      {
        text: 'settings.sidebar.conversation.canned-responses',
        path: 'canned-responses',
        component: CannedResponsesSettings,
        permissions: [PERMISSIONS.CANNED_RESPONSE.CANNED_RESPONSE_LIST],
      },
    ],
  },
  {
    icon: 'integration_instructions',
    title: 'settings.sidebar.integrations',
    path: 'integrations',
    elements: [
      {
        text: 'settings.sidebar.integrations.webclient',
        path: 'webclient',
        component: WebclientSettings,
        permissions: [PERMISSIONS.WEBCLIENT.WEBCLIENT_CONFIG_LIST],
      },
      {
        text: 'settings.sidebar.integrations.facebook',
        path: 'facebook',
        component: FacebookSettings,
        permissions: [PERMISSIONS.FACEBOOK.FACEBOOK_LIST],
      },
      // {
      //   text: 'Viber',
      //   path: 'viber',
      // },
      // {
      //   text: 'KakaoTalk',
      //   path: 'kakao-talk',
      // },
      // {
      //   text: 'WhatsApp',
      //   path: 'whatsapp',
      // },
      // {
      //   text: 'Microsoft Teams',
      //   path: 'microsoft-teams',
      // },
    ],
  },
  {
    icon: 'settings',
    title: 'settings.sidebar.platform-settings',
    path: 'platform-settings',
    elements: [
      {
        text: 'settings.sidebar.platform-settings.connection',
        path: 'connection',
        component: ConnectionSettings,
        permissions: [PERMISSIONS.COMPANY.COMPANY_CONNECTION_SETTINGS_READ],
      },
      {
        text: 'settings.sidebar.platform-settings.general',
        path: 'general',
        component: GeneralSettings,
        permissions: [PERMISSIONS.COMPANY.COMPANY_GENERAL_SETTINGS_READ],
      },
      {
        text: 'settings.sidebar.platform-settings.members',
        path: 'members',
        component: MemberSettings,
        permissions: [PERMISSIONS.COMPANY.COMPANY_MEMBERS_MANAGE],
      },
      {
        text: 'settings.sidebar.platform-settings.roles',
        path: 'roles',
        component: RolesSettings,
        permissions: [PERMISSIONS.ROLE.LIST_ROLES, PERMISSIONS.ROLE.EDIT_ROLES],
      },
    ],
  },
  {
    icon: 'handyman',
    title: 'settings.sidebar.tools',
    path: 'tools',
    elements: [
      {
        text: 'settings.sidebar.tools.links',
        path: 'links',
        component: LinkSettings,
        permissions: [PERMISSIONS.LINK.MANAGE_LINKS],
      },
    ],
  },
];
