import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from '../../assets/scss/components/pages/CompanySelectorPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import conversedLogo from '../../assets/images/logo_black.png';
import { useCompanySelector } from '../../hooks/useCompanySelector';
import CompanyCard from '../auth/CompanyCard';
import { NEW_CUSTOMER_ROUTE } from '../../constants/routes';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { Button } from 'react-bootstrap';
import { useLogin } from '../../hooks/auth/useLogin';
import AddButton from '../common/AddButton';

export const CompanySelectorPage: React.FC = () => {
  const { wrapper, content, copyright, title, cardContainer, contentWrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const { logout } = useLogin();

  const { companies, setSelectedCompany } = useCompanySelector();
  const { hasPermissions } = usePermissions();

  return (
    <div className={`${wrapper} `}>
      <div>
        <div className={`w-100 d-flex justify-content-center align-items-center ${contentWrapper}`}>
          <div className={`w-100 h-100 d-flex justify-content-center flex-column align-items-center ${content}`}>
            <img src={conversedLogo} />
            {companies && companies.length > 0 ? (
              <>
                <div className={`${title} mx-3`}>{t('company-selector.title')}</div>
                <div className={`w-100 ${cardContainer} `}>
                  {companies?.map((company) => (
                    <CompanyCard
                      key={company.identifier}
                      name={company.name}
                      iconUrl={company.icon}
                      url={company.websiteUrl}
                      onPress={() => setSelectedCompany(company)}
                    />
                  ))}
                  {hasPermissions([PERMISSIONS.COMPANY.COMPANY_CREATE]) && (
                    <div className="d-flex align-items-center justify-content-center p-2">
                      <Link to={NEW_CUSTOMER_ROUTE}>
                        <AddButton />
                      </Link>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={`${title} mx-3`}>{t('company-selector.no-customer-error')}</div>
                <Button onClick={() => logout()}>{t('company-selector.logout')}</Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={copyright}>{t('general.copyright.label', { date: new Date().getFullYear().toString() })}</div>
    </div>
  );
};
