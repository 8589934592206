import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useLabels } from '../../hooks/settings/labels/useLabels';
import { useLabelInit } from '../../hooks/settings/labels/useLabelInit';
import { useLabelActions } from '../../hooks/settings/labels/useLabelActions';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const LabelSettings: React.FC = () => {
  useLabelInit();
  const { labels } = useLabels();
  const { setSelectedLabel } = useLabelActions();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  return (
    <>
      <ContentTitle title={t('label-settings.title')} onClickInfo={() => showModal('info-label')} />
      {labels?.length > 0 ? (
        <>
          {hasPermissions([PERMISSIONS.LABEL.LABEL_CREATE]) && (
            <AddButton text={t('label-settings.add-inbox')} onClickAdd={() => showModal('add-label')} />
          )}
          <SettingsCardContainer>
            {labels.map((label) => {
              const removeAction = hasPermissions([PERMISSIONS.LABEL.LABEL_REMOVE])
                ? [
                    {
                      icon: 'delete',
                      action: () => {
                        setSelectedLabel(label);
                        showModal('delete-label');
                      },
                    },
                  ]
                : [];
              const editAction = hasPermissions([PERMISSIONS.LABEL.LABEL_EDIT])
                ? [
                    {
                      icon: 'border_color',
                      action: () => {
                        setSelectedLabel(label);
                        showModal('edit-label');
                      },
                    },
                  ]
                : [];
              return (
                <SettingsCard
                  key={label.identifier}
                  title={label.labelName}
                  label={label.labelColor}
                  actions={[...editAction, ...removeAction]}
                />
              );
            })}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState message={t('label-settings.empty-state')} addButton={() => showModal('add-label')} />
      )}
    </>
  );
};
export default LabelSettings;
