import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTime } from '../../../hooks/useTime';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/MembersPage.module.scss';

interface Props {
  grantedAt?: Date;
  grantedBy: string;
}

export const MemberGrantedColumn: React.FC<Props> = ({ grantedAt, grantedBy }) => {
  const { relativeDate } = useTime(grantedAt);
  const { t } = useTranslation();
  const { cvGranted } = convertKeysToCamelCase(style);

  return (
    <div className={cvGranted}>
      <p>{grantedAt ? `${relativeDate} ` : ''}</p>
      <p>
        {t('members-settings.granted-message', {
          grantedBy,
        })}
      </p>
    </div>
  );
};

export default MemberGrantedColumn;
