import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import MaterialIcon from '../../../../common/MaterialIcon';
import { TFacebookSettings } from '../../../../../interfaces/TFacebook';
import FacebookCallToAction from './FacebookCallToAction';
import { MAX_CALL_TO_ACTION_ITEMS_COUNT } from '@conversed/shared/src/central/constants/boundaries';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import style from '../../../../../assets/scss/components/common/Modal.module.scss';

type Props = {
  name: string;
  control: Control<TFacebookSettings>;
};

export const FacebookCallToActionList: React.FC<Props> = ({ name, control }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name as any,
  });
  const { cvAddDelete, addOnly } = convertKeysToCamelCase(style);
  return (
    <div>
      {fields.map((field, actionId) => (
        <div key={field.id} className="d-flex flex-column">
          <FacebookCallToAction className="flex-grow-1" name={`${name}.${actionId}`} control={control} />
          {fields.length > 1 && (
            <div className={`${cvAddDelete}`} onClick={() => remove(actionId)} role="button">
              <MaterialIcon icon="delete" />
              <p>{t('settings.facebook.persistent-menu.delete-action')}</p>
            </div>
          )}
        </div>
      ))}
      {fields.length < MAX_CALL_TO_ACTION_ITEMS_COUNT && (
        <div className={`${cvAddDelete} ${addOnly}`} onClick={() => append({})} role="button">
          <MaterialIcon icon="add_circle" />
          <p>{t('settings.facebook.persistent-menu.add-action')}</p>
        </div>
      )}
    </div>
  );
};

export default FacebookCallToActionList;
