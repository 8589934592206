import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import style from '../../../../../assets/scss/components/common/Modal.module.scss';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import MaterialIcon from '../../../../common/MaterialIcon';
import WebclientMenuList from './WebclientMenuList';

type Props = {
  label: string;
  name: string;
  watch: any;
  control: Control<any>;
};

// * Arbitrary limit, discuss with the team whether to limit and how many items we want
const MENU_ENTRY_LIMIT = 3;

export const WebclientMenu: React.FC<Props> = ({ label, watch, control, name }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name as any,
  });

  const { cvAddDelete, addOnly } = convertKeysToCamelCase(style);

  return (
    <div>
      {label}
      {fields.map((field, actionId) => (
        <div key={field.id} className="d-flex flex-column">
          <WebclientMenuList className="flex-grow-1" name={`${name}.${actionId}`} control={control} watch={watch} />
          {fields.length > 0 && (
            <div className={`${cvAddDelete}`} onClick={() => remove(actionId)} role="button">
              <MaterialIcon icon="delete" />
              <p>{t('settings.webclient.config.form.config.menu.delete-action')}</p>
            </div>
          )}
        </div>
      ))}
      {fields.length < MENU_ENTRY_LIMIT && (
        <div className={`${cvAddDelete} ${addOnly}`} onClick={() => append({})} role="button">
          <MaterialIcon icon="add_circle" />
          <p>{t('settings.webclient.config.form.config.menu.add-action')}</p>
        </div>
      )}
    </div>
  );
};
