import React from 'react';
import { Form } from 'react-bootstrap';
import { IInputProps } from '../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/common/Modal.module.scss';

export const NumberInput: React.FC<IInputProps> = ({ label, name, register }) => {
  const { modalLabel, inputField } = convertKeysToCamelCase(style);

  return (
    <Form.Group>
      <p className={modalLabel}>{label}</p>
      <Form.Control className={inputField} {...register(name)} />
    </Form.Group>
  );
};

export default NumberInput;
