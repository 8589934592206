import { useCallback } from 'react';
import { TChatHistory } from '../../interfaces/IMessage';
import { useTime } from '../useTime';

export const useChatTimestamp = (messages: TChatHistory[]) => {
  const { timeDiff } = useTime();

  const canShowTimestamp = useCallback(
    (message: TChatHistory, index: number) => {
      // * Do not add timestamp on action log message
      if (message.type === 'meta') {
        return false;
      }
      // * Always timestamp the last message in a group
      if (index === messages.length - 1) {
        return true;
      }

      // * Timestamp in a group if the difference between two messages is greater than 1 minute
      return index > 0 && timeDiff('minutes', messages[index - 1].time, message.time) > 1;
    },
    [messages, timeDiff],
  );

  return { canShowTimestamp };
};
