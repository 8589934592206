import { AuthenticatedAppRoutes } from '../routes/AuthenticatedAppRoutes';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IRoute, TRouteType } from '../../interfaces/IRoutes';
import { RestrictedRoute } from '../routes/RestrictedRoute';
import { SettingsSidebarRouter } from '../settings-page/SettingsContent';
import { useMedia } from '../../hooks/useMedia';
import { EBreakpoint } from '../../constants/breakpoints';
import { CHAT_PAGE_ROUTE, MAIN_PAGE_ROUTE } from '../../constants/routes';
import { NotFoundPage } from '../pages/NotFoundPage';

export const AuthenticatedRouter: React.FC = () => {
  const { down: isMobile } = useMedia(EBreakpoint.MD);

  return (
    <Switch>
      <Route path={MAIN_PAGE_ROUTE} render={() => <Redirect to={CHAT_PAGE_ROUTE} />} exact />
      {AuthenticatedAppRoutes(isMobile).map((route: IRoute) => {
        const { path, component, type, permissions, exact } = route;

        const routeTypeMap: Record<TRouteType, (route: IRoute) => void> = {
          restricted: ({ path, component, permissions, exact }) => (
            <RestrictedRoute
              key={path}
              requiredPermissions={permissions}
              path={path}
              component={component}
              exact={exact}
            />
          ),
          default: ({ path, component, exact }) => <Route key={path} path={path} component={component} exact={exact} />,
        };

        const routeType = type || 'default';
        return routeTypeMap[routeType]({ path, component, type, permissions, exact });
      })}

      {isMobile && <SettingsSidebarRouter />}

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
