import { Actions } from '../../constants/actionTypes';
import { useCallback } from 'react';
import { useMainSelector } from '../useMainSelector';
import { useMainDispatch } from '../useMainDispatch';
import { TCannedResponse } from '../../interfaces/TChat';

export const useCannedResponses = () => {
  const {
    chat: { cannedResponses },
    settings: { selectedCannedResponse },
  } = useMainSelector();

  const dispatch = useMainDispatch();

  const setSelectedCannedResponse = useCallback(
    (cannedResponse: TCannedResponse) => {
      dispatch({ type: Actions.SET_SETTINGS, payload: { selectedCannedResponse: cannedResponse } });
    },
    [dispatch],
  );

  return {
    cannedResponses,
    setSelectedCannedResponse,
    selectedCannedResponse,
  };
};
