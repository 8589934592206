import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useChatList } from '../../../hooks/chat/useChatList';
import { Button, Form, FormControl } from 'react-bootstrap';
import { ChatSidebarGroupDropdown } from './ChatSidebarGroupDropdown';
import ChatSidebarStatusDropdown from './ChatSidebarStatusDropdown';
import ChatSidebarLabelDropdown from './ChatSidebarLabelDropdown';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../../common/MaterialIcon';

export const ChatSidebarHeader: React.FC = () => {
  const {
    selectedChatList,
    assignedUserCount,
    unassignedUserCount,
    setSelectedChatList,
    setSearchString,
    searchLabel,
    searchString,
    setSearchLabel,
  } = useChatList();
  const { t } = useTranslation();

  const { chatlistHeader, chatlistTopHeader, chatlistSearch, chatlistTabs, activeTab, searchSubmit } =
    convertKeysToCamelCase(style);

  return (
    <div className={chatlistHeader}>
      <div className={chatlistTopHeader}>
        <ChatSidebarGroupDropdown />
        <ChatSidebarStatusDropdown />
      </div>
      <div className={chatlistSearch}>
        <ChatSidebarLabelDropdown searchLabel={searchLabel} setSearchLabel={setSearchLabel} />
        <Form className="d-flex">
          <FormControl
            type="search"
            placeholder={t('chat.sidebar.header.search')}
            className="mr-2"
            defaultValue={searchString}
            aria-label="Search"
            onChange={(text) => {
              setSearchString(text.target.value);
            }}
          />
          <Button className={searchSubmit} variant="outline-success">
            <MaterialIcon icon={'search'} />
          </Button>
        </Form>
      </div>
      <div className={chatlistTabs}>
        <div
          className={selectedChatList === 'assigned-to-me' ? activeTab : ''}
          role="button"
          onClick={() => {
            setSelectedChatList('assigned-to-me');
          }}
        >
          <span>{t('chat.sidebar.header.tabs.assigned-to-me')}</span>
          <span>{assignedUserCount}</span>
        </div>
        <div
          className={selectedChatList === 'unassigned' ? activeTab : ''}
          role="button"
          onClick={() => {
            setSelectedChatList('unassigned');
          }}
        >
          <span>{t('chat.sidebar.header.tabs.unassigned')}</span>
          <span>{unassignedUserCount}</span>
        </div>
      </div>
    </div>
  );
};
