import React from 'react';
import chatAvatar from '../../../../assets/images/chat_avatar.png';
import style from '../../../../assets/scss/components/header/dropdown/ProfileDropdown.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { Link } from 'react-router-dom';
import { useMainSelector } from '../../../../hooks/useMainSelector';
import { useCompanySelector } from '../../../../hooks/useCompanySelector';
import { ACCOUNT_PAGE_ROUTE, SELECT_CUSTOMER_ROUTE, LOGOUT_PAGE_ROUTE } from '../../../../constants/routes';
import { Dropdown } from 'react-bootstrap';
import MaterialIcon from '../../../common/MaterialIcon';
import { useTranslation } from 'react-i18next';
import { useLanguageSwitch } from '../../../../hooks/useLangaugeSwitcher';

export const ProfileDropdown: React.FC = () => {
  const {
    user: { currentUser },
  } = useMainSelector();

  const { t } = useTranslation();

  const {
    profileDropdown,
    button,
    menu,
    itemHeader,
    item,
    divider,
    userNameType,
    cvUser,
    userAvatar,
    wrapperTop,
    cvStatus,
    accountSettings,
    chooseCustomer,
    chooseLanguage,
    logOut,
    itemToggler,
    chooseLanguageMenu,
  } = convertKeysToCamelCase(style);

  const { selectedCompany } = useCompanySelector();
  const { languageSwitchers, switchLanguage } = useLanguageSwitch();

  return (
    <Dropdown>
      <Dropdown.Toggle className={`${profileDropdown} ${button}`}>
        <div className={cvUser}>
          <img src={currentUser?.profilePic || chatAvatar} className={userAvatar} />
          <div className={userNameType}>
            {currentUser?.firstName}
            {/* <span>{t('profile.user.role.admin')}</span> */}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${profileDropdown} ${menu}`}>
        <Dropdown.Item eventKey="4" className={itemHeader}>
          <div className={wrapperTop}>
            <div className={cvUser}>
              <img src={currentUser?.profilePic ?? chatAvatar} className={userAvatar} />
              <div className={userNameType}>
                {currentUser?.firstName}
                {/* <span>{t('profile.user.role.admin')}</span> */}
              </div>
            </div>
            <span className={cvStatus}>{t('profile.user.status.online')}</span>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} />

        <Dropdown.Item as={Link} to={ACCOUNT_PAGE_ROUTE} eventKey="4" className={item}>
          <div className={accountSettings}>
            <MaterialIcon icon={'settings'} />
            <span>{t('profile.dropdown.actions.account-settings')}</span>
          </div>
        </Dropdown.Item>

        <Dropdown.Item as={Link} to={SELECT_CUSTOMER_ROUTE} eventKey="4" className={item}>
          <div className={chooseCustomer}>
            <MaterialIcon icon={'switch_account'} />
            <span>{t('profile.dropdown.actions.choose-customer', { customer: selectedCompany?.name })}</span>
          </div>
        </Dropdown.Item>
        <Dropdown>
          <Dropdown.Toggle className={itemToggler} id="dropdown-basic">
            <div className={chooseLanguage}>
              <MaterialIcon icon={'translate'} />
              <span>{t('profile.dropdown.actions.choose-language')}</span>
              <MaterialIcon icon={'arrow_drop_down'} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className={chooseLanguageMenu}>
            {languageSwitchers.map((languageSwitcher, index) => {
              const { displayText, language } = languageSwitcher;

              return (
                <Dropdown.Item key={index} eventKey="4" onClick={() => switchLanguage(language)}>
                  <span>{t(displayText)}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown.Divider className={divider} />

        <Dropdown.Item as={Link} to={LOGOUT_PAGE_ROUTE} eventKey="4" className={item}>
          <div className={logOut}>
            <MaterialIcon icon={'logout'} />
            <span>{t('profile.dropdown.actions.log-out')}</span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
