import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/BlocksSearch.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useFlowSearch } from '../../../hooks/flow/useFlowSearch';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { useTranslation } from 'react-i18next';

export const BlocksSearch: React.FC = () => {
  const { searchStatus, search, button, container } = convertKeysToCamelCase(style);
  const { getNextMatch, getPreviousMatch, searchString, setSearchString, blockSearchMatchStatus } = useFlowSearch();
  const { t } = useTranslation();

  return (
    <>
      <div className={container}>
        {blockSearchMatchStatus && <div className={searchStatus}>{blockSearchMatchStatus}</div>}
        <input
          onChange={(e) => setSearchString(e?.target?.value ?? '')}
          className={search}
          type={'text'}
          value={searchString}
          placeholder={t('flow.blocks.search.desc')}
        />
        <GeneralTooltip id={'tooltip-flow-prev-match-button'} text={t('flow.blocks.search.prev')}>
          <div className={button} onClick={() => getPreviousMatch()}>
            <MaterialIcon icon={'chevron_left'} />
          </div>
        </GeneralTooltip>
        <GeneralTooltip id={'tooltip-flow-next-match-button'} text={t('flow.blocks.search.next')}>
          <div className={button} onClick={() => getNextMatch()}>
            <MaterialIcon icon={'chevron_right'} />
          </div>
        </GeneralTooltip>
        <GeneralTooltip id={'tooltip-flow-close-match-button'} text={t('flow.blocks.search.clear')}>
          <div className={button} onClick={() => setSearchString('')}>
            <MaterialIcon icon={'close'} />
          </div>
        </GeneralTooltip>
      </div>
    </>
  );
};
