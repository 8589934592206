import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsSidebar.module.scss';
import { Accordion } from 'react-bootstrap';
import { SettingsSidebarRoutes } from '../../routes/SettingsSidebarRoutes';
import { SidebarElement } from './SettingsSidebarElement';
import { usePermissions } from '../../../hooks/usePermissions';
import { useSettingsSidebar } from '../../../hooks/settings/useSettingsSidebar';

export const SettingsSidebar: React.FC = () => {
  const { index: selectedIndex, setIndex } = useSettingsSidebar();
  const { settingsSidebarWrapper } = convertKeysToCamelCase(style);
  const { hasPermissions } = usePermissions();

  const setSelectedIndex = (index: string) => setIndex(index);

  return (
    <Accordion className={settingsSidebarWrapper} bsPrefix={settingsSidebarWrapper} activeKey={selectedIndex}>
      {SettingsSidebarRoutes.filter((route) =>
        route.elements.some((element) => hasPermissions(element.permissions)),
      ).map((element, index) => {
        const { elements, icon, title, path } = element;
        return (
          <SidebarElement
            key={index}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            index={index.toString()}
            icon={icon}
            title={title}
            elements={elements}
            path={path}
          />
        );
      })}
    </Accordion>
  );
};
