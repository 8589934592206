import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import { useModal } from '../../../../../hooks/modal/useModal';
import { useWebclient } from '../../../../../hooks/settings/webclients/useWebclient';
import { useWebclientActions } from '../../../../../hooks/settings/webclients/useWebclientActions';
import style from '../../../../../assets/scss/components/common/Modal.module.scss';
import { useTranslation } from 'react-i18next';

export const RemoveWebclientWhitelistModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { webclient } = useWebclient();
  const { removeWebclientWhitelistedDomain } = useWebclientActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {webclient?.selectedWhitelistedDomain && (
        <Modal.Body>
          <p className={cvRow}>
            {t('settings.webclient.domain.delete.confirmation', { domain: webclient.selectedWhitelistedDomain })}
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                if (webclient.selectedWhitelistedDomain) {
                  removeWebclientWhitelistedDomain(webclient.selectedWhitelistedDomain);
                  hideModal();
                }
              }}
            >
              {t('general.buttons.remove')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default RemoveWebclientWhitelistModal;
