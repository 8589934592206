import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import chatAvatar from '../../../assets/images/chat_avatar.png';
import style from '../../../assets/scss/components/chat/chat-panel-sidebar/ChatPanelSidebar.module.scss';
import { TConversation } from '../../../interfaces/TChat';
import MaterialIcon from '../../common/MaterialIcon';
import { LabelSelector } from './LabelSelector';
import { AssigneeSelector } from './AssigneeSelector';
import { useAgentAssigneesInit } from '../../../hooks/chat/useAgentAssigneesInit';
import { CHAT_PAGE_ROUTE } from '../../../constants/routes';
import { Link } from 'react-router-dom';
import { useFileMedia } from '../../../hooks/useFileMedia';
import { EBreakpoint } from '../../../constants/breakpoints';
import { useMedia } from '../../../hooks/useMedia';
import { useSelectedChatStatus } from '../../../hooks/chat/useSelectedChatStatus';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

interface IProps {
  selectedConversation: TConversation;
}

export const ChatPanelSidebar: React.FC<IProps> = ({ selectedConversation }) => {
  const { client, chatPanelSidebar, info } = convertKeysToCamelCase(style);
  const { down: isMobile } = useMedia(EBreakpoint.MD);
  const { chatStatus } = useSelectedChatStatus();
  const { search } = useLocation();

  const { t } = useTranslation();
  const { getImageByURL } = useFileMedia();
  // TODO fetch only when the dropdown is toggled
  useAgentAssigneesInit(selectedConversation.id);

  const { name, imageUrl, userInfo, platform } = selectedConversation;
  const image = getImageByURL(imageUrl ?? chatAvatar);

  return (
    <div className={chatPanelSidebar}>
      <div className={client}>
        {isMobile && (
          <Link to={{ pathname: `${CHAT_PAGE_ROUTE}/${selectedConversation.id}`, search }}>
            <MaterialIcon icon={'arrow_back_ios'} />
          </Link>
        )}
        <img className={`circle`} src={image} />
        <div>
          <p>{name}</p>
          <span>{userInfo?.email ?? t('chat.panel.sidebar.content.notprovided')}</span>
        </div>
      </div>

      <div className={info}>
        <div>
          <MaterialIcon icon={'email'} />
          <span>{userInfo?.email ?? t('chat.panel.sidebar.content.notprovided')}</span>
        </div>
        <div>
          <MaterialIcon icon={'call'} />
          <span>{userInfo?.phone ?? t('chat.panel.sidebar.content.notprovided')}</span>
        </div>
        <div>
          <MaterialIcon icon={'work'} />
          <span>{userInfo?.address ?? t('chat.panel.sidebar.content.notprovided')}</span>
        </div>
        <div>
          <MaterialIcon icon={'phonelink_setup'} />
          <span>{platform ?? t('chat.panel.sidebar.content.notprovided')}</span>
        </div>
      </div>
      {chatStatus === 'open' && (
        <>
          <AssigneeSelector />
          <LabelSelector />
        </>
      )}
    </div>
  );
};
