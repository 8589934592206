import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import i18n from '../config/i18n';

const mapError = (error: string | AxiosError | unknown) => {
  if (typeof error === 'string') {
    return i18n.t(error);
  }

  const errorData = (error as any)?.response?.data;
  if (errorData) {
    const { errorCode, message } = errorData;
    if (errorCode) {
      const errorTranslationKey = `error.codes.${errorCode}`;

      if (i18n.exists(errorTranslationKey)) {
        return i18n.t(errorTranslationKey);
      }
    }

    if (message) {
      return message;
    }
  }

  return i18n.t('error.response.status.general');
};

export const toastErrorTranslated = (error: string | AxiosError | unknown) => {
  toast.error(mapError(error));
};

export const toastInfoTranslated = (info: string) => {
  toast.info(i18n.t(info));
};

export const toastSuccessTranslated = (msg: string) => {
  toast.success(i18n.t(msg));
};
