import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { IColorInputProps } from '../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/common/Modal.module.scss';

export const ColorInput: React.FC<IColorInputProps> = ({ label, name, control }) => {
  const { cvChooseColor, cvColorInput, modalLabel, cvRow } = convertKeysToCamelCase(style);
  return (
    <Form.Group className={`${cvRow} ${cvChooseColor}`}>
      <p className={modalLabel}>{label}:</p>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => <Form.Control className={cvColorInput} type="color" id={name} {...field} />}
      />
    </Form.Group>
  );
};

export default ColorInput;
