import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { TLabelUpdateable } from '../../../../interfaces/TChat';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: Partial<TLabelFormData>;
  onSubmit: (data: TLabelFormData) => void;
};

type TLabelFormData = TLabelUpdateable;

export const LabelDataModal: React.FC<Props> = ({ data, onSubmit }) => {
  const { cvColorInput, input, cvRow, cvFooter } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<TLabelFormData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      labelName: data?.labelName,
      labelColor: data?.labelColor,
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: TLabelFormData) => {
    onSubmit({
      labelName: data.labelName,
      labelColor: data.labelColor,
    });
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <Form onSubmit={handleSubmit<TLabelFormData>(hideAndSubmit)}>
          <Row>
            <Form.Group className={cvRow}>
              <p>{t('settings.label.name')}</p>
              <Form.Control
                className={input}
                {...register('labelName', { required: t('settings.label.validation.name') })}
              />
            </Form.Group>
            <Form.Group className={cvRow}>
              <p>{t('settings.label.color')}</p>
              <Form.Control
                className={cvColorInput}
                type="color"
                {...register('labelColor', { required: t('settings.label.validation.color') })}
              />
            </Form.Group>
          </Row>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">
              <p>{t('general.buttons.save')}</p>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default LabelDataModal;
