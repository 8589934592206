import { AppSidebar } from './sidebar/AppSidebar';
import style from '../../assets/scss/components/Main.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { AppBar } from './header/AppBar';
import useSocketEvents from '../../hooks/useSocketEvents';
import { Socket } from 'socket.io-client';
import React from 'react';
import { useCurrentUserInit } from '../../hooks/user/useCurrentUserInit';
import { AuthenticatedRouter } from '../routers/AuthenticatedRouter';
import { CompanySelectorPage } from '../pages/CompanySelectorPage';
import { useCompanySelector } from '../../hooks/useCompanySelector';
import { useServiceWorker } from '../../hooks/useServiceWorker';
import { Redirect, Route, Switch } from 'react-router';
import { NEW_CUSTOMER_ROUTE, SELECT_CUSTOMER_ROUTE } from '../../constants/routes';
import { NewCustomerPage } from '../pages/NewCustomerPage';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const WebSocketContext = React.createContext<React.MutableRefObject<Socket | undefined> | null>(null);

export const AuthenticatedLayout: React.FC = () => {
  const { wrapper } = convertKeysToCamelCase(style);
  const { loading, error } = useCurrentUserInit();
  const { socketRef } = useSocketEvents();
  const { selectedCompany } = useCompanySelector();
  const { hasPermissions } = usePermissions();

  // * Init service worker if user is logged in
  useServiceWorker();

  if (loading || error) {
    return null;
  }

  return (
    <Switch>
      {hasPermissions([PERMISSIONS.COMPANY.COMPANY_CREATE]) && (
        <Route path={NEW_CUSTOMER_ROUTE} component={NewCustomerPage} exact />
      )}
      <Route path={SELECT_CUSTOMER_ROUTE} component={CompanySelectorPage} exact />
      <Route
        path="*"
        render={() =>
          selectedCompany ? (
            <WebSocketContext.Provider value={socketRef}>
              <div className="d-flex flex-column mh-100">
                <AppBar />
                <div className={wrapper}>
                  <AppSidebar />
                  <AuthenticatedRouter />
                </div>
              </div>
            </WebSocketContext.Provider>
          ) : (
            <Redirect to={SELECT_CUSTOMER_ROUTE} />
          )
        }
      />
    </Switch>
  );
};
