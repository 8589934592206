import { useParams } from 'react-router-dom';
import { getResetPasswordValidationSchema } from '../../helpers/validationSchemaHelpers';
import { useValidatedForm } from '../useValidatedForm';
import { useResetPassword } from './useResetPassword';
import { BaseSyntheticEvent, useEffect } from 'react';
import { useCheckResetCode } from './useCheckResetCode';
import { IUseForm } from '../../interfaces/IGeneral';

interface IResetPasswordInput {
  password: string;
  passwordCheck: string;
  token?: string;
}

interface IUseResetPasswordForm extends IUseForm<IResetPasswordInput> {
  submitResetPassword: (e?: BaseSyntheticEvent) => any;
}

export const useResetPasswordForm = (): IUseResetPasswordForm => {
  const { registerInput, handleSubmit, reset, validationErrors } = useValidatedForm<IResetPasswordInput>({
    schema: getResetPasswordValidationSchema(),
  });

  const { code } = useParams<{ code: string }>();

  const { checkResetCode } = useCheckResetCode();

  useEffect(() => {
    checkResetCode(code);
  }, [code, checkResetCode]);

  const { resetPassword } = useResetPassword();

  const submitResetPassword = (resetPasswordData: IResetPasswordInput) => {
    reset();
    resetPassword(resetPasswordData.password, code, resetPasswordData.token);
  };

  return { submitResetPassword: handleSubmit(submitResetPassword), registerInput, validationErrors };
};
