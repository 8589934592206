import React from 'react';
import { Form } from 'react-bootstrap';
import { IInputProps } from '../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/common/Modal.module.scss';

export const TextInput: React.FC<IInputProps> = ({ name, register, label, horizontal }) => {
  const { modalLabel, inputField } = convertKeysToCamelCase(style);
  return horizontal ? (
    <Form.Group>
      <Form.Label className={modalLabel}>{label}</Form.Label>
      <Form.Control className={inputField} {...register(name)} />
    </Form.Group>
  ) : (
    <Form.Group>
      <Form.Label className={modalLabel}>{label}</Form.Label>
      <Form.Control className={inputField} {...register(name)} />
    </Form.Group>
  );
};

export default TextInput;
