import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelectedChatStatus } from '../../../hooks/chat/useSelectedChatStatus';
import { useTranslation } from 'react-i18next';

export const ChatSidebarStatusDropdown: React.FC = () => {
  const { statusDropdown, active } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { setChatListStatus, chatStatus } = useSelectedChatStatus();

  return (
    <DropdownButton
      className={statusDropdown}
      bsPrefix={statusDropdown}
      id="dropdown-basic-button"
      title={
        chatStatus === 'open' ? t('chat.sidebar.status.dropdown.opened') : t('chat.sidebar.status.dropdown.resolved')
      }
    >
      <Dropdown.Item className={chatStatus === 'open' ? active : ''} onClick={() => setChatListStatus('open')}>
        {t('chat.sidebar.status.dropdown.opened')}
      </Dropdown.Item>
      <Dropdown.Item className={chatStatus === 'resolved' ? active : ''} onClick={() => setChatListStatus('resolved')}>
        {t('chat.sidebar.status.dropdown.resolved')}
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default ChatSidebarStatusDropdown;
