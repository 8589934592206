import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useResetPasswordForm } from '../../hooks/auth/useResetPasswordForm';
import { AuthInput } from './AuthInput';
import { HoverText } from '../common/HoverText';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { EButtonType, GenericButton } from '../common/Button';
import { useTranslation } from 'react-i18next';

export const ResetPasswordForm: React.FC = () => {
  const { submitResetPassword, registerInput, validationErrors } = useResetPasswordForm();

  const history = useHistory();
  const switchToLogin = () => history.push(LOGIN_PAGE_ROUTE);

  const { search } = useLocation();
  const mfaEnabled = useMemo(() => new URLSearchParams(search).get('mfaEnabled') === 'true', [search]);

  const { t } = useTranslation();

  const { loginForm, loginFormFrame, loginFormFrameButtonsOptionsForgot, resetInstructions } =
    convertKeysToCamelCase(style);

  return (
    <>
      <form className={loginForm} onSubmit={submitResetPassword}>
        <p className={resetInstructions}>{t('auth.reset-password.instructions')}</p>

        <AuthInput
          title="auth.input.password"
          name="password"
          type="password"
          errorMessage={validationErrors?.password?.message}
          registerInput={registerInput}
        />

        <AuthInput
          title="auth.input.password-check"
          name="passwordCheck"
          type="password"
          errorMessage={validationErrors?.passwordCheck?.message}
          registerInput={registerInput}
        />

        {mfaEnabled && (
          <AuthInput
            title={'auth.input.2fa.title'}
            name="token"
            minLength={6}
            maxLength={6}
            registerInput={registerInput}
          />
        )}

        <div className={loginFormFrame}>
          <GenericButton buttonType={EButtonType.PRIMARY} text={t('auth.reset-password.submit')} type="submit" />
        </div>
      </form>
      <HoverText className={loginFormFrameButtonsOptionsForgot} text={t('auth.login.back')} onClick={switchToLogin} />
    </>
  );
};
