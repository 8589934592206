import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { useTranslation } from 'react-i18next';
import { SLUG_REGEX, LINK_REGEX } from '@conversed/shared/src/central/constants/regex';
import { CreateLinkReqDTO } from '@conversed/shared/src/central/dto/link.dto';

type Props = {
  linkData?: Partial<CreateLinkReqDTO>;
  onSubmit: (data: CreateLinkReqDTO) => void;
  isAllowedToEditShortLink?: boolean;
};

export const LinkDataModal: React.FC<Props> = ({ linkData, onSubmit, isAllowedToEditShortLink }) => {
  const { cvRow, input, cvFooter } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<CreateLinkReqDTO>({
    defaultValues: linkData,
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: CreateLinkReqDTO) => {
    onSubmit(data);
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <Form onSubmit={handleSubmit<CreateLinkReqDTO>(hideAndSubmit)}>
          <Row>
            <Form.Group className={cvRow}>
              <p>{t('link-settings.add-link.form.short-link')}</p>
              <Form.Control
                className={input}
                disabled={!isAllowedToEditShortLink}
                minLength={2}
                maxLength={16}
                required
                pattern={SLUG_REGEX.source}
                {...register('shortLink')}
              />
            </Form.Group>
            <Form.Group className={cvRow}>
              <p>{t('link-settings.add-link.form.original-link')}</p>
              <Form.Control className={input} required pattern={LINK_REGEX.source} {...register('originalLink')} />
            </Form.Group>
          </Row>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">{t('general.buttons.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default LinkDataModal;
