import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import {
  CreateCannedResponseReqDTO,
  GetAllCannedResponsesResDTO,
  GetCannedResponseResDTO,
  TDTOPutCannedResponse,
} from '@conversed/shared/src/central/dto/canned-response.dto';
import { TGeneralResponse } from './dto/common.dto';

export const getCannedResponsesApi = (): TGeneralResponse<GetAllCannedResponsesResDTO> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.CANNED_RESPONSES);

export const createCannedResponseApi = (
  cannedResponse: CreateCannedResponseReqDTO,
): TGeneralResponse<GetCannedResponseResDTO> =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.CANNED_RESPONSES, cannedResponse);

export const deleteCannedResponseApi = (cannedResponseId: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.CANNED_RESPONSES}/${cannedResponseId}`);

export const updateCannedResponseApi = (
  cannedResponseId: string,
  cannedResponseData: TDTOPutCannedResponse['request'],
): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.CANNED_RESPONSES}/${cannedResponseId}`, {
    ...cannedResponseData,
  });
