import { TFile } from '@conversed/shared/src/central/types/common.type';
import React from 'react';
import { useBlocksUpdates } from '../../../hooks/flow/block/useBlocksUpdates';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { TBlockWithPosition, TBlocksFormData } from '../../../interfaces/TDesigner';
import BlockDataModal from './BlockDataModal';

const getFieldsOfBlock = (block?: TBlockWithPosition) => {
  if (!block) {
    return {};
  }

  // TODO: Add more fields here in the future
  if ('textToSend' in block) {
    return {
      fieldName: 'textToSend',
      blockData: { text: block.textToSend },
      type: 'text',
    } as const;
  }

  if ('buttonText' in block) {
    return {
      fieldName: 'buttonText',
      blockData: { text: block.buttonText },
      type: 'text',
    } as const;
  }

  if ('imageUrl' in block) {
    return {
      fieldName: 'imageUrl',
      blockData: { imageUrl: block.imageUrl },
      type: 'image',
    } as const;
  }

  return {};
};

const isFile = (file?: TFile | string): file is TFile => {
  return (file as TFile)?.base64 !== undefined;
};

export const EditBlockModal: React.FC = () => {
  const { selectedBlock } = useFlow();
  const { updateBlocks } = useBlocksUpdates();
  const { uploadFlowMediaFileAndGetURL } = useFileUpload();

  const updateBlockWithFormData = async (block: TBlockWithPosition, data: TBlocksFormData) => {
    if (block.type === 'button' && data?.text) {
      return {
        ...block,
        buttonText: data?.text,
      };
    }

    if ((block.type === 'text' || block.type === 'textWithButtons') && data?.text) {
      return {
        ...block,
        textToSend: data?.text,
      };
    }

    if (block.type === 'StandAloneImage') {
      return {
        ...block,
        imageUrl: isFile(data.imageUrl) ? await uploadFlowMediaFileAndGetURL(data.imageUrl) : data?.imageUrl ?? '',
      };
    }

    return block;
  };

  const onSubmit = async (blockData: TBlocksFormData) => {
    if (!selectedBlock?.id) {
      return;
    }

    const updatedBlockData = await updateBlockWithFormData(selectedBlock, blockData);
    updateBlocks([updatedBlockData]);
  };

  const { blockData, type, fieldName } = getFieldsOfBlock(selectedBlock);

  if (!selectedBlock || !fieldName || !type) {
    return null;
  }

  return (
    <>
      <BlockDataModal
        type={type}
        description={selectedBlock.id}
        fieldName={fieldName}
        blockData={blockData}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditBlockModal;
