import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useWebclientInit } from '../../hooks/settings/webclients/useWebclientInit';
import { useWebclient } from '../../hooks/settings/webclients/useWebclient';
import ContentSubTitle from './common/ContentSubTitle';
import MaterialIcon from '../common/MaterialIcon';
import { useCollapsedList } from '../../hooks/useCollapsedList';
import { useTranslation } from 'react-i18next';
import style from '../../assets/scss/components/settings-page/NoDomainWhitelisted.module.scss';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const WebclientSettings: React.FC = () => {
  useWebclientInit();
  const { webclient, setSelectedWebclientConfig, setSelectedWhitelistedDomain } = useWebclient();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  const {
    elementsToShow: domains,
    showAll,
    setShowAll,
    hasMoreElements,
  } = useCollapsedList<string>(webclient.whitelistedDomains);

  const { noDomain, cvWhitelistedDomains, cvShowHide, cvShowHideButton } = convertKeysToCamelCase(style);

  return (
    <>
      <ContentTitle title={t('webclient-settings.title')} onClickInfo={() => showModal('webclient-info')} />
      {!!webclient.uuid ? (
        <>
          <ContentSubTitle
            title={t('webclient-settings.domains.title')}
            onClickInfo={() => showModal('webclient-whitelist-info')}
          />
          {hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_WHITELIST_EDIT]) && (
            <AddButton
              text={t('webclient-settings.domains.add-domain')}
              onClickAdd={() => showModal('add-webclient-whitelist')}
            />
          )}
          <div>
            {!!domains?.length ? (
              <ul className={cvWhitelistedDomains}>
                {domains.map((domain) => (
                  <li key={domain} className="d-flex align-items-center">
                    {domain}{' '}
                    {hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_WHITELIST_EDIT]) && (
                      <div
                        role="button"
                        onClick={() => {
                          setSelectedWhitelistedDomain(domain);
                          showModal('delete-webclient-whitelist');
                        }}
                      >
                        <MaterialIcon icon="delete" />
                      </div>
                    )}
                  </li>
                ))}
                {hasMoreElements && (
                  <div className={cvShowHideButton} role="button" onClick={() => setShowAll((showAll) => !showAll)}>
                    {showAll ? (
                      <div className={cvShowHide}>
                        <MaterialIcon icon="visibility_off" />
                        <span>{t('webclient-settings.domains.hide')}</span>
                      </div>
                    ) : (
                      <div className={cvShowHide}>
                        <MaterialIcon icon="visibility" />
                        <span>{t('webclient-settings.domains.show-all')}</span>
                      </div>
                    )}
                  </div>
                )}
              </ul>
            ) : (
              <div className={noDomain}>{t('webclient-settings.domains.no-domain')}</div>
            )}
          </div>
          <br></br>
          <ContentSubTitle
            title={t('webclient-settings.configs.title')}
            onClickInfo={() => showModal('webclient-config-info')}
          />
          {hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_CONFIG_CREATE]) && (
            <AddButton
              text={t('webclient-settings.configs.add-config')}
              onClickAdd={() => showModal('create-webclient-config')}
            />
          )}
          <SettingsCardContainer>
            {webclient.configs.map((webclientConfig) => {
              const removeAction = hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_CONFIG_REMOVE])
                ? [
                    {
                      icon: 'delete',
                      action: () => {
                        setSelectedWebclientConfig(webclientConfig.identifier);
                        showModal('delete-webclient-config');
                      },
                    },
                  ]
                : [];
              const editAction = hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_CONFIG_EDIT])
                ? [
                    {
                      icon: 'border_color',
                      action: () => {
                        setSelectedWebclientConfig(webclientConfig.identifier);
                        showModal('edit-webclient-config');
                      },
                    },
                  ]
                : [];
              const snippetAction = hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_CONFIG_LIST])
                ? [
                    {
                      icon: 'text_snippet',
                      action: () => {
                        setSelectedWebclientConfig(webclientConfig.identifier);
                        showModal('webclient-snippet');
                      },
                    },
                  ]
                : [];

              return (
                <SettingsCard
                  key={webclientConfig.identifier}
                  title={webclientConfig.name}
                  subtitle=""
                  actions={[...snippetAction, ...editAction, ...removeAction]}
                />
              );
            })}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState
          message={t('webclient-settings.configs.empty-state')}
          addButton={
            hasPermissions([PERMISSIONS.WEBCLIENT.WEBCLIENT_CREATE]) ? () => showModal('setup-webclient') : undefined
          }
        />
      )}
    </>
  );
};
export default WebclientSettings;
