import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_EN from '../translations/en.json';
import nl_NL from '../translations/nl.json';
import { DataKeys } from '../constants/login';

export const resources = {
  en: {
    translation: en_EN,
  },
  nl: {
    translation: nl_NL,
  },
} as const;

const initOptions: InitOptions = {
  resources,
  lng: localStorage.getItem(DataKeys.SELECTED_LANGUAGE) || 'en',
  interpolation: {
    escapeValue: false,
  },
};

export const initI18N = () => {
  i18n.use(initReactI18next).init(initOptions);
};

export const switchLanguage = (language: string) => {
  i18n.changeLanguage(language);
  localStorage.setItem(DataKeys.SELECTED_LANGUAGE, language);
};

export default i18n;
