import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import { CreateLabelReqDTO, GetLabelResDTO, GetAllLabelsResDTO } from '@conversed/shared/src/central/dto/label.dto';

export const getLabelsApi = (): TGeneralResponse<GetAllLabelsResDTO> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.LABEL);

export const createLabelApi = (data: CreateLabelReqDTO): TGeneralResponse<GetLabelResDTO> =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.LABEL, data);

export const deleteLabelApi = (id: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.LABEL}/${id}`);

export const updateLabelApi = (id: string, data: CreateLabelReqDTO): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.LABEL}/${id}`, {
    ...data,
  });
