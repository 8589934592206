import { useCallback } from 'react';
import { UpdateLinkReqDTO } from '@conversed/shared/src/central/dto/link.dto';
import { getLinksApi, createLinkApi, updateLinkApi, deleteLinkApi, getLinkStatisticsApi } from '../../api/linkApi';
import { Actions } from '../../constants/actionTypes';
import { toastErrorTranslated } from '../../helpers/toast';
import { useMainDispatch } from '../useMainDispatch';

export const useLinkActions = () => {
  const dispatch = useMainDispatch();

  const fetchLinks = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: links } = await getLinksApi();
      dispatch({ type: Actions.SET_SETTINGS, payload: { links } });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const fetchLinkStatistics = useCallback(
    async (shortLink: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        const { data: statistics } = await getLinkStatisticsApi(shortLink);
        dispatch({
          type: Actions.SET_LINK_STATISTICS,
          payload: { totalClicksCount: statistics.totalClicksCount, shortLink },
        });
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch],
  );

  const createLink = useCallback(
    async (linkData: any) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await createLinkApi(linkData);
        // TODO do not refetch
        await fetchLinks();
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchLinks],
  );

  const editLink = useCallback(
    async (shortLink: string, linkData: UpdateLinkReqDTO) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await updateLinkApi(shortLink, linkData);
        // TODO do not refetch
        await fetchLinks();
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchLinks],
  );

  const deleteLink = useCallback(
    async (shortLink: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await deleteLinkApi(shortLink);
        // TODO do not refetch
        await fetchLinks();
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchLinks],
  );

  return {
    fetchLinks,
    createLink,
    editLink,
    deleteLink,
    fetchLinkStatistics,
  };
};
