import { useEffect } from 'react';
import { useCompanySelector } from '../useCompanySelector';
import { useLinkActions } from './useLinkActions';

export const useLinksInit = () => {
  const { fetchLinks } = useLinkActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchLinks();
  }, [fetchLinks, selectedCompany]);
};
