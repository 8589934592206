import { useQueryState } from '../useQueryState';

const SEARCH_LABEL_QUERY_PARAM_KEY = 'label';

type TSearchLabelType = string | 'all';

export const SEARCH_LABEL_ALL = 'all';

export const useSearchLabel = () => {
  const { query, setQuery } = useQueryState(SEARCH_LABEL_QUERY_PARAM_KEY, SEARCH_LABEL_ALL);

  return {
    searchLabel: query as TSearchLabelType,
    setSearchLabel: (searchLabel: TSearchLabelType) => setQuery(searchLabel),
  };
};
