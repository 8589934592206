import { formatLoginPayload, formatRefreshTokenPayload } from '../helpers/apiHelper';
import { IForgotPasswordPayload } from '../interfaces/payloads/IForgotPasswordPayload';
import { IUserLoginPayload } from '../interfaces/ILogin';
import { IPasswordResetPayload } from '../interfaces/payloads/IPasswordResetPayload';
import { axiosAuth, axiosDefault } from './axios/axios';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { ICheckResetPasswordPayload } from '../interfaces/payloads/ICheckResetPasswordPayload';
import { ENDPOINTS } from '../constants/apiEndpoints';
import {
  TDTOGetCurrentUserResponse,
  UserProfileUpdateDTO,
  UserProfilePictureUpdateReqDTO,
  UserProfilePictureUpdateResDTO,
} from '@conversed/shared/src/central/dto/user.dto';
import { TGeneralResponse } from './dto/common.dto';
import { DisableMFAReqDTO, EnableMFAReqDTO, InitiateMFAResDTO } from '@conversed/shared/src/central/dto/mfa.dto';

export const loginApi = (payload: IUserLoginPayload): TGeneralResponse<any> =>
  axiosAuth().post(ENDPOINTS.OAUTH_LOGIN, formatLoginPayload(payload));

export const refreshAccessTokenApi = (token: string): TGeneralResponse<any> =>
  axiosAuth().post(ENDPOINTS.OAUTH_LOGIN, formatRefreshTokenPayload(token));

export const sendPasswordResetEmailApi = (payload: IForgotPasswordPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.FORGOT_PASSWORD, payload, {});

export const checkResetCodeApi = (payload: ICheckResetPasswordPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.CHECK_RESET_PASSWORD_TOKEN, payload, {});

export const resetPasswordApi = (payload: IPasswordResetPayload): TGeneralResponse<any> =>
  axiosDefault().post(ENDPOINTS.RESET_PASSWORD, payload, {});

export const meApi = (): TGeneralResponse<TDTOGetCurrentUserResponse> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.USER_DATA);

export const getPermissionsApi = (): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.USER_ROUTE);

export const updateProfileApi = (profile: UserProfileUpdateDTO): TGeneralResponse<UserProfileUpdateDTO> =>
  axiosInterceptAccessTokenExpiry().put(ENDPOINTS.PROFILE, profile);

export const updateProfilePictureApi = (
  profilePic: UserProfilePictureUpdateReqDTO,
): TGeneralResponse<UserProfilePictureUpdateResDTO> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.PROFILE}/picture`, profilePic);

export const initMFAApi = (): TGeneralResponse<InitiateMFAResDTO> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.MFA}/init`);

export const enableMFAApi = (data: EnableMFAReqDTO): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.MFA}/enable`, data);

export const disableMFAApi = (data: DisableMFAReqDTO): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.MFA}/disable`, data);
