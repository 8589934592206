import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router';
import qs from 'query-string';

export const useQueryParams = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const setQuery = useCallback(
    (paramName: string, paramValue?: string) => {
      const queryParams = qs.parse(search);
      const newQueries = { ...queryParams, [paramName]: paramValue };

      history.replace({
        pathname,
        search: qs.stringify(newQueries),
      });
    },
    [search, history, pathname],
  );

  return { query, setQuery };
};
