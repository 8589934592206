import React, { useEffect, useMemo } from 'react';
import { CompanyGeneralSettingsDTO } from '@conversed/shared/src/central/dto/company';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import ContentTitle from './common/ContentTitle';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useGeneralSettingsInit } from '../../hooks/settings/company/useGeneralSettingsInit';
import { useCompanySettings } from '../../hooks/settings/company/useCompanySettings';
import { useForm } from 'react-hook-form';
import { useCompanySettingsActions } from '../../hooks/settings/company/useCompanyActions';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const GeneralSettings: React.FC = () => {
  useGeneralSettingsInit();
  const { generalSettings } = useCompanySettings();
  const { updateGeneralSettings } = useCompanySettingsActions();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();
  const { register, handleSubmit, reset } = useForm<CompanyGeneralSettingsDTO>({
    shouldUseNativeValidation: true,
    defaultValues: useMemo(() => generalSettings, [generalSettings]),
  });

  useEffect(() => {
    reset(generalSettings);
  }, [generalSettings, reset]);

  const hideAndSubmit = (generalSettings: CompanyGeneralSettingsDTO) => {
    updateGeneralSettings(generalSettings);
  };

  const { settingsForm, sectionCategory, inputForm, form, inputGroup, input } = convertKeysToCamelCase(style);

  return (
    <div className={settingsForm}>
      <ContentTitle title={t('general-settings.title')} />
      <div className={`${sectionCategory} ${form}`}>
        <h5>{t('general-settings.company.title')}</h5>
        <Form onSubmit={handleSubmit<CompanyGeneralSettingsDTO>(hideAndSubmit)}>
          <Row className={`${inputGroup}`}>
            <Form.Group as={Col} className={`${inputForm}`}>
              <p>{t('general-settings.name')}</p>
              <Form.Control {...register('name', { required: t('general.validation.required') })} className={input} />
            </Form.Group>

            <Form.Group as={Col} className={`${inputForm}`}>
              <p>{t('general-settings.website')}</p>
              <Form.Control
                {...register('websiteUrl', { required: t('general.validation.required') })}
                className={input}
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} className={`${inputForm}`}>
            <p>{t('general-settings.logo')}</p>
            <Form.Control {...register('icon', { required: t('general.validation.required') })} className={input} />
          </Form.Group>
          {hasPermissions([PERMISSIONS.COMPANY.COMPANY_GENERAL_SETTINGS_EDIT]) && (
            <Button type="submit" className={`mt-4`}>
              <span> {t('general.buttons.save')}</span>
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default GeneralSettings;
