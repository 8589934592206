import React, { useMemo } from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { Dropdown } from 'react-bootstrap';
import { Label } from '../Label';
import { useLabels } from '../../../hooks/settings/labels/useLabels';
import { useTranslation } from 'react-i18next';

interface Props {
  searchLabel: string;
  setSearchLabel: (searchLabel: string) => void;
}

export const ChatSidebarLabelDropdown: React.FC<Props> = ({ searchLabel, setSearchLabel }) => {
  const { labelDropdown } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const { labels } = useLabels();

  const selectedLabel = useMemo(
    () =>
      labels.find((label) => label.identifier === searchLabel) || {
        identifier: 'all',
        labelName: 'Label',
        labelColor: 'none',
      },
    [labels, searchLabel],
  );

  return (
    <Dropdown className={labelDropdown} bsPrefix={labelDropdown} id="dropdown-basic-button">
      <Dropdown.Toggle
        style={{
          background: selectedLabel.labelColor,
          color: selectedLabel.identifier === 'all' ? '#999' : 'white',
          borderColor: selectedLabel.identifier === 'all' ? '#999' : selectedLabel.labelColor,
          borderStyle: 'solid 1px',
        }}
      >
        {selectedLabel.labelName}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setSearchLabel('all')}>
          <small>{t('chat.sidebar.label.dropdown.all')}</small>
        </Dropdown.Item>
        {labels?.length > 0 &&
          labels.map((label) => {
            return (
              <Dropdown.Item key={label.identifier} onClick={() => setSearchLabel(label.identifier)}>
                <Label labelName={label.labelName} labelColor={label.labelColor} />
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ChatSidebarLabelDropdown;
