import {
  GetAllLinksResDTO,
  CreateLinkResDTO,
  CreateLinkReqDTO,
  UpdateLinkReqDTO,
  GetLinkStatisticsResDTO,
} from '@conversed/shared/src/central/dto/link.dto';
import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';

export const getLinksApi = (): TGeneralResponse<GetAllLinksResDTO> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.LINKS);

export const getLinkStatisticsApi = (shortLink: string): TGeneralResponse<GetLinkStatisticsResDTO> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.LINKS}/${shortLink}`);

export const createLinkApi = (linkData: CreateLinkReqDTO): TGeneralResponse<CreateLinkResDTO> =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.LINKS, linkData);

export const updateLinkApi = (shortLink: string, linkData: UpdateLinkReqDTO): TGeneralResponse<CreateLinkResDTO> =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.LINKS}/${shortLink}`, linkData);

export const deleteLinkApi = (shortLink: string): TGeneralResponse<CreateLinkResDTO> =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.LINKS}/${shortLink}`);
