import { useRef, useCallback, useEffect, useMemo } from 'react';
import { useMainSelector } from './useMainSelector';
import { useMainDispatch } from './useMainDispatch';
import { Actions } from '../constants/actionTypes';
import { DataKeys } from '../constants/login';
import { TCompany } from '../interfaces/IAuth';
import { useHistory } from 'react-router-dom';

export const useCompanySelector = () => {
  const {
    user: { currentUser },
  } = useMainSelector();
  const currentUserRef = useRef(currentUser);
  const history = useHistory();

  const dispatch = useMainDispatch();

  const setSelectedCompany = useCallback(
    (selectedCompany: TCompany) => {
      const { identifier: selectedCompanyId, permissions } = selectedCompany;
      localStorage.setItem(DataKeys.SELECTED_COMPANY_DATA_KEY, selectedCompanyId);
      if (currentUserRef.current) {
        dispatch({
          type: Actions.SET_USER,
          payload: {
            permissions: permissions ?? [],
            currentUser: {
              ...currentUserRef.current,
              selectedCompanyId,
            },
          },
        });
      }
      history.push('/');
    },
    [dispatch, history],
  );

  const openCompanySelector = useCallback(() => {
    if (currentUser) {
      dispatch({
        type: Actions.SET_USER,
        payload: {
          currentUser: {
            ...currentUser,
            selectedCompanyId: undefined,
          },
        },
      });
    }
  }, [currentUser, dispatch]);

  const selectedCompany = useMemo(
    () => currentUser?.companies?.find((company) => company.identifier === currentUser?.selectedCompanyId),
    [currentUser?.companies, currentUser?.selectedCompanyId],
  );

  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  return {
    selectedCompany,
    companies: currentUser?.companies,
    setSelectedCompany,
    openCompanySelector,
  };
};
