import { useEffect } from 'react';
import { register as registerServiceWorker, unregister as unregisterServiceWorker } from '../serviceWorkerRegistration';
import { useMainSelector } from './useMainSelector';

export const useServiceWorker = () => {
  const {
    user: { currentUser: currentlyLoggedInUser },
  } = useMainSelector();

  useEffect(() => {
    if (!currentlyLoggedInUser) {
      return;
    }
    registerServiceWorker();
    return () => {
      unregisterServiceWorker();
    };
  }, [currentlyLoggedInUser]);
};
