import style from '../../../assets/scss/components/sidebar/AppSidebar.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import { useSidebar } from '../../../hooks/useSidebar';
import { SidebarRoutes } from '../../routes/SidebarRoutes';
import { SidebarItem } from './SidebarItem';
import { SidebarFooter } from './SidebarFooter';
import { usePermissions } from '../../../hooks/usePermissions';

export const AppSidebar = () => {
  const { hasPermissions } = usePermissions();

  const { sideBarOpen } = useSidebar();

  const { sidebarMenu, active } = convertKeysToCamelCase(style);

  return (
    <div id={sidebarMenu} className={getCSS(!sideBarOpen, active)}>
      <div>
        {SidebarRoutes.filter(({ permissions }) => hasPermissions(permissions)).map(({ icon, text, to }) => (
          <SidebarItem key={text} icon={icon} text={text} to={to} />
        ))}
      </div>
      <SidebarFooter />
    </div>
  );
};
