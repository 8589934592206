import { useEffect } from 'react';
import { useCompanySelector } from '../../useCompanySelector';
import { useCompanySettingsActions } from './useCompanyActions';

export const useCompanyMembersInit = () => {
  const { fetchCompanyMembers } = useCompanySettingsActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchCompanyMembers();
  }, [fetchCompanyMembers, selectedCompany]);
};
