import axios, { AxiosInstance } from 'axios';

import { AWS_API_ENDPOINT, AZURE_API_ENDPOINT } from '../../constants/env';
import { DataKeys } from '../../constants/login';

export const getApiEndpoint = () => (localStorage.getItem('CSP') === 'Azure' ? AZURE_API_ENDPOINT : AWS_API_ENDPOINT);

export const axiosAuth = (): AxiosInstance => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'user-company': 'portal',
  };

  return axios.create({
    baseURL: getApiEndpoint(),
    headers,
  });
};

export const axiosDefault = (): AxiosInstance => {
  const company = localStorage.getItem(DataKeys.SELECTED_COMPANY_DATA_KEY);
  const token = localStorage.getItem(DataKeys.ACCESS_TOKEN_DATA_KEY);
  if (!token) {
    return axios.create({
      baseURL: getApiEndpoint(),
      headers: {
        'user-company': company ?? 'portal',
      },
    });
  }

  const headers = {
    common: {
      Authorization: `Bearer ${token}`,
      'user-company': company ?? 'portal',
    },
  };

  return axios.create({
    baseURL: getApiEndpoint(),
    headers,
  });
};
