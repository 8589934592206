import { useMemo } from 'react';
import { useQueryState } from '../useQueryState';

const CHAT_PROFILE_SIDEBAR_QUERY_PARAM_KEY = 'chatSidebarOpen';

export const useChatProfileSidebar = () => {
  const { query, setQuery } = useQueryState(CHAT_PROFILE_SIDEBAR_QUERY_PARAM_KEY, 'false');

  const isSidebarOpen = useMemo(() => (query === 'false' ? false : true), [query]);

  return {
    chatProfileSidebarOpen: isSidebarOpen,
    toggleChatProfileSidebar: () => setQuery((!isSidebarOpen).toString()),
  };
};
