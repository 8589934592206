import React from 'react';

interface Props {
  permission: string;
}

export const PermissionsColumn: React.FC<Props> = ({ permission }) => {
  return (
    <td>
      <p>{permission}</p>
    </td>
  );
};

export default PermissionsColumn;
