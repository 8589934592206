import { useState, useEffect, useCallback } from 'react';
import { FlowElement, isNode } from 'react-flow-renderer';
import { Actions } from '../../constants/actionTypes';
import { useMainDispatch } from '../useMainDispatch';
import { useFlow } from './flow/useFlow';
import { useFlowNode } from './useFlowNode';

export const useFlowEvents = () => {
  const [foundElement, setFoundElement] = useState<FlowElement>();
  const { filteredNodes } = useFlowNode();
  const dispatch = useMainDispatch();

  const { flowMatchIndex } = useFlow();
  const { focusNode, setSelectedNodes } = useFlowNode();

  const resetMatchCount = useCallback(() => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { blocksSearchMatchIndex: 0 } });
  }, [dispatch]);

  useEffect(() => {
    if (!foundElement || !isNode(foundElement)) {
      return;
    }

    focusNode(foundElement, 1.85);
    setSelectedNodes([foundElement]);
  }, [focusNode, foundElement, setSelectedNodes]);

  useEffect(() => {
    if (!filteredNodes.length) {
      return setFoundElement(undefined);
    }

    const element = filteredNodes[flowMatchIndex];
    setFoundElement(element);
  }, [filteredNodes, flowMatchIndex]);

  useEffect(() => {
    resetMatchCount();
  }, [filteredNodes, resetMatchCount]);
};
