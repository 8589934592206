import { Handle } from 'react-flow-renderer';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { getHandlePositionByFlowDirection } from '../../../helpers/flowHelper';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { NodeTitle } from './NodeTitle';
import { StandAloneImage } from '@conversed/shared/src/central/types/blocks/StandAloneImage';
import { Image } from '@conversed/shared/src/central/types/blocks/Image';
import { useFileMedia } from '../../../hooks/useFileMedia';

interface IProps {
  data: StandAloneImage | Image;
}

export const ImageNode: React.FC<IProps> = ({ data }) => {
  const { imageUrl } = data;
  const { getImageByURL } = useFileMedia();
  const { node, nodeSeparator, nodeBody, image } = convertKeysToCamelCase(style);

  const { flowDirection } = useFlow();
  const { start, end } = getHandlePositionByFlowDirection(flowDirection);

  return (
    <div className={node}>
      <Handle type="target" position={start} id="start" />
      <NodeTitle data={data} />
      <hr className={nodeSeparator} />
      <div className={nodeBody}>
        <img className={image} src={getImageByURL(imageUrl)} />
      </div>
      <Handle type="source" position={end} id="end" />
    </div>
  );
};
