import React from 'react';
import { Modal } from 'react-bootstrap';

export const WebclientWhitelistInfoModal: React.FC = () => {
  return (
    <Modal.Body>
      <p>{''}</p>
    </Modal.Body>
  );
};

export default WebclientWhitelistInfoModal;
