import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { ENDPOINTS } from '../constants/apiEndpoints';
import { TGeneralResponse } from './dto/common.dto';
import { TAgentAssignResponseBody, TAssignee, TLabelID, TInboxID } from '@conversed/shared/src/central/dto/chat.dto';

export const getConversationByInboxApi = (chatStatus: string, inbox?: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.CONVERSATIONS, { params: { inbox, chatStatus } });

export const getConversationByIdApi = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}`);

export const getConversationLabelsByIdApi = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/labels`);

export const getMessagesOfConversationApi = (conversationId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/messages`);

export const assignAgentToConversation = (
  agentId: string | null,
  conversationId: string,
): TGeneralResponse<TAgentAssignResponseBody> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/agent`, { agentId });

export const assignInboxToConversation = (conversationId: string, inboxId: string): TGeneralResponse<any> => {
  const body: TInboxID = {
    inboxId,
  };
  return axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/inbox`, body);
};

export const assignLabelToConversationApi = (conversationId: string, labelId: string): TGeneralResponse => {
  const body: TLabelID = { labelId };
  return axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/labels`, body);
};

export const removeLabelFromConversationApi = (conversationId: string, labelId: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/labels/${labelId}`);

export const getAssigneeListForConversation = (conversationId: string): TGeneralResponse<TAssignee[]> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/assignees`);
