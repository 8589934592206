import { Modal, Form, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import { useModal } from '../../../../../hooks/modal/useModal';
import { useWebclientActions } from '../../../../../hooks/settings/webclients/useWebclientActions';
import style from '../../../../../assets/scss/components/common/Modal.module.scss';
import { URL_REGEX } from '../../../../../constants/regex';
import { useTranslation } from 'react-i18next';

export const AddWebclientWhitelistModal: React.FC = () => {
  const { addWhitelistedDomain } = useWebclientActions();
  const { t } = useTranslation();

  const { cvRow, input, cvFooter } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<{ domain: string }>({
    shouldUseNativeValidation: true,
    defaultValues: {
      domain: '',
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = ({ domain }: { domain: string }) => {
    addWhitelistedDomain(domain);
    hideModal();
  };

  const CLIENT_URL = 'https://conversed.ai';

  return (
    <>
      <Modal.Body>
        <Form onSubmit={handleSubmit<{ domain: string }>(hideAndSubmit)}>
          <Row>
            <Form.Group className={cvRow}>
              <p>{t('settings.webclient.domain.title')}</p>
              <Form.Control
                placeholder={CLIENT_URL}
                className={input}
                {...register('domain', {
                  required: t('settings.webclient.validation.domain'),
                  pattern: { value: URL_REGEX, message: t('settings.webclient.validation.domain.pattern') },
                })}
              />
            </Form.Group>
          </Row>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">{t('general.buttons.add')}</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default AddWebclientWhitelistModal;
