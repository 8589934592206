import { useCompanySettingsActions } from './useCompanyActions';
import useRole from '../roles/useRole';
import { getNewMemberValidationSchema } from '../../../helpers/validationSchemaHelpers';
import { useValidatedForm } from '../../useValidatedForm';
import { DEFAULT_ROLES, PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { usePermissions } from '../../usePermissions';

export const useNewMemberForm = () => {
  const { addMember, fetchMemberAutocompleteList } = useCompanySettingsActions();
  const { assignableRoles } = useRole();
  const { hasPermissions } = usePermissions();

  const { register, setValue, reset, handleSubmit, getValues, watch } = useValidatedForm<{
    email: string;
    roles: string[];
  }>(
    {
      schema: getNewMemberValidationSchema(),
      defaultValues: {
        email: '',
        roles: [DEFAULT_ROLES.AGENT_ROLE],
      },
    },
    true,
  );

  const submit = handleSubmit((data) => {
    addMember({
      email: data.email,
      roles: data.roles,
    });
    reset();
  });

  const getUpdatedRoles = (previousRoles: string[], roleId: string) => {
    if (previousRoles.includes(roleId)) {
      return previousRoles.length > 1 ? previousRoles.filter((role) => role !== roleId) : previousRoles;
    }
    return [...previousRoles, roleId];
  };

  const selectRole = (roleId: string) => {
    const newRoles = getUpdatedRoles(getValues().roles, roleId);
    setValue('roles', newRoles);
  };

  const selectMemberFromAutocomplete = (email: string) => setValue('email', email);

  const onNewMemberEmailChange = (email: string) => {
    setValue('email', email);
    if (hasPermissions([PERMISSIONS.GLOBAL.ACCESS_ALL_COMPANIES])) {
      fetchMemberAutocompleteList(email);
    }
  };

  const selectedRoles = watch('roles');

  return {
    assignableRoles,
    selectedRoles,
    register,
    selectRole,
    onNewMemberEmailChange,
    selectMemberFromAutocomplete,
    submit,
  };
};
