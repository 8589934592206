import React from 'react';
import { Control } from 'react-hook-form';
import TextInput from '../../../../input/TextInput';
import DropDownInput from '../../../../input/DropDownInput';
import { TFacebookSettings } from '../../../../../interfaces/TFacebook';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  control: Control<TFacebookSettings>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const FacebookCallToAction: React.FC<Props> = ({ name, control, ...rest }) => {
  const { t } = useTranslation();
  return (
    <div {...rest}>
      <DropDownInput
        horizontal
        label={t('settings.facebook.call-to-action.type')}
        name={`${name}.type`}
        register={control.register}
        values={['postback', 'web_url']}
      />
      <TextInput
        horizontal
        label={t('settings.facebook.call-to-action.title')}
        name={`${name}.title`}
        register={control.register}
      />
      <TextInput
        horizontal
        label={t('settings.facebook.call-to-action.url')}
        name={`${name}.url`}
        register={control.register}
      />
      <TextInput
        horizontal
        label={t('settings.facebook.call-to-action.payload')}
        name={`${name}.payload`}
        register={control.register}
      />
    </div>
  );
};

export default FacebookCallToAction;
