import { useCallback } from 'react';
import {
  getFacebookPagesApi,
  getFacebookPageSettingsApi,
  getFacebookStateTokenApi,
  updateFacebookPageSettingsApi,
} from '../../../api/facebookPagesApi';
import { Actions } from '../../../constants/actionTypes';
import { API_ENDPOINT, FB_GRAPH_VERSION, FB_INTEGRATION_CREATE_ENDPOINT } from '../../../constants/env';
import { toastErrorTranslated } from '../../../helpers/toast';
import { TFacebookSettings } from '../../../interfaces/TFacebook';
import { useMainDispatch } from '../../useMainDispatch';
import { useFacebookPages } from './useFacebookPages';

type FacebookLoginWindowParams = {
  scope: string;
  responseType: string;
  state: string;
  authType?: string;
  botId: string;
};

const facebookAppLoginRequiredScopes = ['pages_show_list', 'pages_manage_metadata', 'pages_messaging'];

export const useFacebookPagesActions = () => {
  const dispatch = useMainDispatch();
  const { selectedFacebookPage } = useFacebookPages();

  const fetchFacebookPages = useCallback(async () => {
    try {
      const { data: facebookPages } = await getFacebookPagesApi();
      dispatch({ type: Actions.SET_SETTINGS, payload: { facebookPages } });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  const openFacebookLoginPage = ({ scope, state, responseType, authType, botId }: FacebookLoginWindowParams) => {
    const possibleAuthType = authType ? `&auth_type=${authType}` : '';
    window.open(
      `https://www.facebook.com/${FB_GRAPH_VERSION}/dialog/oauth?client_id=${botId}&redirect_uri=${API_ENDPOINT}${FB_INTEGRATION_CREATE_ENDPOINT}&state=${state}&response_type=${responseType}&scope=${scope}${possibleAuthType}`,
      '_self',
    );
  };

  const getSelectedFacebookPageSettings = useCallback(
    async (facebookPageId?: string) => {
      const pageId = facebookPageId ?? selectedFacebookPage?.id;

      if (!pageId) {
        return;
      }

      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        const { data: pageSettings } = await getFacebookPageSettingsApi(pageId);
        dispatch({
          type: Actions.SET_SETTINGS,
          payload: {
            facebookPageSettings: pageSettings,
            selectedFacebookPage: pageId,
          },
        });
      } catch (error) {
        console.error(error);
        dispatch({
          type: Actions.SET_SETTINGS,
          payload: { facebookPageSettings: undefined },
        });
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [selectedFacebookPage?.id, dispatch],
  );

  const updateSelectedPageSettings = useCallback(
    async (pageSettings: TFacebookSettings) => {
      if (!selectedFacebookPage?.id) {
        return;
      }
      try {
        await updateFacebookPageSettingsApi(selectedFacebookPage.id, pageSettings);
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [selectedFacebookPage?.id],
  );

  const connectFacebookPage = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const {
        data: { facebookStateToken, botId },
      } = await getFacebookStateTokenApi();

      openFacebookLoginPage({
        scope: facebookAppLoginRequiredScopes.join(','),
        state: facebookStateToken,
        responseType: 'code',
        botId,
      });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  return {
    fetchFacebookPages,
    connectFacebookPage,
    getSelectedFacebookPageSettings,
    updateSelectedPageSettings,
  };
};
