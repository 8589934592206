import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useChatActions } from '../useChatActions';
import { useMainSelector } from '../useMainSelector';
import { useMainDispatch } from '../useMainDispatch';
import { Actions } from '../../constants/actionTypes';

export const useChatMessagesPage = () => {
  const {
    chat: { selectedConversation },
  } = useMainSelector();

  const { getConversationById } = useChatActions();

  const { id: conversationId } = useParams<{ id?: string }>();

  const dispatch = useMainDispatch();

  useEffect(() => {
    dispatch({ type: Actions.SET_CHAT, payload: { selectedConversation: undefined } });
    if (conversationId) {
      getConversationById(conversationId);
    }
  }, [conversationId, getConversationById, dispatch]);

  return { selectedConversation };
};
