import { useCallback, useMemo } from 'react';
import { Actions } from '../../constants/actionTypes';
import { TCannedResponse } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';
import { useChatInput } from './useChatInput';

export const useCannedResponsesMenu = () => {
  const {
    chat: { cannedResponsesIndex, isCannedResponsesMenuOpen, cannedResponses, chatInputMessage },
  } = useMainSelector();
  const { setMessage } = useChatInput();
  const dispatch = useMainDispatch();

  const filteredCannedResponses = useMemo(
    () => cannedResponses?.filter(({ shorthand }) => shorthand.toLowerCase().includes(chatInputMessage.toLowerCase())),
    [cannedResponses, chatInputMessage],
  );

  const increaseCannedResponseIndex = useCallback(() => {
    if (filteredCannedResponses && cannedResponsesIndex < filteredCannedResponses?.length - 1) {
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesIndex: cannedResponsesIndex + 1 } });
    }
  }, [cannedResponsesIndex, dispatch, filteredCannedResponses]);

  const decreaseCannedResponseIndex = useCallback(() => {
    if (cannedResponsesIndex > 0) {
      dispatch({ type: Actions.SET_CHAT, payload: { cannedResponsesIndex: cannedResponsesIndex - 1 } });
    }
  }, [cannedResponsesIndex, dispatch]);

  const toggleCannedResponsesMenuOpen = useCallback(() => {
    setMessage('');
    dispatch({ type: Actions.SET_CHAT, payload: { isCannedResponsesMenuOpen: !isCannedResponsesMenuOpen } });
  }, [dispatch, isCannedResponsesMenuOpen, setMessage]);

  const setCurrentResponseBasedOnIndex = useCallback(() => {
    if (filteredCannedResponses && cannedResponsesIndex) {
      const { message } = filteredCannedResponses[cannedResponsesIndex];
      setMessage(message);
      dispatch({ type: Actions.SET_CHAT, payload: { isCannedResponsesMenuOpen: false } });
    }
  }, [cannedResponsesIndex, dispatch, filteredCannedResponses, setMessage]);

  const setCurrentResponse = useCallback(
    (response: TCannedResponse['message']) => {
      setMessage(response);
      dispatch({ type: Actions.SET_CHAT, payload: { isCannedResponsesMenuOpen: false } });
    },
    [dispatch, setMessage],
  );

  return {
    cannedResponsesIndex,
    increaseCannedResponseIndex,
    decreaseCannedResponseIndex,
    setCurrentResponseBasedOnIndex,
    isCannedResponsesMenuOpen,
    toggleCannedResponsesMenuOpen,
    setCurrentResponse,
    filteredCannedResponses,
  };
};
