import React from 'react';
import { Form } from 'react-bootstrap';
import { IInputProps } from '../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/common/Modal.module.scss';

export const BooleanInput: React.FC<IInputProps> = ({ label, name, register }) => {
  const { modalLabel, inputBoolean } = convertKeysToCamelCase(style);
  return (
    <Form.Group className={inputBoolean}>
      <Form.Label className={`${modalLabel}`}>{label}</Form.Label>
      <Form.Check {...register(name)} />
    </Form.Group>
  );
};

export default BooleanInput;
