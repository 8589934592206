import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import {
  CreateInboxRequestBody,
  GetInboxResponseBody,
  UpdateInboxRequestBody,
} from '@conversed/shared/src/central/dto/inbox.dto';

export const getInboxesApi = (): TGeneralResponse<GetInboxResponseBody[]> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.INBOXES);

export const getInboxesOfAgentApi = (): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.INBOXES}/agent`);

export const createInboxApi = (inboxData: CreateInboxRequestBody): TGeneralResponse<GetInboxResponseBody> =>
  axiosInterceptAccessTokenExpiry().post(ENDPOINTS.INBOXES, inboxData);

export const updateInboxApi = (inboxId: string, inboxData: UpdateInboxRequestBody): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.INBOXES}/${inboxId}`, inboxData);

export const deleteInboxApi = (inboxId: string): TGeneralResponse<any> =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.INBOXES}/${inboxId}`);
