import { useHistory } from 'react-router-dom';
import { getForgotPasswordValidationSchema } from '../../helpers/validationSchemaHelpers';
import { useForgotPassword } from './useForgotPassword';
import { useValidatedForm } from '../useValidatedForm';
import { IUseForm } from '../../interfaces/IGeneral';
import { BaseSyntheticEvent } from 'react';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';

interface IForgotPasswordInput {
  email?: string;
}

interface IUseForgotPasswordForm extends IUseForm<IForgotPasswordInput> {
  submitForgotPassword: (e?: BaseSyntheticEvent) => any;
}

export const useForgotPasswordForm = (): IUseForgotPasswordForm => {
  const { registerInput, handleSubmit, reset, validationErrors } = useValidatedForm<IForgotPasswordInput>({
    schema: getForgotPasswordValidationSchema(),
  });

  const history = useHistory();

  const { forgotPassword } = useForgotPassword();

  const submitForgotPassword = (forgotPasswordData: IForgotPasswordInput) => {
    reset();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    forgotPassword(forgotPasswordData.email!);
    history.push(LOGIN_PAGE_ROUTE);
  };

  return { submitForgotPassword: handleSubmit(submitForgotPassword), registerInput, validationErrors };
};
