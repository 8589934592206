import React, { createContext, RefObject, useRef } from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/designer/DesignerContent.module.scss';
import { DesignerFlow } from './DesignerFlow';
import { DesignerSidebar } from './DesignerSidebar';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useBlocksInit } from '../../hooks/flow/block/useBlocksInit';
import MaterialIcon from '../common/MaterialIcon';
import { useChatProfileSidebar } from '../../hooks/chat/useChatProfileSidebar';

export const ReactFlowReferenceContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const DesignerContent: React.FC = () => {
  const { chatProfileSidebarOpen: sidebarState, toggleChatProfileSidebar: toggleSidebar } = useChatProfileSidebar();
  const { loading, error } = useBlocksInit();
  const { designerContentWrapper, toggleButton, toggleButtonClose, sidebarButton } = convertKeysToCamelCase(style);
  const sideBarButtonClass = sidebarState ? toggleButton : toggleButtonClose;
  const sideBarButtonIcon = sidebarState ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left';
  const flowRef = useRef<HTMLDivElement>(null);

  if (loading || error) {
    return null;
  }

  return (
    <div ref={flowRef} className={designerContentWrapper}>
      <ReactFlowProvider>
        <ReactFlowReferenceContext.Provider value={flowRef}>
          <DesignerFlow />
          <div
            className={`justify-content-center align-items-center ${sidebarButton} ${sideBarButtonClass}`}
            role="button"
            onClick={() => toggleSidebar()}
          >
            <MaterialIcon icon={sideBarButtonIcon} />
          </div>
          {sidebarState && <DesignerSidebar />}
        </ReactFlowReferenceContext.Provider>
      </ReactFlowProvider>
    </div>
  );
};

export default DesignerContent;
