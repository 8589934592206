import React, { Fragment } from 'react';
import { ChatMessage } from './ChatMessage';
import { IMessageGroup } from '../../../interfaces/IMessage';
import ChatTimestamp from './ChatTimestamp';
import { useChatTimestamp } from '../../../hooks/chat/useChatTimestamp';

export const MessageGroup: React.FC<IMessageGroup> = ({ messages }) => {
  const { canShowTimestamp } = useChatTimestamp(messages);

  return (
    <>
      {messages.map((message, index) => (
        <Fragment key={new Date(message.time).getTime().toString()}>
          <ChatMessage messageLocation={message.direction} message={message} time={message.time} />
          {canShowTimestamp(message, index) && <ChatTimestamp time={messages[index].time} />}
        </Fragment>
      ))}
    </>
  );
};

export default MessageGroup;
