import { TChatListFilter } from '../../interfaces/TChat';
import { useQueryState } from '../useQueryState';

const CHAT_LIST_TYPE_QUERY_PARAM_KEY = 'listType';

export const useSelectedChatList = () => {
  const { query, setQuery } = useQueryState(CHAT_LIST_TYPE_QUERY_PARAM_KEY, 'assigned-to-me');

  return {
    selectedChatList: query as TChatListFilter,
    setSelectedChatList: (key: TChatListFilter) => setQuery(key),
  };
};
