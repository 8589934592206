import React from 'react';
import { useAgentActions } from '../../../../hooks/settings/agents/useAgentActions';
import { useAgents } from '../../../../hooks/settings/agents/useAgents';
import AgentDataModal from './AgentDataModal';
import { CreateAgentRequestDTO } from '@conversed/shared/src/central/dto/agent.dto';

export const EditAgentModal: React.FC = () => {
  const { editAgent } = useAgentActions();

  const { selectedAgent } = useAgents();

  const onSubmit = async (agentData: CreateAgentRequestDTO) => {
    if (selectedAgent?.id) {
      editAgent(selectedAgent.id, agentData);
    }
  };

  return <AgentDataModal onSubmit={onSubmit} agentData={selectedAgent} />;
};

export default EditAgentModal;
