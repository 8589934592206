import { useEffect } from 'react';
import { useChatActions } from './useChatActions';
import { useLabelInit } from './settings/labels/useLabelInit';
import { useSelectedInbox } from './chat/useSelectedInbox';

export const useChatSidebar = () => {
  const { selectedInboxId } = useSelectedInbox();

  useLabelInit();

  const { getConversationsList, getAvailableInboxesForConversations } = useChatActions();

  useEffect(() => {
    getAvailableInboxesForConversations();
  }, [getAvailableInboxesForConversations]);

  useEffect(() => {
    getConversationsList();
  }, [selectedInboxId, getConversationsList]);
};
