import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useFlowControls } from '../../../hooks/flow/useFlowControls';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { EFlowDirection } from '../../../interfaces/EFlowDirection';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const FlipLayoutButton: React.FC = () => {
  const { flipFlowLayout, flowDirection } = useFlowControls();
  const { button } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const getComponentPropertiesByFlowDirection = useCallback(
    (flowDirection: EFlowDirection) => {
      switch (flowDirection) {
        case EFlowDirection.Vertical: {
          return {
            text: t('flow.blocks.flip.vertical'),
            icon: 'vertical_distribute',
          };
        }
        case EFlowDirection.Horizontal: {
          return {
            text: t('flow.blocks.flip.horizontal'),
            icon: 'horizontal_distribute',
          };
        }
      }
    },
    [t],
  );

  const { icon, text } = getComponentPropertiesByFlowDirection(flowDirection);

  return (
    <GeneralTooltip id={'tooltip-flow-layout-flip-button'} text={text}>
      <div className={button} onClick={() => flipFlowLayout()}>
        <MaterialIcon icon={icon} />
      </div>
    </GeneralTooltip>
  );
};
