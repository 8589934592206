import { useEffect } from 'react';
import { useAgentAssigneeActions } from './useAgentAssigneeActions';

export const useAgentAssigneesInit = (conversationId: string) => {
  const { fetchAgentAssignees } = useAgentAssigneeActions();

  useEffect(() => {
    fetchAgentAssignees(conversationId);
  }, [fetchAgentAssignees, conversationId]);
};
