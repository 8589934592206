import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../constants/actionTypes';
import { resetPasswordApi } from '../../api/userApi';
import { useHistory } from 'react-router-dom';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { toastErrorTranslated } from '../../helpers/toast';
import { useMainDispatch } from '../useMainDispatch';
import { useCallback } from 'react';

export const useResetPassword = () => {
  const dispatch = useMainDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const resetPassword = useCallback(
    async (password: string, code: string, mfaToken?: string) => {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      try {
        await resetPasswordApi({ password, code, mfaToken });
        history.push(LOGIN_PAGE_ROUTE);
        toast.success(t('auth.reset-password.success.message'));
      } catch (error) {
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, history, t],
  );

  return { resetPassword };
};
