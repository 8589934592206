export const SIGN_IN_DEFAULT_VALUES = {
  email: '',
  password: '',
};

export enum DataKeys {
  ACCESS_TOKEN_DATA_KEY = 'ACCESS_TOKEN_DATA_KEY',
  REFRESH_TOKEN_DATA_KEY = 'REFRESH_TOKEN_DATA_KEY',
  USER_DATA = 'USER_DATA',
  SELECTED_COMPANY_DATA_KEY = 'SELECTED_COMPANY',
  SIDEBAR_OPEN = 'SIDEBAR_OPEN',
  SELECTED_LANGUAGE = 'SELECTED_LANGUAGE',
}

export const MFA_DEFAULT_VALUE = {
  token: '',
};
