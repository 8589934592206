import { useEffect } from 'react';
import { useCompanySelector } from '../../useCompanySelector';
import { useFacebookPagesActions } from './useFacebookPagesActions';

export const useFacebookPagesInit = () => {
  const { fetchFacebookPages } = useFacebookPagesActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchFacebookPages();
  }, [fetchFacebookPages, selectedCompany]);
};
