import { useMemo } from 'react';
import { useMainSelector } from '../../useMainSelector';

export const useCompanySettings = () => {
  const {
    settings: { company },
    user: { currentUser },
  } = useMainSelector();

  const members = useMemo(
    () =>
      (company?.members || [])
        .filter((member) => member.roles.length > 0)
        .map((member) => ({ ...member, isCurrentUser: member.id === currentUser?.id })),
    [company?.members, currentUser?.id],
  );

  return {
    connectionSettings: company?.connectionSettings,
    generalSettings: company?.generalSettings,
    members,
    membersAutocompleteList: company?.membersAutocompleteList || [],
  };
};
