import { useMemo } from 'react';
import { useQueryParams } from './useQueryParams';

export const useQueryState = (id: string, defaultKey = '') => {
  const { query, setQuery } = useQueryParams();

  const activeKey = useMemo(() => query.get(id) || defaultKey, [defaultKey, id, query]);

  const setActiveKey = (setKey: string) => {
    setQuery(id, setKey);
  };

  return { query: activeKey, setQuery: setActiveKey };
};
