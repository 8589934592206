import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/common/Modal.module.scss';
import { useForm, UseFormRegister } from 'react-hook-form';
import { useModal } from '../../../hooks/modal/useModal';
import { TBlocksFormData } from '../../../interfaces/TDesigner';
import { BlockCreationForm } from './form/BlockCreationForm';
import { BlockTextForm } from './form/BlockTextForm';
import { BlockImageForm } from './form/BlockImageForm';
import { convertDataFilesToBase64Format } from '../../../helpers/fileHelper';
import { useTranslation } from 'react-i18next';

type TProps = {
  type: 'creation' | 'text' | 'image';
  description?: string;
  blockData?: Partial<TBlocksFormData>;
  fieldName?: string;
  onSubmit: (data: TBlocksFormData) => void;
};

type TOptions = {
  type: 'creation' | 'text' | 'image';
  register: UseFormRegister<Partial<TBlocksFormData>>;
  blockData?: Partial<TBlocksFormData>;
  fieldName?: string;
};

const getBlockFormByType = (options: TOptions) => {
  const { fieldName, register, type, blockData } = options;

  switch (type) {
    case 'creation': {
      return <BlockCreationForm register={register} />;
    }
    case 'text': {
      return <BlockTextForm register={register} blockData={blockData} fieldName={fieldName} />;
    }
    case 'image': {
      return <BlockImageForm register={register} blockData={blockData} />;
    }
  }
};

export const BlockDataModal: React.FC<TProps> = ({ type, description, fieldName, blockData, onSubmit }) => {
  const { cvRow, cvFooter } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm({ shouldUseNativeValidation: true, defaultValues: blockData });
  const { t } = useTranslation();

  const { hideModal } = useModal();

  const hideAndSubmit = async (data: TBlocksFormData) => {
    const convertedData = await convertDataFilesToBase64Format(data);
    onSubmit(convertedData);
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <div className={cvRow}>{description && <h5>{description}</h5>}</div>
        <Form onSubmit={handleSubmit<any>(hideAndSubmit)}>
          <div className={cvRow}>{getBlockFormByType({ type, register, fieldName, blockData })}</div>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">{t('general.buttons.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default BlockDataModal;
