import React from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { WebclientConfig } from '@conversed/shared/src/central/dto/webclient-config.dto';
import { useModal } from '../../../../hooks/modal/useModal';
import ColorInput from '../../../input/ColorInput';
import TextInput from '../../../input/TextInput';
import NumberInput from '../../../input/NumberInput';
import DropDownInput from '../../../input/DropDownInput';
import ImageInput from '../../../input/ImageInput';
import WebclientInputGroup from './WebclientInputGroup';
import BooleanInput from '../../../input/BooleanInput';
import { WebclientFormData, WebclientFormInputData } from '../../../../interfaces/IWebclient';
import { convertDataFilesToBase64Format } from '../../../../helpers/fileHelper';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useTranslation } from 'react-i18next';
import { WebclientMenu } from './menu/WebclientMenu';

interface Props {
  data: WebclientConfig;
  name: string;
  onSubmit: (data: WebclientFormData) => void;
}

//TODO: Remove once menuTemplate issues are fixed
const objectToArray = (possibleObject: any) => {
  if (!possibleObject) {
    return [];
  }

  if (!Array.isArray(possibleObject)) {
    return Object.values(possibleObject);
  }

  return possibleObject.map((obj: any) => Object.values(obj));
};

export const WebclientDataModal: React.FC<Props> = ({ data, name, onSubmit }) => {
  const { t } = useTranslation();
  const defaultValues = {
    ...data,
    options: {
      ...data.options,
      allowTextInput: data.options.allowTextInput ?? true,
      alwaysRestart: data.options.alwaysRestart ?? false,
    },
  };

  const reAddDefaultValuesToData = (data: WebclientFormInputData): WebclientFormInputData => {
    return {
      ...data,
      options: {
        ...data.options,
        icons: {
          ...data.options.icons,
          logo: {
            ...data.options.icons.logo,
            path: data.options.icons.logo.path || defaultValues.options.icons.logo.path,
          },
          speechIcon: data.options.icons.speechIcon || defaultValues.options.icons.speechIcon,
          chatIcon: {
            ...data.options.icons.chatIcon,
            closed: data.options.icons.chatIcon.closed || defaultValues.options.icons.chatIcon.closed,
            open: data.options.icons.chatIcon.open || defaultValues.options.icons.chatIcon.closed,
          },
        },
        greeting: {
          ...data.options.greeting,
          messageIcon: data.options.greeting.messageIcon || defaultValues.options.greeting.messageIcon,
        },
      },
    };
  };

  const { register, handleSubmit, control, watch } = useForm<any>({
    shouldUseNativeValidation: true,
    defaultValues: {
      name,
      ...{
        ...defaultValues,
      },
    },
  });

  const { hideModal } = useModal();

  const hideAndSubmit = async (data: WebclientFormInputData) => {
    const formData = reAddDefaultValuesToData(data);
    const convertedData = await convertDataFilesToBase64Format(formData);

    onSubmit({
      name: convertedData.name,
      config: {
        ...{
          ...convertedData,
          options: {
            ...convertedData.options,
            menuTemplate: objectToArray(convertedData?.options?.menuTemplate), // FIXME: menuTemplate is an object here or array of objects with arrayIndex as key find out why and fix
          },
          name: undefined,
        },
      },
    });
    hideModal();
  };
  const { cvFooter } = convertKeysToCamelCase(style);

  return (
    <Modal.Body>
      <Form onSubmit={handleSubmit<WebclientFormInputData>(hideAndSubmit)}>
        <Row>
          <Form.Group>
            <WebclientInputGroup title={t('settings.webclient.config.form.group.name')}>
              <TextInput label={t('settings.webclient.config.form.config.name')} name="name" register={register} />
            </WebclientInputGroup>

            <WebclientInputGroup title={t('settings.webclient.config.form.group.texts')}>
              <TextInput
                label={t('settings.webclient.config.form.config.title')}
                name="options.title"
                register={register}
              />
              <TextInput
                label={t('settings.webclient.config.form.config.subtitle')}
                name="options.subtitle"
                register={register}
              />
              <TextInput
                label={t('settings.webclient.config.form.config.welcome-message')}
                name="options.welcomeMessage"
                register={register}
              />
              <TextInput
                label={t('settings.webclient.config.form.config.placeholder')}
                name="options.messagePlaceholder"
                register={register}
              />
              <TextInput
                label={t('settings.webclient.config.form.config.greeting-button')}
                name="options.greeting.buttonText"
                register={register}
              />
            </WebclientInputGroup>

            <WebclientInputGroup title={t('settings.webclient.config.form.group.options')}>
              <TextInput
                label={t('settings.webclient.config.form.config.ref-param')}
                name="options.refParam"
                register={register}
              />
              <WebclientMenu
                label={t('settings.webclient.config.form.config.menu.label')}
                name="options.menuTemplate"
                watch={watch}
                control={control}
              />
              <TextInput
                label={t('settings.webclient.config.form.config.language')}
                name="options.language"
                register={register}
              />

              <DropDownInput
                label={t('settings.webclient.config.form.config.window-state')}
                values={['greeting', 'fullscreen', 'open', 'closed']}
                name="options.defaultState"
                register={register}
              />
              <DropDownInput
                label={t('settings.webclient.config.form.config.logo-type')}
                values={['circle', 'square']}
                name="options.icons.logo.type"
                register={register}
              />
              <DropDownInput
                label={t('settings.webclient.config.form.config.header-type')}
                values={['slim', 'normal', 'noimage']}
                name="options.headerType"
                register={register}
              />
              <NumberInput
                label={t('settings.webclient.config.form.config.greeting-timeout')}
                name="options.greetingTimeout"
                register={register}
              />
              <BooleanInput
                label={t('settings.webclient.config.form.config.show-close-button')}
                name="options.showCloseButton"
                register={register}
              />
              <BooleanInput
                label={t('settings.webclient.config.form.config.enable-attachments')}
                name="options.useAttachment"
                register={register}
              />
              <BooleanInput
                label={t('settings.webclient.config.form.config.enable-text-input')}
                name="options.allowTextInput"
                register={register}
              />
              <BooleanInput
                label={t('settings.webclient.config.form.config.always-restart')}
                name="options.alwaysRestart"
                register={register}
              />
            </WebclientInputGroup>

            <WebclientInputGroup title={t('settings.webclient.config.form.group.icons')}>
              <ImageInput
                label={t('settings.webclient.config.form.config.chat-icon-open')}
                value={defaultValues.options.icons.chatIcon.open}
                name="options.icons.chatIcon.open"
                register={register}
              />
              <ImageInput
                label={t('settings.webclient.config.form.config.chat-icon-closed')}
                value={defaultValues.options.icons.chatIcon.closed}
                name="options.icons.chatIcon.closed"
                register={register}
              />
              <ImageInput
                label={t('settings.webclient.config.form.config.logo')}
                value={defaultValues.options.icons.logo.path}
                name="options.icons.logo.path"
                register={register}
              />
              <ImageInput
                label={t('settings.webclient.config.form.config.speech-icon')}
                value={defaultValues.options.icons.speechIcon}
                name="options.icons.speechIcon"
                register={register}
              />
              <ImageInput
                label={t('settings.webclient.config.form.config.greeting-icon')}
                value={defaultValues.options.greeting.messageIcon ?? ''}
                name="options.greeting.messageIcon"
                register={register}
              />
            </WebclientInputGroup>

            <WebclientInputGroup title={t('settings.webclient.config.form.group.header-colors')}>
              <ColorInput
                label={t('settings.webclient.config.form.config.header-background')}
                name="scheme.header.headerColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.header-title-color')}
                name="scheme.header.headerTitleColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.header-subtitle-color')}
                name="scheme.header.headerSubtitleColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.header-window-color')}
                name="scheme.header.windowColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.header-message-bar-color')}
                name="scheme.header.messageBarColor"
                control={control}
              />
            </WebclientInputGroup>

            <WebclientInputGroup title={t('settings.webclient.config.form.group.message-colors')}>
              <ColorInput
                label={t('settings.webclient.config.form.config.bot-bubble-background')}
                name="scheme.message.messageBubbleColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.bot-bubble-text')}
                name="scheme.message.messageBubbleTextColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.user-bubble-background')}
                name="scheme.message.personalMessageBubbleColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.user-bubble-text')}
                name="scheme.message.personalMessageBubbleTextColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.button-edge')}
                name="scheme.message.buttonEdgeColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.button-text')}
                name="scheme.message.buttonTextColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.button-hover')}
                name="scheme.message.buttonHoverColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.carousel-background')}
                name="scheme.message.carouselBackgroundColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.carousel-button-background')}
                name="scheme.message.carouselButtonColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.carousel-text')}
                name="scheme.message.carouselTextColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.carousel-button-text')}
                name="scheme.message.carouselButtonTextColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.submit-button')}
                name="scheme.message.submitButtonColor"
                control={control}
              />
            </WebclientInputGroup>

            <WebclientInputGroup title={t('settings.webclient.config.form.group.greeting-colors')}>
              <ColorInput
                label={t('settings.webclient.config.form.config.greeting-title')}
                name="scheme.greeting.titleColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.greeting-subtitle')}
                name="scheme.greeting.subtitleColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.greeting-button-text')}
                name="scheme.greeting.buttonTextColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.greeting-button-background')}
                name="scheme.greeting.buttonColor"
                control={control}
              />
              <ColorInput
                label={t('settings.webclient.config.form.config.greeting-background')}
                name="scheme.greeting.backgroundColor"
                control={control}
              />
            </WebclientInputGroup>
          </Form.Group>

          <Modal.Footer className={`${cvFooter}`}>
            <Button type="submit">{t('general.buttons.save')}</Button>
          </Modal.Footer>
        </Row>
      </Form>
    </Modal.Body>
  );
};

export default WebclientDataModal;
