import { useLabels } from '../settings/labels/useLabels';
import { useMainSelector } from '../useMainSelector';
import { useConversationLabelActions } from './useConversationLabelActions';
import { TLabel } from '../../interfaces/TChat';
import { useCallback, useMemo } from 'react';

export const useLabelSelector = () => {
  const {
    chat: { selectedConversation },
  } = useMainSelector();

  const { labels } = useLabels();

  const { assignLabelToConversation, removeLabelFromConversation } = useConversationLabelActions();

  const selectedLabels = useMemo(() => selectedConversation?.labels || [], [selectedConversation?.labels]);

  const selectLabel = useCallback(
    (label: TLabel) => {
      if (selectedLabels.map((label) => label.identifier).includes(label.identifier)) {
        return removeLabelFromConversation(label.identifier);
      }

      assignLabelToConversation(label);
    },
    [selectedLabels, assignLabelToConversation, removeLabelFromConversation],
  );

  return { selectedLabels, labels, selectLabel };
};
