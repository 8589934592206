import { MaterialIcon } from '../../../components/common/MaterialIcon';
import { useChatInput } from '../../../hooks/chat/useChatInput';
import styles from '../../../assets/scss/components/chat/ChatInput.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import { ChatEmojiPicker } from './ChatEmojiPicker';
import { useCannedResponsesMenu } from '../../../hooks/chat/useCannedResponsesMenu';
import { useTranslation } from 'react-i18next';

export const ChatInputFooter = () => {
  const { chatMode, sendMessage } = useChatInput();
  const { t } = useTranslation();
  const { toggleCannedResponsesMenuOpen } = useCannedResponsesMenu();

  const { chatInputBottom, active, inputIcons, icon, buttonSend } = convertKeysToCamelCase(styles);

  const noteChatClassName = getCSS(chatMode === 'note', active);

  return (
    <>
      <div className={`d-flex justify-content-between ${chatInputBottom} ${noteChatClassName}`}>
        <div className={`d-flex justify-content-between ${inputIcons}`}>
          <ChatEmojiPicker />
          <MaterialIcon icon={'attach_file'} classes={[icon]} />
          <MaterialIcon icon={'quickreply'} classes={[icon]} onClick={() => toggleCannedResponsesMenuOpen()} />
        </div>

        <div onClick={() => sendMessage()} role="button" className={`btn btn-primary ${buttonSend} `}>
          <p>{chatMode === 'reply' ? t('chat.input.action.send') : t('chat.input.action.note')}</p>
          <MaterialIcon icon={'send'} classes={[icon]} />
        </div>
      </div>
    </>
  );
};
