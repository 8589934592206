export const flattenObject = (obj?: Record<string, any>, prefix = '') =>
  obj
    ? Object.keys(obj).reduce<Record<string, any>>((acc, k) => {
        const pre = prefix.length ? `${prefix}.` : '';
        if (typeof obj[k] === 'object' && obj[k] !== null && Object.keys(obj[k]).length > 0)
          Object.assign(acc, flattenObject(obj[k], pre + k));
        else acc[pre + k] = obj[k];
        return acc;
      }, {})
    : {};

export const objectDeepMapPromise = async (object: any, map: (v: any) => any) => {
  if (object === null || typeof object !== 'object' || object instanceof FileList) {
    const result = await map(object);
    return result;
  }

  const mappedObject: any = {};

  await Promise.all(
    Object.keys(object).map(async (key) => {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        mappedObject[key] = await objectDeepMapPromise(object[key], map);
      }
    }),
  );

  return mappedObject;
};
