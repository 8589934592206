import { useCallback, useMemo, useState } from 'react';
import { ASSIGNEE_VALUES } from '../../constants/chat';
import { containsIgnoreCase } from '../../helpers/baseHelpers';
import { TAssignee } from '../../interfaces/TChat';
import useChat from '../useChat';
import { useMainSelector } from '../useMainSelector';

const getAssigneeDisplayName = (assignee: TAssignee) =>
  !assignee.firstName && !assignee.lastName ? assignee.email : `${assignee.firstName} ${assignee.lastName}`.trim();

export const useSelectAssignedAgent = () => {
  const { assignUserToAgent } = useChat();

  const [searchValue, setSearchValue] = useState('');

  const {
    chat: { selectedConversation },
    user,
  } = useMainSelector();

  const assignedAgent = useMemo(() => {
    const agent = selectedConversation?.assignedAgent;

    if (!agent?.id) {
      return ASSIGNEE_VALUES.UNASSIGNED;
    }
    if (agent?.id === user.currentUser?.id) {
      return ASSIGNEE_VALUES.ME;
    }

    return `${agent.firstName} ${agent.lastName}`;
  }, [selectedConversation?.assignedAgent, user.currentUser?.id]);

  const agents = useMemo(() => {
    if (!user.currentUser?.id) {
      return [];
    }

    const assigneeIDList = selectedConversation?.assigneeList ?? [];

    const assigneeList = assigneeIDList
      ?.filter((agent) => agent.id !== user.currentUser?.id)
      ?.filter((agent) => containsIgnoreCase(getAssigneeDisplayName(agent) || '', searchValue))
      ?.map((agent) => {
        const displayName = getAssigneeDisplayName(agent);
        return { id: agent.id, name: displayName, email: agent.email };
      });

    if (selectedConversation?.assignedAgent?.id) {
      return [{ name: ASSIGNEE_VALUES.UNASSIGNED, email: null, id: null }, ...assigneeList];
    }

    if (
      assignedAgent === ASSIGNEE_VALUES.UNASSIGNED &&
      assigneeList.length !== selectedConversation?.assigneeList?.length
    ) {
      return [{ name: ASSIGNEE_VALUES.ME, email: user.currentUser.email, id: user.currentUser.id }, ...assigneeList];
    }

    return assigneeList;
  }, [
    user.currentUser?.id,
    user.currentUser?.email,
    selectedConversation?.assigneeList,
    selectedConversation?.assignedAgent?.id,
    assignedAgent,
    searchValue,
  ]);

  const setAssignedAgent = useCallback(
    (agentId: string | null) => {
      if (agentId === ASSIGNEE_VALUES.ME && user.currentUser?.id) {
        assignUserToAgent(user.currentUser.id);
      } else {
        assignUserToAgent(agentId);
      }
    },
    [assignUserToAgent, user.currentUser?.id],
  );

  const canSelfAssign = useMemo(
    () =>
      !selectedConversation?.assignedAgent?.id &&
      selectedConversation?.assigneeList?.find((agent) => agent.id === user.currentUser?.id),
    [selectedConversation?.assignedAgent, selectedConversation?.assigneeList, user.currentUser?.id],
  );

  return { agents, assignedAgent, canSelfAssign, setAssignedAgent, searchValue, setSearchValue };
};
