import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { ReactFlowReferenceContext } from '../DesignerContent';

export const FullscreenButton: React.FC = () => {
  const { t } = useTranslation();
  const context = useContext(ReactFlowReferenceContext);
  const { button } = convertKeysToCamelCase(style);

  const requestFullscreen = useCallback(() => {
    if (!context?.current) {
      return;
    }

    context?.current.requestFullscreen();
  }, [context]);

  return (
    <GeneralTooltip id={'tooltip-flow-layout-save-button'} text={t('flow.controls.fullscreen')}>
      <div className={button} onClick={() => requestFullscreen()}>
        <MaterialIcon icon={'open_in_full'} />
      </div>
    </GeneralTooltip>
  );
};
