import React, { useEffect, useMemo } from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import ContentTitle from './common/ContentTitle';
import { Form, Col, Button } from 'react-bootstrap';
import { useConnectionSettingsInit } from '../../hooks/settings/company/useConnectionSettingsInit';
import { useCompanySettings } from '../../hooks/settings/company/useCompanySettings';
import { useForm } from 'react-hook-form';
import { useCompanySettingsActions } from '../../hooks/settings/company/useCompanyActions';
import { useTranslation } from 'react-i18next';
import { CompanyConnectionSettingsDTO } from '@conversed/shared/src/central/dto/company';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { usePermissions } from '../../hooks/usePermissions';

export const ConnectionSettings: React.FC = () => {
  useConnectionSettingsInit();
  const { connectionSettings } = useCompanySettings();
  const { updateConnectionSettings } = useCompanySettingsActions();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  const { register, handleSubmit, reset } = useForm<CompanyConnectionSettingsDTO>({
    shouldUseNativeValidation: true,
    defaultValues: useMemo(() => connectionSettings, [connectionSettings]),
  });

  useEffect(() => {
    reset(connectionSettings);
  }, [connectionSettings, reset]);

  const { settingsForm, sectionCategory, inputForm, form, input } = convertKeysToCamelCase(style);

  const hideAndSubmit = (connectionSettings: CompanyConnectionSettingsDTO) => {
    updateConnectionSettings(connectionSettings);
  };

  return (
    <div className={settingsForm}>
      <ContentTitle title={t('connection-settings.title')} />
      <div className={`${sectionCategory} ${form}`}>
        <Form onSubmit={handleSubmit<CompanyConnectionSettingsDTO>(hideAndSubmit)}>
          <Form.Group as={Col} className={`${inputForm}`}>
            <p>{t('connection-settings.database')}</p>
            <Form.Control
              className={input}
              {...register('dbConnection', { required: t('general.validation.required') })}
            />
          </Form.Group>
          <Form.Group as={Col} className={`${inputForm}`}>
            <p>{t('connection-settings.backend')}</p>
            <Form.Control
              className={input}
              {...register('backendConnection', { required: t('general.validation.required') })}
            />
          </Form.Group>
          {hasPermissions([PERMISSIONS.COMPANY.COMPANY_CONNECTION_SETTINGS_EDIT]) && (
            <Button type="submit" className={`mt-4`}>
              <span> {t('general.buttons.save')}</span>
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ConnectionSettings;
