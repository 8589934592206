import React from 'react';
import { ChatSidebar } from '../../../chat/chat-sidebar/ChatSidebar';

export const ChatListPage: React.FC = () => {
  return (
    <div className="d-flex flex-row flex-grow-1">
      <ChatSidebar />
    </div>
  );
};
