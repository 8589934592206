import React from 'react';

import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { Form, Row, Col, Container } from 'react-bootstrap';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { TBlocksFormData } from '../../../../interfaces/TDesigner';
import { useTranslation } from 'react-i18next';

interface IProps {
  register: UseFormRegister<Partial<TBlocksFormData>>;
  blockData?: Partial<TBlocksFormData>;
  fieldName?: string;
}

export const BlockImageForm: React.FC<IProps> = ({ register, blockData }) => {
  const { inputForm } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const imageUrl = blockData?.imageUrl ?? '';

  const IMAGE_SIZE = 'sm';

  return (
    <Row>
      <Container fluid>
        <Row>
          <Col>
            <Form.Group>
              <p>{imageUrl && `Image - ${imageUrl}`} </p>
              <Form.Control
                className={`${inputForm}`}
                type="file"
                accept="image/png, image/jpeg"
                size={IMAGE_SIZE}
                {...register('imageUrl', {
                  value: '', // * Must be explicitly set as defaultValues messes with this, you cannot set anything else programmatically as a default value for input type file
                  validate: {
                    lessThan10MB: (files: any) => {
                      // * If a file exists check if it is under 5 MB
                      if (!files) return true;
                      return files[0]?.size < 5000000 || t('general.validation.image.size');
                    },
                    acceptedFormats: (files: any) => {
                      // * If a file exists check if it is a valid format
                      if (!files) return true;
                      return (
                        ['image/jpeg', 'image/png'].includes(files[0]?.type) || t('general.validation.image.format')
                      );
                    },
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
