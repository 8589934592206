import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { TCannedResponse, TCannedResponseUpdatable } from '../../../../interfaces/TChat';
import { useTranslation } from 'react-i18next';

type Props = {
  cannedResponseData?: Partial<TCannedResponse>;
  onSubmit: (data: TCannedResponseUpdatable) => void;
};

type TCannedResponseFormData = TCannedResponseUpdatable;

export const CannedResponseDataModal: React.FC<Props> = ({ cannedResponseData, onSubmit }) => {
  const { input, cvRow, cvFooter } = convertKeysToCamelCase(style);

  const { register, handleSubmit } = useForm<TCannedResponseFormData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      message: cannedResponseData?.message,
      shorthand: cannedResponseData?.shorthand,
    },
  });

  const { t } = useTranslation();
  const { hideModal } = useModal();

  const hideAndSubmit = (data: TCannedResponseFormData) => {
    onSubmit({
      message: data.message,
      shorthand: data.shorthand,
    });
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <Form onSubmit={handleSubmit<TCannedResponseFormData>(hideAndSubmit)}>
          <Row>
            <Form.Group className={cvRow}>
              <p>{t('settings.canned-response.shorthand')}</p>
              <Form.Control
                className={input}
                {...register('shorthand', { required: t('settings.canned-response.validation.shorthand') })}
              />
            </Form.Group>
            <Form.Group className={cvRow}>
              <p>{t('settings.canned-response.response')}</p>
              <Form.Control
                className={input}
                {...register('message', { required: t('settings.canned-response.response') })}
              />
            </Form.Group>
          </Row>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">{t('general.buttons.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default CannedResponseDataModal;
