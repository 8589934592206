import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { SpinnerComponent } from 'react-element-spinner';

import { AuthenticatedLayout } from './layouts/AuthenticatedLayout';
import { UnauthenticatedLayout } from './layouts/UnauthenticatedLayout';
import { useMainSelector } from '../hooks/useMainSelector';
import { ModalContainer } from './layouts/modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import history from '../config/history';

export const Main: React.FC = () => {
  const {
    login: { isLoggedIn },
    ui: { isLoading },
  } = useMainSelector();
  const { t } = useTranslation();

  const SPINNER_POSITION = 'global';

  return (
    <>
      <SpinnerComponent loading={isLoading} position={SPINNER_POSITION} message={t('general.status.loading')} />
      <Router history={history}>{isLoggedIn ? <AuthenticatedLayout /> : <UnauthenticatedLayout />}</Router>
      <ToastContainer hideProgressBar={true} transition={Slide} autoClose={3000} />
      <ModalContainer />
    </>
  );
};
