import React from 'react';
import { Form } from 'react-bootstrap';
import { IFileProps } from '../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/common/Modal.module.scss';
import { useTranslation } from 'react-i18next';

export const ImageInput: React.FC<IFileProps> = ({ label, value, name, register }) => {
  const { modalLabel, imageInputValue, inputField } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const IMAGE_SIZE = 'sm';
  return (
    <Form.Group>
      <p className={modalLabel}>{label}</p>
      <p className={imageInputValue}>{value}</p> {/* * Display the value from defaultValues here*/}
      <Form.Control
        className={inputField}
        type="file"
        accept="image/png, image/jpeg"
        size={IMAGE_SIZE}
        {...register(name, {
          value: '', // * Must be explicitly set as defaultValues messes with this, you cannot set anything else programmatically as a default value for input type file
          validate: {
            lessThan10MB: (files) => {
              // * If a file is not set or we use the default
              if (typeof files === 'string') return true;
              // * If a file exists check if it is under 5 MB
              if (!files) return true;
              return files[0]?.size < 5000000 || t('general.validation.image.size');
            },
            acceptedFormats: (files) => {
              // * If a file is not set or we use the default
              if (typeof files === 'string') return true;
              // * If a file exists check if it is a valid format
              if (!files) return true;
              return ['image/jpeg', 'image/png'].includes(files[0]?.type) || t('general.validation.image.format');
            },
          },
        })}
      />
    </Form.Group>
  );
};

export default ImageInput;
