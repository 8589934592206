import React from 'react';
import { Form } from 'react-bootstrap';
import { IChipProps } from '../../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/common/Modal.module.scss';
import { Controller } from 'react-hook-form';
import ChipInputField from './ChipInputField';

export const ChipInput: React.FC<IChipProps> = ({ name, label, control }) => {
  const { cvRow } = convertKeysToCamelCase(style);

  return (
    <Form.Group className={`${cvRow}`}>
      <p>{label}</p>
      <Controller
        control={control}
        name={name}
        render={({ field: { name, ref, value, onChange } }) => (
          <ChipInputField name={name} ref={ref} value={value} onChange={onChange} />
        )}
      />
    </Form.Group>
  );
};

export default ChipInput;
