import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import {
  DTOGetFlow,
  DTOGetFlowEntries,
  DTOPutFlow,
  DTOPutFlowResponse,
} from '@conversed/shared/src/central/dto/flow.dto';
import { DTOPutMedia, DTOPutMediaResponse } from '@conversed/shared/src/central/dto/media.dto';

export const getBlocksApi = (): TGeneralResponse<DTOGetFlow> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.FLOW.FLOW);

export const putBlocksApi = (blockUpdates: DTOPutFlow): TGeneralResponse<DTOPutFlowResponse> =>
  axiosInterceptAccessTokenExpiry().put(ENDPOINTS.FLOW.FLOW, blockUpdates);

export const putBlockMediaApi = (data: DTOPutMedia): TGeneralResponse<DTOPutMediaResponse> =>
  axiosInterceptAccessTokenExpiry().put(ENDPOINTS.FLOW.UPLOAD, data);

export const getBlockEntryPointsApi = (): TGeneralResponse<DTOGetFlowEntries> =>
  axiosInterceptAccessTokenExpiry().get(ENDPOINTS.FLOW.ENTRYPOINTS);
