import { mapObject } from '@conversed/shared/src/helpers/data.helpers';

// TODO: Find a way to strictly check style types, then we can use typechecking for errors
// * Replace the keys of style objects to camelCase (ex login-form-auth becomes)
export const convertKeysToCamelCase = (styleObject: Record<string, string>): Record<string, string> =>
  mapObject(styleObject, ([key, value]) => {
    const newKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '')).replace('-', '');

    return {
      [newKey]: value,
    };
  });

export const getCSS = (condition: boolean, className: string) => (condition ? className : '');

export const containsIgnoreCase = (text: string, searchText: string) =>
  text.toLowerCase().includes(searchText.toLowerCase());
