import { TChatStatusType } from '../../interfaces/TChat';
import { useQueryState } from '../useQueryState';

const CHAT_STATUS_QUERY_PARAM_KEY = 'chatStatus';

export const useSelectedChatStatus = () => {
  const { query, setQuery } = useQueryState(CHAT_STATUS_QUERY_PARAM_KEY, 'open');

  return {
    chatStatus: query as TChatStatusType,
    setChatListStatus: (chatStatus: TChatStatusType) => setQuery(chatStatus),
  };
};
