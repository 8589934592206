import { useChatInput } from '../../../hooks/chat/useChatInput';
import styles from '../../../assets/scss/components/chat/ChatInput.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../../common/MaterialIcon';

export const ChatInputHeader = () => {
  const { chatMode, setChatMode } = useChatInput();
  const { t } = useTranslation();

  const { chatInputHeader, inputTabs, tab, reply, note, active } = convertKeysToCamelCase(styles);

  const replyChatClassName = getCSS(chatMode === 'reply', active);
  const noteChatClassName = getCSS(chatMode === 'note', active);

  return (
    <div className={`d-flex justify-content-between ${chatInputHeader}`}>
      <div className={`d-flex flex-row ${inputTabs}`}>
        <div onClick={() => setChatMode('reply')} role="button" className={`${tab} ${reply} ${replyChatClassName}`}>
          <p>{t('chat.input.menu.reply')}</p>
        </div>
        <div onClick={() => setChatMode('note')} role="button" className={`${tab} ${note} ${noteChatClassName}`}>
          <p>{t('chat.input.menu.note')}</p>
        </div>
      </div>
      <div className={`${tab}`}>
        <MaterialIcon icon={'format_size'} role={'button'} />
      </div>
    </div>
  );
};
