import { IHistoryMessage } from '@conversed/shared/src/central/types/chat.types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../../assets/scss/components/chat/chat-content/ChatContent.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useMainSelector } from '../../../hooks/useMainSelector';
import { useTime } from '../../../hooks/useTime';

interface IChatInterMessageProps {
  message: IHistoryMessage;
  time: string;
}

const messagesByType = {
  assign: 'chat.content.meta-message.assign',
  unassign: 'chat.content.meta-message.unassign',
} as const;

export const ChatMetaMessage: React.FC<IChatInterMessageProps> = ({ message, time }) => {
  const { assign } = convertKeysToCamelCase(styles);

  const {
    user: { currentUser },
  } = useMainSelector();
  const { t } = useTranslation();
  const { date: timestamp } = useTime(time, 'MMMM DD, YYYY HH:mm');

  const { agent, triggeredBy, type } = message;

  // TODO: Replace this with value from backend
  const action = useMemo(() => (type === 'assign' && agent?.id ? type : 'unassign'), [type, agent]);

  const subjectByContext = useMemo(() => {
    if (type !== 'assign') return '';

    // TODO: Find a way to extract pronouns
    if (triggeredBy?.id === agent?.id && agent?.id === currentUser?.id) return 'yourself';
    if (agent?.id === currentUser?.id) return 'you';
    if (triggeredBy?.id === agent?.id) return 'themselves';

    return `${agent?.firstName} ${agent?.lastName}`;
  }, [type, currentUser, agent, triggeredBy]);

  const triggeredByContext = useMemo(() => {
    if (triggeredBy?.id === currentUser?.id) return 'You';
    return `${triggeredBy?.firstName} ${triggeredBy?.lastName}`;
  }, [currentUser, triggeredBy]);

  const messageID = messagesByType[action];

  return (
    <p className={assign}>
      <span>
        {t(messageID, {
          subject: subjectByContext,
          triggeredBy: triggeredByContext,
          timestamp,
        })}
      </span>
    </p>
  );
};
