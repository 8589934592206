import style from '../../../assets/scss/components/sidebar/AppSidebar.module.scss';
import { Link } from 'react-router-dom';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { PRIVACY_POLICY_ROUTE, SETTINGS_PAGE_ROUTE, TERMS_AND_CONDITIONS_ROUTE } from '../../../constants/routes';
import SidebarItem from './SidebarItem';
import { useTranslation } from 'react-i18next';

export const SidebarFooter = () => {
  const { sidebarFooter, wrapper, legalsWrapper, footer } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={sidebarFooter}>
      <SidebarItem icon={'settings'} to={SETTINGS_PAGE_ROUTE} text={'sidebar.footer.settings'} />
      <div className={footer}>
        <div className={wrapper}>
          <p>{t('sidebar.footer.powered-by')}</p>
          <a href="https://conversed.ai" target="_blank" rel="noopener noreferrer">
            {t('sidebar.footer.conversed')}
          </a>
        </div>
        <div className={legalsWrapper}>
          <Link to={TERMS_AND_CONDITIONS_ROUTE}>{t('sidebar.footer.terms')}</Link>
          <Link to={PRIVACY_POLICY_ROUTE}>{t('sidebar.footer.privacy')}</Link>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
