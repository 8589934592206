import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { splitIntoLines } from '../../../../helpers/domHelper';

export const LinkInfoModal: React.FC = () => {
  const { t } = useTranslation();
  const text = t('link-settings.info.text');
  return <Modal.Body>{splitIntoLines(text)}</Modal.Body>;
};

export default LinkInfoModal;
