import { useMemo, useEffect } from 'react';
import { IMessageGroup, TChatHistory } from '../../interfaces/IMessage';
import { useChatActions } from '../useChatActions';
import { useMainSelector } from '../useMainSelector';
import { useChatMode } from './useChatMode';

export const useChatMessages = () => {
  const {
    chat: { selectedConversation },
  } = useMainSelector();

  const { chatMode } = useChatMode();

  const { getConversationMessages } = useChatActions();

  useEffect(() => {
    if (selectedConversation?.id) {
      getConversationMessages(selectedConversation.id);
    }
  }, [getConversationMessages, selectedConversation?.id]);

  const conversationMessages = useMemo(() => selectedConversation?.messages || [], [selectedConversation]);

  const filteredUserMessages = useMemo(
    () => conversationMessages.filter(({ type }) => type === 'note'),
    [conversationMessages],
  );

  const messages = useMemo(
    () => (chatMode === 'reply' ? conversationMessages : filteredUserMessages),
    [chatMode, conversationMessages, filteredUserMessages],
  );

  const messageGroups = useMemo(
    () =>
      messages.reduce((messageGroups: IMessageGroup[], current: TChatHistory) => {
        const lastGroup = messageGroups[messageGroups.length - 1];
        if (lastGroup?.direction !== current.direction) {
          messageGroups.push({
            messages: [current],
            direction: current.direction,
            key: new Date(current.time).getTime().toString(),
          });
        } else {
          lastGroup.messages.push(current);
        }
        return messageGroups;
      }, []),
    [messages],
  );

  return {
    messageGroups,
  };
};
