import { TChatHistory } from '../../../interfaces/IMessage';
import { ChatMetaMessage } from './ChatMetaMessage';
import ImageCarouselMessage from './messages/ImageCarouselMessage';
import ImageMessage from './messages/ImageMessage';
import { TextMessage } from './messages/TextMessage';
import { TextWithButtonsMessage } from './messages/TextWithButtonsMessage';
import { PrivateNote } from './PrivateNote';

interface IChatMessageProps {
  message: TChatHistory;
  time: string;
  messageLocation: string;
}

export const ChatMessage: React.FC<IChatMessageProps> = ({ messageLocation, message, time }) => {
  if (!message?.type || !message.message) {
    return null;
  }

  switch (message?.type) {
    case 'text':
    case 'button':
    case 'databaseUpdate': {
      return <TextMessage messageLocation={messageLocation} text={message.message?.text} />;
    }
    case 'textWithButtons': {
      return (
        <TextWithButtonsMessage
          messageLocation={messageLocation}
          text={message.message?.text}
          buttons={message.message?.buttons}
        />
      );
    }
    case 'image': {
      return <ImageMessage messageLocation={messageLocation} image={message.message?.image} />;
    }
    case 'imageCarousel': {
      return <ImageCarouselMessage messageLocation={messageLocation} elements={message.message?.elements} />;
    }
    case 'note': {
      return <PrivateNote text={message.message?.text} />;
    }
    case 'meta': {
      // If type does not exist then render nothing
      if (!message.message.type) return <></>;
      return <ChatMetaMessage time={time} message={message.message} />;
    }
    default: {
      return null;
    }
  }
};

export default ChatMessage;
