import React from 'react';
import ContentTitle from '../common/ContentTitle';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/account-settings/AccountInformation.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useImageUpload } from '../../../hooks/useImageUpload';
import { useAccountSettingsForm } from '../../../hooks/user/useAccountSettingsForm';
import { useModal } from '../../../hooks/modal/useModal';
import { useMfaActions } from '../../../hooks/auth/useMFAActions';

export const AccountSettings: React.FC = () => {
  const { t } = useTranslation();

  const { showModal } = useModal();

  const { imageUploadRef, openFileChooser } = useImageUpload();

  const { initMFA } = useMfaActions();

  const { uploadProfilePicture, profilePic, submit, register, mfaEnabled } = useAccountSettingsForm();

  const {
    profilePictureWrapper,
    profilePicture,
    iconWrapper,
    sectionCategory,
    inputGroup,
    inputForm,
    input,
    form,
    buttonSaveWrapper,
    accountInformationWrapper,
  } = convertKeysToCamelCase(style);

  return (
    <>
      <ContentTitle title={t('account-settings.title')} onClickInfo={() => showModal('account-info')} />
      <div className={profilePictureWrapper}>
        <img className={profilePicture} src={profilePic} />
        <input
          type="file"
          id="file"
          ref={imageUploadRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={uploadProfilePicture}
        />
        <div className={iconWrapper}>
          <MaterialIcon role="button" icon={'edit'} onClick={openFileChooser} />
        </div>
      </div>

      <div className={accountInformationWrapper}>
        <Form onSubmit={submit}>
          <div className={`${sectionCategory} ${form}`}>
            <h5>{t('account-settings.profile.title')}</h5>
            <Row className={`${inputGroup}`}>
              <Form.Group as={Col} className={`${inputForm}`}>
                <p>{t('account-settings.profile.first-name')}</p>
                <Form.Control className={input} {...register('firstName')} />
              </Form.Group>
              <Form.Group as={Col} className={`${inputForm}`}>
                <p>{t('account-settings.profile.last-name')}</p>
                <Form.Control className={input} {...register('lastName')} />
              </Form.Group>
            </Row>
            <Row className={`${inputGroup}`}>
              <Form.Group as={Col} className={`${inputForm}`}>
                <p>{t('account-settings.profile.email')}</p>
                <Form.Control className={input} {...register('email')} />
              </Form.Group>
            </Row>
          </div>
          <div className={`${sectionCategory} ${buttonSaveWrapper}`}>
            <Button type="submit" className={`btn btn-primary`}>
              <span> {t('general.buttons.save')}</span>
            </Button>
          </div>
        </Form>
        <div>
          <h5>{t('account-settings.2fa.label')}</h5>

          <div className="d-flex flex-row">
            <p className="m-1">{t('account-settings.2fa.status.label')}:</p>
            <p className="m-1">
              {mfaEnabled ? t('account-settings.2fa.status.enabled') : t('account-settings.2fa.status.disabled')}
            </p>
          </div>
          <p className="my-1">
            {!mfaEnabled ? t('account-settings.2fa.description') : t('account-settings.2fa.description.enabled')}
          </p>
          {mfaEnabled ? (
            <Button
              type="submit"
              onClick={() => {
                showModal('disable-2fa');
              }}
            >
              {t('account-settings.2fa.manage.button')}
            </Button>
          ) : (
            <Button
              type="submit"
              onClick={() => {
                initMFA();
                showModal('enable-2fa');
              }}
            >
              {t('account-settings.2fa.enable.button')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default AccountSettings;
