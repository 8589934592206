import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';

interface IProps {
  title: 'auth.input.email' | 'auth.input.password' | 'auth.input.password-check' | 'auth.input.2fa.title';
  name: string;
  type?: string;
  errorMessage?: string;
  registerInput: any;
  autoComplete?: string;
  minLength?: number;
  maxLength?: number;
}

export const AuthInput: React.FC<IProps> = ({
  title,
  name,
  type,
  registerInput,
  errorMessage,
  autoComplete,
  minLength,
  maxLength,
}) => {
  const { t } = useTranslation();

  const { loginFormAuthInput, loginFormAuthInputLabel, formError, emptyError } = convertKeysToCamelCase(style);

  return (
    <div className={loginFormAuthInput}>
      <div className={loginFormAuthInputLabel}>{t(title)}</div>
      <input
        {...registerInput(name)}
        type={type}
        autoComplete={autoComplete}
        minLength={minLength}
        maxLength={maxLength}
      />
      {errorMessage ? (
        //TODO: Fix this type check
        <div className={formError}>{t(`validation.${errorMessage}` as 'validation.required')}</div>
      ) : (
        <div className={emptyError}></div>
      )}
    </div>
  );
};
