import { loadFileFromReader } from './fileHelper';

const scaleToFit = (image: HTMLImageElement, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
  const x = canvas.width / 2 - (image.width / 2) * scale;
  const y = canvas.height / 2 - (image.height / 2) * scale;
  return ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
};

const scaleToFill = (image: HTMLImageElement, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  const scale = Math.max(canvas.width / image.width, canvas.height / image.height);
  const x = canvas.width / 2 - (image.width / 2) * scale;
  const y = canvas.height / 2 - (image.height / 2) * scale;
  return ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
};

const resizeImage = (file: File, width: number, height: number, mode?: 'fill' | 'fit') =>
  new Promise<string>(async (resolve, reject) => {
    const imageUrl = await loadFileFromReader(file);
    const image = new Image();
    image.src = imageUrl as string;
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return reject(`2d context not supported or canvas already initialized`);
      }

      mode === 'fit' ? scaleToFit(image, canvas, ctx) : scaleToFill(image, canvas, ctx);

      resolve(canvas.toDataURL('image/base64', 10));
    });
  });

export const resizeProfilePic = (file: File) => resizeImage(file, 360, 360, 'fill');
