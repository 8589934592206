import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import {
  CreateWebclientConfigRequestDto,
  UpdateWebclientConfigRequestDto,
  GetWebclientResponseDto,
} from '@conversed/shared/src/central/dto/webclient-config.dto';

export const getWebclientConfigsEndpoint = (): TGeneralResponse<GetWebclientResponseDto> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.WEBCLIENT}/configs`);

export const createWebclientConfigEndpoint = (
  name: string,
  config: CreateWebclientConfigRequestDto['config'],
): TGeneralResponse => axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.WEBCLIENT}/configs`, { name, config });

export const createWebclientEndpoint = (): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.WEBCLIENT}`);

export const updateWebclientConfigEndpoint = (
  identifier: string,
  name: string,
  config: UpdateWebclientConfigRequestDto['config'],
): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.WEBCLIENT}/configs/${identifier}`, { name, config });

export const deleteWebclientConfigEndpoint = (identifier: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.WEBCLIENT}/configs/${identifier}`);

export const addWebclientWhitelistEndpoint = (domain: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.WEBCLIENT}/whitelist`, { domain });

export const removeWebclientWhitelistedDomainEndpoint = (domain: string): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.WEBCLIENT}/whitelist`, { params: { domain } });
