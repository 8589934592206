import React, { RefCallback } from 'react';
import { Form } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/common/Modal.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useChipInput } from '../../../hooks/general/input/chips/useChipInput';

type TChipInputFieldProps = {
  name: string;
  value: string[];
  ref: RefCallback<unknown>;
  onChange: (...args: any[]) => void;
};

export const ChipInputField: React.FC<TChipInputFieldProps> = ({ name, ref, value: chips, onChange }) => {
  const { input, cvChipsWrapper } = convertKeysToCamelCase(style);
  const { inputValue, onChipChange, onChipBlur, onChipKeyDown, onClickRemoveChip } = useChipInput(chips, onChange);

  return (
    <div className={cvChipsWrapper}>
      {chips?.map((chip) => {
        return (
          <span key={chip}>
            {chip}
            <MaterialIcon icon={'close'} onClick={onClickRemoveChip(chip)} />
          </span>
        );
      })}

      <Form.Control
        className={input}
        name={name}
        ref={ref}
        value={inputValue}
        onChange={onChipChange}
        onBlur={onChipBlur}
        onKeyDown={onChipKeyDown}
      />
    </div>
  );
};

export default ChipInputField;
