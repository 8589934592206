import { useCallback } from 'react';
import { assignLabelToConversationApi, removeLabelFromConversationApi } from '../../api/chatApi';
import { Actions } from '../../constants/actionTypes';
import { removeValueFromList, replaceValueInList } from '@conversed/shared/src/helpers/data.helpers';
import { TLabelIdentifier, TLabel } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';

export const useConversationLabelActions = () => {
  const {
    chat: { selectedConversation, chatList },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const assignLabelToConversation = useCallback(
    async (label: TLabel) => {
      if (!selectedConversation?.id) {
        return;
      }
      try {
        await assignLabelToConversationApi(selectedConversation.id, label.identifier);

        const updatedConversation = {
          ...selectedConversation,
          labels: [...selectedConversation.labels, label],
        };

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            selectedConversation: updatedConversation,
            chatList: replaceValueInList(updatedConversation, chatList, (user) => user.id === updatedConversation.id),
          },
        });
      } catch (error) {
        // TODO: Handle errors
      }
    },
    [chatList, selectedConversation, dispatch],
  );

  const removeLabelFromConversation = useCallback(
    async (labelId: TLabelIdentifier) => {
      if (!selectedConversation?.id) {
        return;
      }
      try {
        await removeLabelFromConversationApi(selectedConversation.id, labelId);

        const updatedConversation = {
          ...selectedConversation,
          labels: removeValueFromList((label) => label.identifier === labelId, selectedConversation.labels),
        };

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            selectedConversation: updatedConversation,
            chatList: replaceValueInList(updatedConversation, chatList, (user) => user.id === updatedConversation.id),
          },
        });
      } catch (error) {
        // TODO: Handle errors
      }
    },
    [chatList, selectedConversation, dispatch],
  );

  return {
    removeLabelFromConversation,
    assignLabelToConversation,
  };
};
