import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/chat/chat-panel-sidebar/ChatPanelSidebar.module.scss';

interface IProps {
  labelName: string;
  labelColor: string;
  onRemove?: () => void;
}

export const Label: React.FC<IProps> = ({ labelName, labelColor, onRemove }) => {
  const { cvLabel, closeButton } = convertKeysToCamelCase(style);

  return (
    <>
      <span
        className={`badge ${cvLabel} m-1`}
        style={{
          backgroundColor: labelColor,
        }}
      >
        {labelName}
        {onRemove && (
          <span
            aria-hidden="true"
            className={closeButton}
            onClick={() => onRemove()}
            // TODO: Remove and find a way to allow HTML escape characters
            // eslint-disable-next-line i18next/no-literal-string
          >
            &times;
          </span>
        )}
      </span>
    </>
  );
};
