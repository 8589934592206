import React from 'react';
import LinkDataModal from './LinkDataModal';
import { useLinkActions } from '../../../../hooks/inboxes/useLinkActions';

export const CreateLinkModal: React.FC = () => {
  const { createLink } = useLinkActions();

  return <LinkDataModal onSubmit={createLink} isAllowedToEditShortLink />;
};

export default CreateLinkModal;
