import React from 'react';
import style from '../../assets/scss/components/pages/EmptyPage.module.scss';
import notFound from '../../assets/images/404.svg';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: React.FC = () => {
  const { emptyPageWrapper, message } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={emptyPageWrapper}>
      <img src={notFound} />
      <div className={message}>
        <span>{t('not-found.state')}</span>
      </div>
    </div>
  );
};
