import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import MaterialIcon from '../../../../common/MaterialIcon';
import FacebookPersistentMenuItem from './FacebookPersistentMenuItem';
import { TFacebookSettings } from '../../../../../interfaces/TFacebook';
import { MAX_PERSISTENT_MENU_ITEMS_COUNT } from '@conversed/shared/src/central/constants/boundaries';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import style from '../../../../../assets/scss/components/common/Modal.module.scss';
import AddButton from '../../../../common/AddButton';

interface Props {
  control: Control<TFacebookSettings>;
}

export const FacebookPersistentMenuList: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'persistentMenuList',
  });

  const { cvAddDelete, localeOnly } = convertKeysToCamelCase(style);

  return (
    <>
      {fields.map((field, id) => (
        <div key={field.id}>
          <FacebookPersistentMenuItem name={`persistentMenuList.${id}.`} control={control} />
          {fields.length > 1 && (
            <div className={`${cvAddDelete}`} onClick={() => remove(id)} role="button">
              <MaterialIcon icon="delete" />
              <p>{t('settings.facebook.persistent-menu.delete-locale')}</p>
            </div>
          )}
        </div>
      ))}
      {fields.length < MAX_PERSISTENT_MENU_ITEMS_COUNT && (
        <div className={`${cvAddDelete} ${localeOnly}`} onClick={() => append({ actions: [] })} role="button">
          <AddButton />
          <p>{t('settings.facebook.persistent-menu.add-new')}</p>
        </div>
      )}
    </>
  );
};

export default FacebookPersistentMenuList;
