export enum Actions {
  SET_LOGIN,
  SET_CHAT,
  SET_UI,
  SET_USER,
  SET_SETTINGS,
  SET_DESIGNER,
  SET_COLOR,
  SET_CONVERSATION_ASSIGNEE_LIST,
  SET_CHAT_MESSAGES,
  SET_SELECTED_CONVERSATION,
  SET_COMPANY_SETTINGS,
  ADD_COMPANY_MEMBER,
  TOGGLE_PERMISSION_FOR_ROLE,
  SET_ROLES,
  UPDATE_CURRENT_USER,
  UPDATE_BLOCKS,
  DELETE_BLOCKS,
  BLOCK_UPDATES_CLEAR,
  SET_ASSIGNABLE_ROLES,
  SET_LINK_STATISTICS,
}
