import React from 'react';
import { CommonButton } from '@conversed/ui/src/components/CommonButton';

export enum EButtonType {
  PRIMARY,
  SECONDARY,
}
interface IButtonProps {
  text: string;
  buttonType: EButtonType;
  type?: 'button' | 'submit' | 'reset';
}

export const GenericButton: React.FC<IButtonProps> = ({ text, buttonType, type }) => {
  const buttonClass = buttonType === EButtonType.PRIMARY ? 'btn-primary' : 'btn-secondary';

  return <CommonButton text={text} classNames={['btn', buttonClass]} type={type} />;
};
