import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../hooks/modal/useModal';
import { useBlocks } from '../../../hooks/flow/block/useBlocks';
import { useFlowActions } from '../../../hooks/flow/flow/useFlowActions';
import { useTranslation } from 'react-i18next';

export const DeleteBlockModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { selectedBlock } = useBlocks();
  const { deleteBlockFromFlow } = useFlowActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {selectedBlock && (
        <Modal.Body>
          <p className={cvRow}>
            {t('flow.blocks.delete.confirmation')}
            <b> {selectedBlock?.id}</b>.
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteBlockFromFlow(selectedBlock?.id);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteBlockModal;
