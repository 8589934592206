import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useModal } from '../../../hooks/modal/useModal';
import style from '../../../assets/scss/components/common/Modal.module.scss';
import { useTranslation } from 'react-i18next';

export const OpenChatModal: React.FC = () => {
  const { modalSection, footer } = convertKeysToCamelCase(style);
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <Modal.Body>
      <div>
        <div className={`${modalSection}`}>
          <p>{t('chat.panel.dialog.desc')}</p>
        </div>
      </div>
      <div className={`${footer}`}>
        <Modal.Footer>
          <Button
            onClick={() => {
              // TODO: Implement open chat info modal
              alert('Implement open chat info modal');
            }}
          >
            {t('chat.panel.dialog.open')}
          </Button>
          <Button
            onClick={() => {
              hideModal();
            }}
          >
            {t('chat.panel.dialog.abort')}
          </Button>
        </Modal.Footer>
      </div>
    </Modal.Body>
  );
};

export default OpenChatModal;
