import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { sendPasswordResetEmailApi } from '../../api/userApi';
import { Actions } from '../../constants/actionTypes';
import { toastErrorTranslated } from '../../helpers/toast';
import { useMainDispatch } from '../useMainDispatch';

interface IUseForgotPassword {
  forgotPassword: (email: string) => Promise<void>;
}

export const useForgotPassword = (): IUseForgotPassword => {
  const dispatch = useMainDispatch();
  const { t } = useTranslation();

  const forgotPassword = async (email: string) => {
    dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
    try {
      await sendPasswordResetEmailApi({ email });
      toast.success(t('auth.forgot-password.success.message'));
    } catch (error) {
      toastErrorTranslated(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  };

  return { forgotPassword };
};
