import style from '../../../assets/scss/components/chat/CannedResponsesMenu.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import { useCannedResponsesMenu } from '../../../hooks/chat/useCannedResponsesMenu';

export const CannedResponsesMenu: React.FC = () => {
  const { cannedResponsesMenu, cannedResponsesContainer, active } = convertKeysToCamelCase(style);
  const {
    cannedResponsesIndex: responseIndex,
    filteredCannedResponses: responses,
    setCurrentResponse,
  } = useCannedResponsesMenu();

  return responses ? (
    <div className={cannedResponsesMenu}>
      {responses.map(({ message, shorthand }, index) => {
        return (
          <div
            key={index}
            className={`${cannedResponsesContainer} ${getCSS(responseIndex === index, active)}`}
            onClick={() => setCurrentResponse(message)}
          >
            <p>{shorthand}</p>
            <p>{message}</p>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default CannedResponsesMenu;
