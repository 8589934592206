export const FB_SUPPORTED_LOCALES = [
  'default',
  'en_US',
  'ca_ES',
  'cs_CZ',
  'cx_PH',
  'cy_GB',
  'da_DK',
  'de_DE',
  'eu_ES',
  'en_UD',
  'es_LA',
  'es_ES',
  'gn_PY',
  'fi_FI',
  'fr_FR',
  'gl_ES',
  'hu_HU',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'nb_NO',
  'nn_NO',
  'nl_NL',
  'fy_NL',
  'pl_PL',
  'pt_BR',
  'pt_PT',
  'ro_RO',
  'ru_RU',
  'sk_SK',
  'sl_SI',
  'sv_SE',
  'th_TH',
  'tr_TR',
  'ku_TR',
  'zh_CN',
  'zh_HK',
  'zh_TW',
  'af_ZA',
  'sq_AL',
  'hy_AM',
  'az_AZ',
  'be_BY',
  'bn_IN',
  'bs_BA',
  'bg_BG',
  'hr_HR',
  'nl_BE',
  'en_GB',
  'et_EE',
  'fo_FO',
  'fr_CA',
  'ka_GE',
  'el_GR',
  'gu_IN',
  'hi_IN',
  'is_IS',
  'id_ID',
  'ga_IE',
  'jv_ID',
  'kn_IN',
  'kk_KZ',
  'lv_LV',
  'lt_LT',
  'mk_MK',
  'mg_MG',
  'ms_MY',
  'mt_MT',
  'mr_IN',
  'mn_MN',
  'ne_NP',
  'pa_IN',
  'sr_RS',
  'so_SO',
  'sw_KE',
  'tl_PH',
  'ta_IN',
  'te_IN',
  'ml_IN',
  'uk_UA',
  'uz_UZ',
  'vi_VN',
  'km_KH',
  'tg_TJ',
  'ar_AR',
  'he_IL',
  'ur_PK',
  'fa_IR',
  'ps_AF',
  'my_MM',
  'qz_MM',
  'or_IN',
  'si_LK',
  'rw_RW',
  'cb_IQ',
  'ha_NG',
  'ja_KS',
  'br_FR',
  'tz_MA',
  'co_FR',
  'as_IN',
  'ff_NG',
  'sc_IT',
  'sz_PL',
];
