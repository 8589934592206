import { Actions } from '../constants/actionTypes';
import { ESocketEvents } from '../constants/ESocketEvents';
import useSocket from './useSocket';
import { useCallback } from 'react';
import { useMainSelector } from './useMainSelector';
import { useMainDispatch } from './useMainDispatch';
import { assignAgentToConversation } from '../api/chatApi';
import { toastErrorTranslated } from '../helpers/toast';
import { useSelectedChatList } from './chat/useSelectedChatList';
import { useHistory, useLocation } from 'react-router';
import { CHAT_PAGE_ROUTE } from '../constants/routes';

export const useChat = () => {
  const { send } = useSocket();

  const {
    chat: { selectedConversation },
    user: { currentUser },
  } = useMainSelector();
  const dispatch = useMainDispatch();
  const { setSelectedChatList } = useSelectedChatList();
  const history = useHistory();
  const { search } = useLocation();

  const assignUserToAgent = useCallback(
    async (agentId: string | null) => {
      if (selectedConversation?.id) {
        try {
          const {
            data: { assignedAgent },
          } = await assignAgentToConversation(agentId, selectedConversation.id);
          if (currentUser?.id === assignedAgent?.id) {
            dispatch({ type: Actions.SET_SELECTED_CONVERSATION, payload: { assignedAgent } });
            setSelectedChatList('assigned-to-me');
          } else {
            history.push({ pathname: `${CHAT_PAGE_ROUTE}`, search });
          }
        } catch (error) {
          console.error(error);
          toastErrorTranslated(error);
        }
      }
    },
    [selectedConversation?.id, currentUser?.id, dispatch, setSelectedChatList, history, search],
  );

  const sendMessage = useCallback(
    (message?: string) => {
      if (selectedConversation) {
        send(ESocketEvents.NEW_AGENT_MESSAGE, {
          userData: {
            chatBotUserId: selectedConversation.id,
            chatBotPlatform: selectedConversation.platform,
          },
          data: message,
        });
      }
    },
    [selectedConversation, send],
  );

  const sendNote = useCallback(
    (message?: string) => {
      if (selectedConversation) {
        send(ESocketEvents.NEW_AGENT_NOTE, {
          userData: {
            chatBotUserId: selectedConversation.id,
            chatBotPlatform: selectedConversation.platform,
          },
          data: message,
        });
      }
    },
    [selectedConversation, send],
  );

  const sendAgentTyping = useCallback(
    (isTyping?: boolean) => {
      if (selectedConversation) {
        send(ESocketEvents.AGENT_TYPING, {
          userData: {
            chatBotUserId: selectedConversation.id,
            chatBotPlatform: selectedConversation.platform,
          },
          data: {
            action: isTyping ? 'typing_on' : 'typing_off',
          },
        });
      }
    },
    [selectedConversation, send],
  );

  const resolveChat = useCallback(() => {
    dispatch({ type: Actions.SET_CHAT, payload: { selectedConversation: undefined } });
    send(ESocketEvents.HANDOVER_TO_BOT, {
      userData: {
        chatBotPlatform: selectedConversation?.platform,
        chatBotUserId: selectedConversation?.id,
      },
    });
  }, [dispatch, selectedConversation, send]);

  return {
    sendMessage,
    sendNote,
    sendAgentTyping,
    resolveChat,
    assignUserToAgent,
  };
};

export default useChat;
