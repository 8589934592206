import { useForm } from 'react-hook-form';
import { TValidatedForm } from '../interfaces/TValidatedForm';
import { useYupValidationResolver } from './useYupValidationSchema';

export const useValidatedForm = <FormInput>(
  { schema, defaultValues }: TValidatedForm<FormInput>,
  shouldUseNativeValidation = false,
) => {
  const resolver = useYupValidationResolver(schema);

  const form = useForm<FormInput>({ resolver, defaultValues, shouldUseNativeValidation });
  return { ...form, validationErrors: form.formState.errors, registerInput: form.register };
};
