import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useWebclient } from '../../../../hooks/settings/webclients/useWebclient';
import { useWebclientActions } from '../../../../hooks/settings/webclients/useWebclientActions';
import { useTranslation } from 'react-i18next';

export const DeleteWebclientConfigModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { selectedWebclientConfig } = useWebclient();
  const { deleteWebclientConfig } = useWebclientActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {selectedWebclientConfig && (
        <Modal.Body>
          <p className={cvRow}>
            {t('settings.webclient.config.delete.confirmation')} <b>{selectedWebclientConfig?.name}</b> (
            {selectedWebclientConfig?.identifier}).
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteWebclientConfig(selectedWebclientConfig.identifier);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteWebclientConfigModal;
