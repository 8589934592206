import React from 'react';
import { TBlock } from '../../../interfaces/TDesigner';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { MaterialIcon } from '../../common/MaterialIcon';
import { NodeOptions } from './NodeOptions';

interface IProps {
  data: TBlock;
}

const getIconAndTooltipForBlockType = (blockType?: TBlock['type']) => {
  switch (blockType) {
    case 'text':
    case 'textWithButtons':
      return { icon: 'text_fields', description: 'Text Block' };
    case 'button':
      return { icon: 'smart_button', description: 'Button Block' };
    case 'Decider':
      return { icon: 'alt_route', description: 'Route Block' };
    case 'databaseChecker':
    case 'databaseDelete':
    case 'databaseRead':
    case 'databaseUpdate':
      return {
        icon: 'storage',
        description: 'Data Block',
      };
    case 'StandAloneImage': {
      return {
        icon: 'image',
        description: 'Image Block',
      };
    }
    default:
      return { icon: 'question_mark', description: 'Unknown Block' };
  }
};

export const NodeTitle: React.FC<IProps> = ({ data }) => {
  const { nodeTitle, nodeIcon } = convertKeysToCamelCase(style);
  const { icon } = getIconAndTooltipForBlockType(data?.type);

  return (
    <div className={nodeTitle}>
      {/* TODO: Figure out how to add a tooltip */}
      <MaterialIcon icon={icon} classes={[nodeIcon]} />
      <p>
        <strong>{data.id}</strong>
      </p>
      <NodeOptions data={data} />
    </div>
  );
};
