import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TFacebookSettings } from '../../../interfaces/TFacebook';
import { useModal } from '../../modal/useModal';
import { useFacebookPages } from './useFacebookPages';
import { useFacebookPagesActions } from './useFacebookPagesActions';

export const useFacebookSettingsForm = () => {
  const { updateSelectedPageSettings } = useFacebookPagesActions();
  const { facebookPageSettings } = useFacebookPages();

  const { handleSubmit, control, reset } = useForm<TFacebookSettings>({
    shouldUseNativeValidation: true,
    defaultValues: useMemo(() => facebookPageSettings, [facebookPageSettings]),
  });

  useEffect(() => {
    reset(facebookPageSettings);
  }, [facebookPageSettings, reset]);

  const { hideModal } = useModal();

  const hideAndSubmit = async (data: TFacebookSettings) => {
    updateSelectedPageSettings(data);
    hideModal();
  };

  const onSubmit = handleSubmit<TFacebookSettings>(hideAndSubmit);

  return {
    onSubmit,
    control,
  };
};
