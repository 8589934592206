import { GetWebclientConfigDto, WebclientConfig } from '@conversed/shared/src/central/dto/webclient-config.dto';
import { TWebclient } from '../interfaces/redux/TSettingsState';

export const defaultWebclientConfig: WebclientConfig = {
  options: {
    useAttachment: false,
    menuTemplate: [],
    messagePlaceholder: 'Type here',
    welcomeMessage: 'Hi there! I am here to chat with you. Ready to start? 😊',
    defaultState: 'greeting',
    headerType: 'normal',
    title: 'Conversed',
    subtitle: 'AI powered chatbot',
    refParam: 'normal',
    showCloseButton: false,
    greetingTimeout: 500,
    greeting: {
      buttonText: "Let's go!",
      messageIcon: '',
    },
    icons: {
      chatIcon: {
        open: 'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/opened.svg',
        closed: 'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/closed.svg',
      },
      logo: {
        type: 'circle',
        path: 'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/conversed_logo.png',
      },
      speechIcon:
        'https://s3.eu-central-1.amazonaws.com/webclient-connector-staging.conversed.biz/assets/conversed_logo.png',
    },
  },
  scheme: {
    header: {
      headerColor: '#3866A2',
      headerTitleColor: '#FFFFFF',
      headerSubtitleColor: '#FFFFFF',
      windowColor: '#FFFFFF',
      messageBarColor: '#F2F2F2',
    },
    message: {
      messageBubbleColor: '#3866A2',
      messageBubbleTextColor: '#FFFFFF',
      personalMessageBubbleColor: '#F7F7F7',
      personalMessageBubbleTextColor: '#3D3D3D',
      buttonEdgeColor: '#3866A2',
      buttonTextColor: '#3866A2',
      buttonHoverColor: '#90a9ca',
      carouselBackgroundColor: '#FFFFFF',
      carouselButtonColor: '#3866A2',
      carouselTextColor: '#000000',
      carouselButtonTextColor: '#FFFFFF',
      submitButtonColor: '#3866A2',
    },
    greeting: {
      titleColor: '#FFFFFF',
      subtitleColor: '#FFFFFF',
      buttonTextColor: '#FFFFFF',
      buttonColor: '#3866A2',
      backgroundColor: '#FFFFFF',
    },
  },
};

const getWebclientURL = (webclient: TWebclient) => {
  switch (webclient.environment) {
    case 'develop': {
      return `https://${webclient.uuid}-webclient-develop.conversed.biz/connect.js`;
    }
    case 'stage': {
      return `https://${webclient.uuid}-webclient-stage.conversed.biz/connect.js`;
    }
    default: {
      return `https://${webclient.uuid}-webclient.conversed.biz/connect.js`;
    }
  }
};

const createWebclientConfig = (
  webclient: TWebclient,
  selectedWebclientConfig?: TWebclient['configs'][0],
  config?: GetWebclientConfigDto,
) => `
<script 
src=${getWebclientURL(webclient)}
>
</script>
<script>
Conversed.init('${webclient.uuid}', ${JSON.stringify(config, null, 2)}, '${selectedWebclientConfig?.identifier}');
</script>
`;

export const createSimpleWebclientSnippet = (
  webclient: TWebclient,
  selectedWebclientConfig?: TWebclient['configs'][0],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
) => createWebclientConfig(webclient, selectedWebclientConfig, { botId: webclient.botId! });

export const createAdvancedWebclientSnippet = (
  webclient: TWebclient,
  selectedWebclientConfig?: TWebclient['configs'][0],
) =>
  createWebclientConfig(webclient, selectedWebclientConfig, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    botId: webclient.botId!,
    ...selectedWebclientConfig?.config,
  });
