import React from 'react';
import { ChatPanel } from '../../../chat/chat-panel/ChatPanel';
import { ChatSidebar } from '../../../chat/chat-sidebar/ChatSidebar';
import { useMainSelector } from '../../../../hooks/useMainSelector';
import { ChatPanelSidebar } from '../../../chat/chat-panel/ChatPanelSidebar';
import { ChatPanelHeader } from '../../../chat/chat-panel-header/ChatPanelHeader';
import chatAvatar from '../../../../assets/images/chat_avatar.png';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useChatActions } from '../../../../hooks/useChatActions';
import { useMainDispatch } from '../../../../hooks/useMainDispatch';
import { Actions } from '../../../../constants/actionTypes';
import { useChatProfileSidebar } from '../../../../hooks/chat/useChatProfileSidebar';
import { useFileMedia } from '../../../../hooks/useFileMedia';

export const ChatPage: React.FC = () => {
  const { chatProfileSidebarOpen, toggleChatProfileSidebar } = useChatProfileSidebar();
  const {
    chat: { selectedConversation },
  } = useMainSelector();

  const { getConversationById } = useChatActions();

  const { getImageByURL } = useFileMedia();
  const { id: conversationId } = useParams<{ id?: string }>();

  const dispatch = useMainDispatch();

  useEffect(() => {
    if (conversationId) {
      getConversationById(conversationId);
    } else {
      dispatch({ type: Actions.SET_CHAT, payload: { selectedConversation: undefined } });
    }
  }, [conversationId, getConversationById, dispatch]);

  return (
    <div className="d-flex flex-row flex-grow-1">
      <ChatSidebar />
      <div className="d-flex flex-column flex-grow-1">
        {selectedConversation && (
          <ChatPanelHeader
            name={selectedConversation.name}
            imageUrl={getImageByURL(selectedConversation.imageUrl ?? chatAvatar)}
            id={selectedConversation.id}
          />
        )}

        <div className="d-flex flex-row flex-grow-1">
          <ChatPanel sidebarState={chatProfileSidebarOpen} toggleSidebar={toggleChatProfileSidebar} />
          {selectedConversation && chatProfileSidebarOpen && (
            <ChatPanelSidebar selectedConversation={selectedConversation} />
          )}
        </div>
      </div>
    </div>
  );
};
