import { useCallback } from 'react';
import { putBlocksApi, getBlocksApi, getBlockEntryPointsApi } from '../../../api/flow.api';
import { Actions } from '../../../constants/actionTypes';
import { toastErrorTranslated } from '../../../helpers/toast';
import { useMainDispatch } from '../../useMainDispatch';
import { useBlocksUpdates } from './useBlocksUpdates';

export const useBlocksActions = () => {
  const dispatch = useMainDispatch();
  const { blockUpdates } = useBlocksUpdates();

  const fetchFlowEntryPoints = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: blocks } = await getBlockEntryPointsApi();

      dispatch({ type: Actions.SET_DESIGNER, payload: { entryPoints: blocks } });
      return 'success';
    } catch (error) {
      console.error(error);
      return 'error';
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const getBlocks = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: blocks } = await getBlocksApi();

      dispatch({ type: Actions.SET_DESIGNER, payload: { blocks } });
      return 'success';
    } catch (error) {
      console.error(error);
      return 'error';
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const saveFlow = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      await putBlocksApi(blockUpdates);
      dispatch({ type: Actions.BLOCK_UPDATES_CLEAR, payload: undefined });
    } catch (error) {
      toastErrorTranslated(error);
      console.error(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch, blockUpdates]);

  return { getBlocks, saveFlow, fetchFlowEntryPoints };
};
