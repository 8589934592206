const {
  REACT_APP_API_ENDPOINT,
  NODE_ENV,
  REACT_APP_FB_GRAPH_VERSION,
  REACT_APP_FB_LOGIN_REDIRECT_ENDPOINT,
  REACT_APP_AWS_API_ENDPOINT,
  REACT_APP_AZURE_API_ENDPOINT,
} = process.env;

export const API_ENDPOINT = REACT_APP_API_ENDPOINT || '';
export const IS_DEVELOPMENT_ENVIRONMENT = NODE_ENV === 'development';
export const WS_ENDPOINT = REACT_APP_API_ENDPOINT || '';
export const FB_GRAPH_VERSION = REACT_APP_FB_GRAPH_VERSION || 'v10.0';
export const FB_INTEGRATION_CREATE_ENDPOINT = REACT_APP_FB_LOGIN_REDIRECT_ENDPOINT || '/integrations/facebook';
export const AWS_API_ENDPOINT = REACT_APP_AWS_API_ENDPOINT || '';
export const AZURE_API_ENDPOINT = REACT_APP_AZURE_API_ENDPOINT || '';
