import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useFacebookPages } from '../../../../hooks/settings/facebook/useFacebookPages';
import { useFacebookPagesActions } from '../../../../hooks/settings/facebook/useFacebookPagesActions';
import { useUiState } from '../../../../hooks/useUIState';
import FacebookErrorModal from './FacebookErrorModal';
import FacebookPersistentMenuList from './persistent-menu/FacebookPersistentMenuList';
import { useFacebookSettingsForm } from '../../../../hooks/settings/facebook/useFacebookSettingsForm';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';

export const FacebookSettingsModal: React.FC = () => {
  const { facebookPageSettings } = useFacebookPages();
  const { getSelectedFacebookPageSettings } = useFacebookPagesActions();
  const { onSubmit, control } = useFacebookSettingsForm();
  const { t } = useTranslation();

  const { isLoading } = useUiState();

  if (isLoading) {
    return null;
  }

  const { facebookModalBody, modalSection, sectionTitle, cvFooter } = convertKeysToCamelCase(style);

  return facebookPageSettings ? (
    <Modal.Body className={facebookModalBody}>
      <Form onSubmit={onSubmit} className={`${modalSection}`}>
        <h5 className={`${sectionTitle}`}>{t('settings.facebook.persistent-menu.title')}</h5>
        <FacebookPersistentMenuList control={control} />
      </Form>
      <Modal.Footer className={`${cvFooter}`}>
        <Button type="submit">{t('general.buttons.save')}</Button>
      </Modal.Footer>
    </Modal.Body>
  ) : (
    <FacebookErrorModal retry={() => getSelectedFacebookPageSettings()} />
  );
};

export default FacebookSettingsModal;
