/* eslint-disable i18next/no-literal-string */
import React from 'react';
import style from '../../../assets/scss/components/chat/chat-panel-header/ChatPanelHeader.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import InboxDropDown from './InboxDropDown';
import MoreDropDown from './MoreDropDown';
// import MoreVertDropDown from './MoreVertDropDown';
import { CHAT_PAGE_ROUTE } from '../../../constants/routes';
import { useSidebar } from '../../../hooks/useSidebar';
import { Link, useLocation } from 'react-router-dom';
import { EBreakpoint } from '../../../constants/breakpoints';
import { useMedia } from '../../../hooks/useMedia';
import { useChatStatus } from '../../../hooks/chat/useChatStatus';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../../common/MaterialIcon';
import { Container, Nav, Navbar } from 'react-bootstrap';

interface Props {
  name: string;
  imageUrl: string;
  id: string;
}

export const ChatPanelHeader: React.FC<Props> = ({ name, imageUrl, id }) => {
  const { sideBarOpen } = useSidebar();
  const { down: isMobile } = useMedia(EBreakpoint.MD);
  const { search } = useLocation();
  const { t } = useTranslation();

  const { chatStatus, setChatToResolved, setChatToOpen } = useChatStatus();

  const {
    panelHeader,
    client,
    userAvatar,
    wrapper,
    segmentedControl,
    buttonResolve,
    arrowBack,
    buttonDefault,
    appBarOpen,
    panelNavbar,
    cvContainer,
    navWrapper,
    cvNavbarToggle,
    cvNavCollapse,
    nameUser,
  } = convertKeysToCamelCase(style);

  return (
    <article className={`${sideBarOpen ? appBarOpen : ''} `}>
      <div className={`d-flex justify-content-between ${panelHeader} `}>
        <div className={client}>
          <Link to={{ pathname: `${CHAT_PAGE_ROUTE}`, search }}>
            <MaterialIcon icon={'arrow_back_ios'} classes={[arrowBack]} role={'button'} />
          </Link>
          <img className={`circle ${userAvatar}`} src={imageUrl} />
          {isMobile ? (
            <Link to={{ pathname: `${CHAT_PAGE_ROUTE}/${id}/info`, search }} className={nameUser}>
              <p>{name}</p>
            </Link>
          ) : (
            <p>{name}</p>
          )}
        </div>

        <div className={wrapper}>
          <Navbar expand="lg" className={panelNavbar}>
            <Container className={cvContainer}>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className={cvNavbarToggle} />
              <Navbar.Collapse id="basic-navbar-nav" className={cvNavCollapse}>
                <Nav className={navWrapper}>
                  {chatStatus === 'open' && <InboxDropDown />}
                  <div className={segmentedControl}>
                    {chatStatus === 'open' ? (
                      <div
                        className={`btn ${buttonDefault} ${buttonResolve}`}
                        onClick={() => {
                          setChatToResolved();
                        }}
                      >
                        <MaterialIcon icon={'check'} />
                        <p>{t('chat.panel.header.buttons.resolve')}</p>
                      </div>
                    ) : (
                      <div
                        className={`btn ${buttonDefault} ${buttonResolve}`}
                        onClick={() => {
                          setChatToOpen();
                        }}
                      >
                        <p>{t('chat.panel.header.buttons.open')}</p>
                      </div>
                    )}
                  </div>
                  <MoreDropDown />
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </article>
  );
};
