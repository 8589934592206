import style from '../../assets/scss/components/designer/nodes/Sidebar.module.scss';
import { DragEvent } from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { TNodeType } from '../../interfaces/TDesigner';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../common/MaterialIcon';

export const DesignerSidebar: React.FC = () => {
  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: TNodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const { t } = useTranslation();

  const { node, nodeContainer } = convertKeysToCamelCase(style);

  return (
    <aside className={nodeContainer}>
      <p>{t('flow.blocks.sidebar.desc')}</p>
      <div className={node} onDragStart={(event) => onDragStart(event, 'textNode')} draggable>
        <MaterialIcon icon={'sticky_note_2'} />
        <p>{t('flow.blocks.sidebar.text')}</p>
      </div>
      <div className={node} onDragStart={(event) => onDragStart(event, 'buttonNode')} draggable>
        <MaterialIcon icon={'smart_button'} />
        <p>{t('flow.blocks.sidebar.button')}</p>
      </div>
      <div className={node} onDragStart={(event) => onDragStart(event, 'imageNode')} draggable>
        <MaterialIcon icon={'add_photo_alternate'} />
        <p>{t('flow.blocks.sidebar.image')}</p>
      </div>
      {/* <div className={node} onDragStart={(event) => onDragStart(event, 'dataNode')} draggable>
        <p>
          <strong>Data Block</strong>
        </p>
      </div>
      <div className={node} onDragStart={(event) => onDragStart(event, 'routeNode')} draggable>
        <p>
          <strong>Route Block</strong>
        </p>
      </div> */}
    </aside>
  );
};
