import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import ContentTitle from './common/ContentTitle';
import { useTranslation } from 'react-i18next';
import { useCompanyMembersInit } from '../../hooks/settings/company/useCompanyMembersInit';
import CompanyMembersTable from './member-settings/CompanyMembersTable';
import NewMemberForm from './member-settings/NewMemberForm';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { useAssignableRolesInit } from '../../hooks/settings/roles/useAssignableRolesInit';

export const MemberSettings: React.FC = () => {
  useCompanyMembersInit();
  useAssignableRolesInit();

  const { hasPermissions } = usePermissions();
  const { t } = useTranslation();

  const { settingsForm } = convertKeysToCamelCase(style);

  return (
    <div className={settingsForm}>
      <ContentTitle title={t('members-settings.title')} />
      {hasPermissions([PERMISSIONS.COMPANY.COMPANY_CLIENT_MEMBERS_EDIT]) && <NewMemberForm />}
      <CompanyMembersTable />
    </div>
  );
};

export default MemberSettings;
