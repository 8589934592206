import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import { Table } from 'react-bootstrap';
import { useRole } from '../../../hooks/settings/roles/useRole';
import { useTranslation } from 'react-i18next';
import RolePermissionColumn from './RolePermissionColumn';
import PermissionsColumn from './PermissionsColumn';

const titleByRoleId = {
  agent: 'roles-settings.role-agent',
  companyAdmin: 'roles-settings.role-admin',
  conversedAdmin: 'roles-settings.role-conversed-admin',
  superAdmin: 'roles-settings.role-super-admin',
} as const;

export const RolesTable: React.FC = () => {
  const { roles, permissions } = useRole();

  const { t } = useTranslation();
  const { sectionCategory, table } = convertKeysToCamelCase(style);

  return (
    <div className={`${sectionCategory} ${table}`}>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <p>{t('roles-settings.permissions-title')}</p>
            </th>
            {roles.map((role) => (
              <th key={role.id}>
                <p>{t(titleByRoleId[role.id])}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission) => (
            <tr key={permission}>
              <PermissionsColumn permission={permission} />
              {roles.map((role) => (
                <RolePermissionColumn key={role.id} role={role} permission={permission} />
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RolesTable;
