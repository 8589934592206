import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import {
  TFacebookSettings,
  GetFacebookPagesResponseDTO,
  GetFacebookStateTokenResponseDTO,
} from '@conversed/shared/src/central/dto/facebook.dto';

export const getFacebookPagesApi = (): TGeneralResponse<GetFacebookPagesResponseDTO> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.FACEBOOK}/pages`);

export const getFacebookStateTokenApi = (): TGeneralResponse<GetFacebookStateTokenResponseDTO> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.FACEBOOK}/state`);

export const getFacebookPageSettingsApi = (id: string): TGeneralResponse<TFacebookSettings> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.FACEBOOK}/pages/${id}/settings`);

export const updateFacebookPageSettingsApi = (
  id: string,
  data: TFacebookSettings,
): TGeneralResponse<TFacebookSettings> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.FACEBOOK}/pages/${id}/settings`, data);
