import React from 'react';
import { Button, Dropdown, Form, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from '../../../assets/scss/components/common/DropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useSelectAssignedAgent } from '../../../hooks/chat/useSelectAssignedAgent';
import MaterialIcon from '../../common/MaterialIcon';

export const AssigneeSelector: React.FC = () => {
  const { cv, dropdown, toggler, item, menu, custom, cvDropdownSearchWrapper, searchSubmit, cvDropdownSearch } =
    convertKeysToCamelCase(style);
  const { agents, assignedAgent, setAssignedAgent, searchValue, setSearchValue } = useSelectAssignedAgent();
  const { t } = useTranslation();

  return (
    <div>
      <p
        style={{
          fontSize: 13,
        }}
      >
        {t('chat.panel.sidebar.assigned-agent')}
      </p>
      <Dropdown className={`${cv} ${dropdown} ${custom}`}>
        <Dropdown.Toggle className={`${cv} ${toggler} ${custom}`}>{assignedAgent}</Dropdown.Toggle>
        <Dropdown.Menu className={`${cv} ${menu} ${custom}`}>
          <div className={cvDropdownSearch}>
            <div className={cvDropdownSearchWrapper}>
              <Form className="d-flex">
                <FormControl
                  type="search"
                  placeholder={t('chat.sidebar.header.search')}
                  className="mr-2"
                  aria-label="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <Button className={searchSubmit} variant="outline-success">
                  <MaterialIcon icon={'search'} />
                </Button>
              </Form>
            </div>
          </div>
          {agents.map((agent) => {
            return (
              <Dropdown.Item
                className={`${cv} ${item} ${custom}`}
                key={agent.id}
                onClick={() => setAssignedAgent(agent.id)}
              >
                {agent.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
