import { Handle } from 'react-flow-renderer';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { getHandlePositionByFlowDirection } from '../../../helpers/flowHelper';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { Decider } from '@conversed/shared/src/central/types/blocks/Decider';
import { splitIntoLines } from '../../../helpers/domHelper';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { NodeTitle } from './NodeTitle';

interface IProps {
  data: Decider;
}

const getContent = (block: Decider) => {
  if (!block?.type || !block.params.possibleDestinations) {
    return '';
  }

  return block.params.possibleDestinations.reduce((acc, val) => {
    return `${acc}\n${val.blockId}`;
  }, '');
};

export const RouteNode: React.FC<IProps> = ({ data }) => {
  const { node, nodeSeparator, nodeBody } = convertKeysToCamelCase(style);

  const { flowDirection } = useFlow();
  const { start, end } = getHandlePositionByFlowDirection(flowDirection);
  const body = getContent(data);

  return (
    <div className={node}>
      <Handle type="target" position={start} id="start" />
      <NodeTitle data={data} />
      <hr className={nodeSeparator} />
      <div className={nodeBody}>{splitIntoLines(body)}</div>
      <Handle type="source" position={end} id="end" />
    </div>
  );
};
