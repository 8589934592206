import React from 'react';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { useModal } from '../../../../hooks/modal/useModal';
import { useMfaDisableForm } from '../../../../hooks/settings/account/useMFADisableForm';

export const Disable2FaModal: React.FC = () => {
  const { register, handleSubmit } = useMfaDisableForm();

  const { hideModal } = useModal();
  const { t } = useTranslation();

  const { cvRow, cvFooter, multipleButtons, input } = convertKeysToCamelCase(style);
  return (
    <Modal.Body>
      <p className={cvRow}>{t('account-settings.2fa.modals.disable.confirmation')}</p>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group className={cvRow}>
            <p>{t('account-settings.2fa.modals.disbale.password')}</p>
            <Form.Control
              className={input}
              required
              {...register('password')}
              type="password"
              autoComplete="current-password"
            />
          </Form.Group>
          <Form.Group className={cvRow}>
            <p>{t('account-settings.2fa.modals.enable.token')}</p>
            <Form.Control className={input} required {...register('token')} type="text" />
          </Form.Group>
        </Row>
        <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
          <Button type="submit">{t('account-settings.2fa.modals.disable.disable')}</Button>
          <Button
            onClick={() => {
              hideModal();
            }}
          >
            {t('general.buttons.abort')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal.Body>
  );
};

export default Disable2FaModal;
