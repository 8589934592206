import React from 'react';

type TProps = {
  text: string;
  classNames: string[];
  type?: 'button' | 'submit' | 'reset';
};

export const CommonButton: React.FC<TProps> = ({ text, classNames, type }) => {
  return (
    <button className={classNames.join(' ')} type={type}>
      {text}
    </button>
  );
};
