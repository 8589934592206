import { useCallback } from 'react';
import { getAssigneeListForConversation } from '../../api/chatApi';
import { Actions } from '../../constants/actionTypes';
import { toastErrorTranslated } from '../../helpers/toast';
import { useMainDispatch } from '../useMainDispatch';

export const useAgentAssigneeActions = () => {
  const dispatch = useMainDispatch();

  const fetchAgentAssignees = useCallback(
    async (conversationId: string) => {
      try {
        const { data: assigneeList } = await getAssigneeListForConversation(conversationId);

        dispatch({
          type: Actions.SET_CONVERSATION_ASSIGNEE_LIST,
          payload: assigneeList,
        });
      } catch (error) {
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  return { fetchAgentAssignees };
};
