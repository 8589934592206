import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const WebclientConfigInfoModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Modal.Body>
      <Container>
        <p>{t('settings.webclient.config.info.title')}</p>
        <br></br>
        <p>{t('settings.webclient.config.info.body')}</p>
        <br></br>
        <p>{t('settings.webclient.config.info.use-case-desc')}</p>
        <ul className="mx-5">
          <li>{t('settings.webclient.config.info.use-case-1')}</li>
          <li>{t('settings.webclient.config.info.use-case-2')}</li>
          <li>{t('settings.webclient.config.info.use-case-3')}</li>
        </ul>
      </Container>
    </Modal.Body>
  );
};

export default WebclientConfigInfoModal;
