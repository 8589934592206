import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useFlowControls } from '../../../hooks/flow/useFlowControls';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { useTranslation } from 'react-i18next';

export const CommitButton: React.FC = () => {
  const { commitBlockChanges } = useFlowControls();
  const { t } = useTranslation();
  const { button } = convertKeysToCamelCase(style);

  return (
    <GeneralTooltip id={'tooltip-flow-reset-button'} text={t('flow.blocks.commit.button')}>
      <div className={button} onClick={() => commitBlockChanges()}>
        <MaterialIcon icon={'done'} />
      </div>
    </GeneralTooltip>
  );
};
