import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { splitIntoLines } from '../../../helpers/domHelper';

export const AccountInfoModal: React.FC = () => {
  const { t } = useTranslation();
  const text = t('account.info');
  return <Modal.Body>{splitIntoLines(text)} </Modal.Body>;
};

export default AccountInfoModal;
