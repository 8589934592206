import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { usePermissions } from '../../hooks/usePermissions';
import { useLinksInit } from '../../hooks/inboxes/useLinksInit';
import { useLinks } from '../../hooks/inboxes/useLinks';
import { toastInfoTranslated } from '../../helpers/toast';
import { useLinkActions } from '../../hooks/inboxes/useLinkActions';

export const LinkSettings: React.FC = () => {
  useLinksInit();
  const { links, setSelectedLink } = useLinks();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();
  const { fetchLinkStatistics } = useLinkActions();

  return (
    <>
      <ContentTitle title={t('settings.sidebar.tools.links')} onClickInfo={() => showModal('link-info')} />
      {links?.length ? (
        <>
          {hasPermissions([PERMISSIONS.LINK.MANAGE_LINKS]) && (
            <AddButton text={t('link-settings.add-link')} onClickAdd={() => showModal('add-link')} />
          )}
          <SettingsCardContainer>
            {links.map((link) => {
              const removeAction = hasPermissions([PERMISSIONS.LINK.MANAGE_LINKS])
                ? [
                    {
                      icon: 'delete',
                      action: () => {
                        showModal('delete-link');
                        setSelectedLink(link.shortLink);
                      },
                    },
                  ]
                : [];
              const editAction = hasPermissions([PERMISSIONS.LINK.MANAGE_LINKS])
                ? [
                    {
                      icon: 'border_color',
                      action: () => {
                        showModal('edit-link');
                        setSelectedLink(link.shortLink);
                      },
                    },
                  ]
                : [];
              const copyAction = {
                icon: 'content_copy',
                action: () => {
                  navigator.clipboard.writeText(link.shortLinkFullVersion);
                  toastInfoTranslated('general.toast.copied-to-clipboard');
                },
              };

              const statisticsOption = {
                icon: 'trending_up',
                action: () => {
                  showModal('link-statistics');
                  fetchLinkStatistics(link.shortLink);
                  setSelectedLink(link.shortLink);
                },
              };

              return (
                <SettingsCard
                  key={link.shortLink}
                  title={`/${link.shortLink}`}
                  subtitle={link.originalLink.substring(0, 30) + (link.originalLink.length > 30 ? '...' : '')}
                  actions={[statisticsOption, copyAction, ...editAction, ...removeAction]}
                />
              );
            })}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState message={t('link-settings.empty-state')} addButton={() => showModal('add-link')} />
      )}
    </>
  );
};
export default LinkSettings;
