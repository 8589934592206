import React from 'react';
import { Route } from 'react-router-dom';

import { IRestrictedRoute } from '../../interfaces/IRoutes';
import { usePermissions } from '../../hooks/usePermissions';
import { ForbiddenPage } from '../pages/ForbiddenPage';

export const RestrictedRoute: React.FC<IRestrictedRoute> = ({ component: Component, requiredPermissions, ...rest }) => {
  const { hasPermissions } = usePermissions();

  return (
    <Route
      {...rest}
      render={(childProps) => (hasPermissions(requiredPermissions) ? <Component {...childProps} /> : <ForbiddenPage />)}
    />
  );
};
