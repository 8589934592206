import uuid from 'short-uuid';
import { Actions } from '../../constants/actionTypes';
import { useCallback, useMemo } from 'react';
import { useMainSelector } from '../useMainSelector';
import { useMainDispatch } from '../useMainDispatch';
import { useChat } from '../useChat';
import { TChatHistory } from '../../interfaces/IMessage';
import { useChatMode } from './useChatMode';

export const useChatInput = () => {
  const { sendMessage, sendNote } = useChat();
  const {
    chat: { chatInputMessage, selectedConversation },
    user,
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const { chatMode, setChatMode } = useChatMode();

  const setMessage = useCallback(
    (message: string) => {
      dispatch({ type: Actions.SET_CHAT, payload: { chatInputMessage: message } });
    },
    [dispatch],
  );

  const sendInputMessage = useCallback(() => {
    if (chatInputMessage) {
      const messageObject: TChatHistory = {
        direction: 'out',
        id: uuid.generate().toString(),
        message: {
          text: chatInputMessage,
        },
        time: new Date().toISOString(),
        type: chatMode === 'reply' ? 'text' : 'note', // set it as either text or note
      };

      dispatch({
        type: Actions.SET_CHAT_MESSAGES,
        payload: [...(selectedConversation?.messages || []), messageObject],
      });
      chatMode === 'reply' ? sendMessage(chatInputMessage) : sendNote(chatInputMessage);
      setMessage('');
    }
  }, [chatInputMessage, chatMode, dispatch, selectedConversation?.messages, sendMessage, sendNote, setMessage]);

  const messagingEnabled = useMemo(
    () => selectedConversation?.assignedAgent?.id === user.currentUser?.id,
    [selectedConversation?.assignedAgent?.id, user.currentUser?.id],
  );

  return {
    chatMode,
    message: chatInputMessage,
    setMessage,
    sendMessage: sendInputMessage,
    messagingEnabled,
    setChatMode,
  };
};
