import React from 'react';
import style from '../../assets/scss/components/pages/MainPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import TermsAndConditions from '../settings-page/TermsAndConditions';

export const TermsAndConditionsPage: React.FC = () => {
  const { wrapper, contentWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <div className={contentWrapper}>
        <TermsAndConditions />
      </div>
    </div>
  );
};
