import { useCallback, useEffect, useState } from 'react';
import { toastErrorTranslated } from '../../helpers/toast';
import { useLogin } from '../auth/useLogin';
import { useCurrentUserActions } from '../auth/useCurrentUserActions';

export const useCurrentUserInit = () => {
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');
  const { logout } = useLogin();
  const { fetchCurrentUser } = useCurrentUserActions();

  const getLoggedInUser = useCallback(async () => {
    try {
      await fetchCurrentUser();
      setStatus('success');
    } catch (error) {
      logout();
      setStatus('error');
      toastErrorTranslated(error);
    }
  }, [fetchCurrentUser, logout]);

  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  return { loading: status === 'loading', error: status === 'error' };
};
