import { Handle } from 'react-flow-renderer';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { getHandlePositionByFlowDirection } from '../../../helpers/flowHelper';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { TextWithButtons } from '@conversed/shared/src/central/types/blocks/TextWithButtons';
import { splitIntoLines } from '../../../helpers/domHelper';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { NodeTitle } from './NodeTitle';

interface IProps {
  data: TextWithButtons;
}

export const TextNode: React.FC<IProps> = ({ data }) => {
  const { textToSend } = data;

  const { node, nodeSeparator, nodeBody } = convertKeysToCamelCase(style);

  const firstLanguage = Object.keys(textToSend)[0];
  const { flowDirection } = useFlow();
  const { start, end } = getHandlePositionByFlowDirection(flowDirection);

  return (
    <div className={node}>
      <Handle type="target" position={start} id="start" />
      <NodeTitle data={data} />
      <hr className={nodeSeparator} />
      <div className={nodeBody}>{splitIntoLines(textToSend[firstLanguage])}</div>
      <Handle type="source" position={end} id="end" />
    </div>
  );
};
