import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useTranslation } from 'react-i18next';
import { useLinks } from '../../../../hooks/inboxes/useLinks';
import { useLinkActions } from '../../../../hooks/inboxes/useLinkActions';

export const DeleteLinkModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { selectedLink } = useLinks();
  const { deleteLink } = useLinkActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {selectedLink && (
        <Modal.Body>
          <p className={cvRow}>
            {t('link-settings.delete-link.prompt')} <b>{selectedLink?.shortLink}</b>?
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteLink(selectedLink.shortLink);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteLinkModal;
