import { useEffect } from 'react';
import { IS_DEVELOPMENT_ENVIRONMENT } from '../constants/env';

export const useAlertUserBeforeLeave = () => {
  // * Prompt user before leaving page
  useEffect(() => {
    if (IS_DEVELOPMENT_ENVIRONMENT) {
      // * Do not enable on dev
      return;
    }

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  const alertUser = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };
};
