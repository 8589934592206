import { TextWithButtons } from '@conversed/shared/src/central/types/blocks/TextWithButtons';
import { useCallback } from 'react';
import { Actions } from '../../../constants/actionTypes';
import { ENTRY_BLOCK_ID } from '@conversed/shared/src/central/constants/blocks';
import { pushUnique, removeValueFromList } from '@conversed/shared/src/helpers/data.helpers';
import {
  TBlockParamsWithPosition,
  TBlocksConfigWithPositions,
  TBlockWithPosition,
} from '../../../interfaces/TDesigner';
import { useMainDispatch } from '../../useMainDispatch';
import { useBlocks } from '../block/useBlocks';
import { useBlocksUpdates } from '../block/useBlocksUpdates';
import { TBlockWithPositionWithoutEntryBlock } from '@conversed/shared/src/central/types/block.type';

export const useFlowActions = () => {
  const { blocks } = useBlocks();
  const { updateBlocks, deleteBlocks } = useBlocksUpdates();
  const dispatch = useMainDispatch();

  const setSelectedBlock = (selectedBlock: TBlockWithPosition) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { selectedBlock } });
  };

  const setMatchIndex = (index: number) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { blocksSearchMatchIndex: index } });
  };

  const setSearchString = (input: string) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { blocksSearchString: input } });
  };

  const setBlockParamsForCreation = (params: TBlockParamsWithPosition) => {
    dispatch({ type: Actions.SET_DESIGNER, payload: { newBlock: params } });
  };

  const updateBlockPosition = useCallback(
    (block: TBlockWithPosition) => {
      updateBlocks([block]);
    },
    [updateBlocks],
  );

  const connectBlocks = useCallback(
    (sourceId?: string | null, targetId?: string | null): TBlocksConfigWithPositions | undefined => {
      const sourceBlock = blocks?.find(({ id }) => id === sourceId);
      const targetBlock = blocks?.find(({ id }) => id === targetId);

      if (!sourceBlock || !targetBlock) {
        return;
      }

      const updateOperations: TBlocksConfigWithPositions = [];

      // * Special case, target of entry node always becomes the entry block
      if (sourceBlock.id === ENTRY_BLOCK_ID) {
        blocks
          // * Filter out blocks that cannot have isEntryBlock set
          ?.filter(
            (block): block is TBlockWithPositionWithoutEntryBlock =>
              block.type !== 'version' && block.type !== 'button' && block.type !== 'entry',
          )
          ?.forEach((block) => {
            // * We have to remove the isEntryBlock from any block that has it set
            // * Also set isEntryBlock to true for target block
            if (('isEntryBlock' in block && block.isEntryBlock) || targetId === block.id) {
              return updateOperations.push({
                ...block,
                isEntryBlock: targetId === block.id,
              });
            }
          });
      }

      blocks?.forEach((block) => {
        // * Leave block unchanged if it is not target or source
        if (![sourceBlock.id, targetBlock.id].includes(block.id)) {
          return;
        }

        if (
          sourceBlock.id === block.id &&
          targetBlock.type === 'button' &&
          (sourceBlock.type === 'text' || sourceBlock.type === 'textWithButtons')
        ) {
          return updateOperations.push({
            ...block,
            type: 'textWithButtons' as const,
            contains: pushUnique(targetBlock.id, sourceBlock.type === 'textWithButtons' ? sourceBlock?.contains : []),
          } as TextWithButtons);
        }

        if (sourceBlock.id === block.id && 'destination' in block) {
          return updateOperations.push({ ...block, destination: [targetBlock.id] });
        }
      });

      updateBlocks(updateOperations);
    },
    [blocks, updateBlocks],
  );

  // * After block deletion we need to delete said block reference from all other connected blocks
  const disconnectBlock = useCallback(
    (targetId?: string | null) => {
      if (!targetId) {
        return;
      }

      const updateOperations: TBlocksConfigWithPositions = [];

      blocks?.forEach((block) => {
        if ('destination' in block && block.destination?.includes(targetId)) {
          return updateOperations.push({
            ...block,
            destination: removeValueFromList((blockId) => blockId === targetId, block.destination),
          });
        }

        if ('contains' in block && block.contains?.includes(targetId)) {
          return updateOperations.push({
            ...block,
            contains: removeValueFromList((blockId) => blockId === targetId, block.contains),
          });
        }
      });

      updateBlocks(updateOperations);
      deleteBlocks([targetId]);
    },
    [blocks, updateBlocks, deleteBlocks],
  );

  return {
    setSelectedBlock,
    setBlockParamsForCreation,
    setMatchIndex,
    setSearchString,
    connectBlocksByIds: connectBlocks,
    deleteBlockFromFlow: disconnectBlock,
    updateBlockPosition,
  };
};
