import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { toastErrorTranslated } from '../../../helpers/toast';

export const useHandleFacebookConnectionError = () => {
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);

    const error = params.get('error');
    const code = params.get('code');
    const missingScopes = params.get('missingScopes');

    if (error || code || missingScopes) {
      console.error({ error, code, missingScopes });
    }

    if (error) {
      toastErrorTranslated(
        (
          FACEBOOK_ERRORS[(code as keyof typeof FACEBOOK_ERRORS) || 'unexpectedError'] ||
          FACEBOOK_ERRORS['unexpectedError']
        ).description,
      );
    }
  }, [search]);
};

export const FACEBOOK_ERRORS = {
  onePageShouldBeSelected: {
    description:
      'Only one page should be selected to connect to the app, please retry and select the page you would like to connect',
  },
  missingScopes: {
    description: 'One or more of the permissions that the app needs was not granted by the user',
  },
  subscriptionFailed: {
    description:
      'An error occurred while trying to subscribe the page please try logging in again or contact the administrator',
  },
  missingUserTasks: {
    description: 'The connected user must be an admin of the page and have either the MODERATE or MANAGE tasks',
  },
  invalidStateToken: {
    description: 'An unexpected error occurred, please retry or contact the administrator',
  },
  noPermission: {
    description: 'An unexpected error occurred, please retry or contact the administrator',
  },
  unexpectedError: {
    description: 'An unexpected error occurred, please retry or contact the administrator',
  },
  200: {
    description: 'Permission error, if you canceled the request you can retry it by pressing the button below',
  },
};
