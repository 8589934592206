const GLOBAL_PERMISSIONS = {
  ACCESS_ALL_COMPANIES: 'ACCESS_ALL_COMPANIES',
};

const COMPANY_PERMISSIONS = {
  COMPANY_CREATE: 'COMPANY_CREATE',
  COMPANY_ASSIGN: 'COMPANY_ASSIGN',
  COMPANY_CONNECTION_SETTINGS_READ: 'COMPANY_CONNECTION_SETTINGS_READ',
  COMPANY_CONNECTION_SETTINGS_EDIT: 'COMPANY_CONNECTION_SETTINGS_EDIT',
  COMPANY_GENERAL_SETTINGS_READ: 'COMPANY_GENERAL_SETTINGS_READ',
  COMPANY_GENERAL_SETTINGS_EDIT: 'COMPANY_GENERAL_SETTINGS_EDIT',
  COMPANY_MEMBERS_MANAGE: 'COMPANY_MEMBERS_MANAGE',
  COMPANY_GLOBAL_MEMBERS_READ: 'COMPANY_GLOBAL_MEMBERS_READ',
  COMPANY_GLOBAL_MEMBERS_EDIT: 'COMPANY_GLOBAL_MEMBERS_EDIT',
  COMPANY_INTERNAL_MEMBERS_READ: 'COMPANY_INTERNAL_MEMBERS_READ',
  COMPANY_INTERNAL_MEMBERS_EDIT: 'COMPANY_INTERNAL_MEMBERS_EDIT',
  COMPANY_CLIENT_MEMBERS_READ: 'COMPANY_CLIENT_MEMBERS_READ',
  COMPANY_CLIENT_MEMBERS_EDIT: 'COMPANY_CLIENT_MEMBERS_EDIT',
};

const CONVERSATION_PERMISSIONS = {
  CONVERSATION_MANAGE: 'CONVERSATION_MANAGE',
};

const FLOW_PERMISSIONS = {
  FLOW_LIST: 'FLOW_LIST',
  FLOW_EDIT: 'FLOW_EDIT',
};

const AGENT_PERMISSIONS = {
  AGENT_LIST: 'AGENT_LIST',
  AGENT_CREATE: 'AGENT_CREATE',
  AGENT_EDIT: 'AGENT_EDIT',
  AGENT_REMOVE: 'AGENT_REMOVE',
};

const INBOX_PERMISSIONS = {
  INBOX_LIST: 'INBOX_LIST',
  INBOX_CREATE: 'INBOX_CREATE',
  INBOX_EDIT: 'INBOX_EDIT',
  INBOX_REMOVE: 'INBOX_REMOVE',
};

const LABEL_PERMISSIONS = {
  LABEL_LIST: 'LABEL_LIST',
  LABEL_CREATE: 'LABEL_CREATE',
  LABEL_EDIT: 'LABEL_EDIT',
  LABEL_REMOVE: 'LABEL_REMOVE',
};

const CANNED_RESPONSE_PERMISSIONS = {
  CANNED_RESPONSE_LIST: 'CANNED_RESPONSE_LIST',
  CANNED_RESPONSE_CREATE: 'CANNED_RESPONSE_CREATE',
  CANNED_RESPONSE_EDIT: 'CANNED_RESPONSE_EDIT',
  CANNED_RESPONSE_REMOVE: 'CANNED_RESPONSE_REMOVE',
};

const FACEBOOK_PERMISSIONS = {
  FACEBOOK_LIST: 'FACEBOOK_LIST',
  FACEBOOK_CREATE: 'FACEBOOK_CREATE',
  FACEBOOK_EDIT: 'FACEBOOK_EDIT',
  FACEBOOK_REMOVE: 'FACEBOOK_REMOVE',
};

const WEBCLIENT_PERMISSIONS = {
  WEBCLIENT_CREATE: 'WEBCLIENT_CREATE',
  WEBCLIENT_CONFIG_LIST: 'WEBCLIENT_CONFIG_LIST',
  WEBCLIENT_CONFIG_CREATE: 'WEBCLIENT_CONFIG_CREATE',
  WEBCLIENT_CONFIG_EDIT: 'WEBCLIENT_CONFIG_EDIT',
  WEBCLIENT_CONFIG_REMOVE: 'WEBCLIENT_CONFIG_REMOVE',
  WEBCLIENT_WHITELIST_EDIT: 'WEBCLIENT_WHITELIST_EDIT',
};

const PROFILE_PERMISSIONS = {
  PROFILE_READ: 'PROFILE_READ',
  PROFILE_EDIT: 'PROFILE_EDIT',
};

const ROLE_PERMISSIONS = {
  LIST_ROLES: 'LIST_ROLES',
  EDIT_ROLES: 'EDIT_ROLES',
};

const LINK_PERMISSIONS = {
  MANAGE_LINKS: 'LINK_MANAGE',
};

export const PERMISSIONS = {
  GLOBAL: GLOBAL_PERMISSIONS,
  COMPANY: COMPANY_PERMISSIONS,
  CONVERSATION: CONVERSATION_PERMISSIONS,
  FLOW: FLOW_PERMISSIONS,
  AGENT: AGENT_PERMISSIONS,
  ROLE: ROLE_PERMISSIONS,
  INBOX: INBOX_PERMISSIONS,
  LABEL: LABEL_PERMISSIONS,
  CANNED_RESPONSE: CANNED_RESPONSE_PERMISSIONS,
  FACEBOOK: FACEBOOK_PERMISSIONS,
  WEBCLIENT: WEBCLIENT_PERMISSIONS,
  PROFILE: PROFILE_PERMISSIONS,
  LINK: LINK_PERMISSIONS,
};

export const ALL_PERMISSIONS = Object.values({
  ...PERMISSIONS.GLOBAL,
  ...PERMISSIONS.COMPANY,
  ...PERMISSIONS.CONVERSATION,
  ...PERMISSIONS.FLOW,
  ...PERMISSIONS.AGENT,
  ...PERMISSIONS.INBOX,
  ...PERMISSIONS.LABEL,
  ...PERMISSIONS.ROLE,
  ...PERMISSIONS.CANNED_RESPONSE,
  ...PERMISSIONS.FACEBOOK,
  ...PERMISSIONS.WEBCLIENT,
  ...PERMISSIONS.PROFILE,
});

export const DEFAULT_ROLES = {
  AGENT_ROLE: 'agent',
  COMPANY_ADMIN_ROLE: 'companyAdmin',
  CONVERSED_ADMIN_ROLE: 'conversedAdmin',
  SUPER_ADMIN_ROLE: 'superAdmin',
} as const;
