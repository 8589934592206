import React, { ReactElement, useCallback } from 'react';
import { useModal } from '../../../hooks/modal/useModal';
import AgentsInfoModal from '../../settings-page/modal/agents/AgentsInfoModal';
import CreateAgentModal from '../../settings-page/modal/agents/CreateAgentModal';
import DeleteAgentModal from '../../settings-page/modal/agents/DeleteAgentModal';
import EditAgentModal from '../../settings-page/modal/agents/EditAgentModal';
import style from '../../../assets/scss/components/common/Modal.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { Modal } from 'react-bootstrap';
import { ModalType } from '../../../interfaces/redux/TUIState';
import { useModalListeners } from '../../../hooks/modal/useModalListeners';
import CreateInboxModal from '../../settings-page/modal/inboxes/CreateInboxModal';
import EditInboxModal from '../../settings-page/modal/inboxes/EditInboxModal';
import InboxesInfoModal from '../../settings-page/modal/inboxes/InboxesInfoModal';
import DeleteInboxModal from '../../settings-page/modal/inboxes/DeleteInboxModal';
import { CreateBlockModal } from '../../designer/modal/CreateBlockModal';
import { EditBlockModal } from '../../designer/modal/EditBlockModal';
import { DeleteBlockModal } from '../../designer/modal/DeleteBlockModal';
import CreateCannedResponseModal from '../../settings-page/modal/canned-responses/CreateCannedResponseModal';
import CannedResponsesInfoModal from '../../settings-page/modal/canned-responses/CannedResponsesInfoModal';
import EditCannedResponseModal from '../../settings-page/modal/canned-responses/EditCannedResponseModal';
import DeleteCannedResponseModal from '../../settings-page/modal/canned-responses/DeleteCannedResponseModal';
import WebclientConfigInfoModal from '../../settings-page/modal/webclient/WebclientConfigInfoModal';
import WebclientWhitelistInfoModal from '../../settings-page/modal/webclient/whitelist/WebclientWhitelistInfoModal';
import WebclientSnippetModal from '../../settings-page/modal/webclient/WebclientSnippetModal';
import CreateWebclientConfigModal from '../../settings-page/modal/webclient/CreateWebclientConfigModal';
import EditWebclientConfigModal from '../../settings-page/modal/webclient/EditWebclientConfigModal';
import DeleteWebclientConfigModal from '../../settings-page/modal/webclient/DeleteWebclientConfigModal';
import WebclientInfoModal from '../../settings-page/modal/webclient/WebclientInfoModal';
import { LabelInfoModal } from '../../settings-page/modal/labels/LabelInfoModal';
import { CreateLabelModal } from '../../settings-page/modal/labels/CreateLabelModal';
import { EditLabelModal } from '../../settings-page/modal/labels/EditLabelModal';
import { DeleteLabelModal } from '../../settings-page/modal/labels/DeleteLabelModal';
import AddWebclientWhitelistModal from '../../settings-page/modal/webclient/whitelist/AddWebclientWhitelistModal';
import RemoveWebclientWhitelistModal from '../../settings-page/modal/webclient/whitelist/RemoveWebclientWhitelistModal';
import SetupWebclient from '../../settings-page/modal/webclient/SetupWebclient';
import FacebookInfoModal from '../../settings-page/modal/facebook/FacebookInfoModal';
import OpenChatModal from '../../chat/chat-panel-header/OpenChatModal';
import { AccountInfoModal } from '../../settings-page/account/AccountInfoModal';
import FacebookSettingsModal from '../../settings-page/modal/facebook/FacebookSettingsModal';
import CreateLinkModal from '../../settings-page/modal/links/CreateLinkModal';
import { useTranslation } from 'react-i18next';
import EditLinkModal from '../../settings-page/modal/links/EditLinkModal';
import DeleteLinkModal from '../../settings-page/modal/links/DeleteLinkModal';
import LinkInfoModal from '../../settings-page/modal/links/LinkInfoModal';
import LinkStatisticsModal from '../../settings-page/modal/links/LinkStatisticsModal';
import Enable2FaModal from '../../settings-page/modal/2fa/Enable2FAModal';
import Disable2FaModal from '../../settings-page/modal/2fa/Disable2FAModal';

type TModalTitle =
  | 'modals.title.add-agent'
  | 'modals.title.info-agent'
  | 'modals.title.edit-agent'
  | 'modals.title.delete-agent'
  | 'modals.title.add-inbox'
  | 'modals.title.info-inbox'
  | 'modals.title.edit-inbox'
  | 'modals.title.delete-inbox'
  | 'modals.title.add-block'
  | 'modals.title.edit-block'
  | 'modals.title.delete-block'
  | 'modals.title.add-canned-response'
  | 'modals.title.info-canned-response'
  | 'modals.title.edit-canned-response'
  | 'modals.title.delete-canned-response'
  | 'modals.title.edit-webclient-config'
  | 'modals.title.info-webclient-config'
  | 'modals.title.info-webclient-whitelist'
  | 'modals.title.snippet-webclient-connector'
  | 'modals.title.create-webclient-config'
  | 'modals.title.delete-webclient-config'
  | 'modals.title.add-webclient-whitelist'
  | 'modals.title.delete-webclient-whitelist'
  | 'modals.title.info-webclient'
  | 'modals.title.info-label'
  | 'modals.title.add-label'
  | 'modals.title.delete-label'
  | 'modals.title.edit-label'
  | 'modals.title.setup-webclient'
  | 'modals.title.info-facebook'
  | 'modals.title.open-chat'
  | 'modals.title.create-link'
  | 'modals.title.edit-link'
  | 'modals.title.delete-link'
  | 'modals.title.info-link'
  | 'modals.title.link-statistics'
  | 'modals.title.account-info'
  | 'modals.title.settings-facebook'
  | 'modals.title.enable-2fa'
  | 'modals.title.disable-2fa';

type TModal = {
  component: () => ReactElement;
  title: TModalTitle;
};

const modalMap: Record<ModalType, TModal> = {
  'add-agent': { component: () => <CreateAgentModal />, title: 'modals.title.add-agent' },
  'agent-info': { component: () => <AgentsInfoModal />, title: 'modals.title.info-agent' },
  'edit-agent': { component: () => <EditAgentModal />, title: 'modals.title.edit-agent' },
  'delete-agent': { component: () => <DeleteAgentModal />, title: 'modals.title.delete-agent' },
  'add-inbox': { component: () => <CreateInboxModal />, title: 'modals.title.add-inbox' },
  'inbox-info': { component: () => <InboxesInfoModal />, title: 'modals.title.info-inbox' },
  'edit-inbox': { component: () => <EditInboxModal />, title: 'modals.title.edit-inbox' },
  'delete-inbox': { component: () => <DeleteInboxModal />, title: 'modals.title.delete-inbox' },
  'add-block': { component: () => <CreateBlockModal />, title: 'modals.title.add-block' },
  'edit-block': { component: () => <EditBlockModal />, title: 'modals.title.edit-block' },
  'delete-block': { component: () => <DeleteBlockModal />, title: 'modals.title.delete-block' },
  'add-canned-response': { component: () => <CreateCannedResponseModal />, title: 'modals.title.add-canned-response' },
  'canned-response-info': { component: () => <CannedResponsesInfoModal />, title: 'modals.title.info-canned-response' },
  'edit-canned-response': { component: () => <EditCannedResponseModal />, title: 'modals.title.edit-canned-response' },
  'delete-canned-response': {
    component: () => <DeleteCannedResponseModal />,
    title: 'modals.title.delete-canned-response',
  },
  'edit-webclient-config': {
    component: () => <EditWebclientConfigModal />,
    title: 'modals.title.edit-webclient-config',
  },
  'webclient-config-info': {
    component: () => <WebclientConfigInfoModal />,
    title: 'modals.title.info-webclient-config',
  },
  'webclient-whitelist-info': {
    component: () => <WebclientWhitelistInfoModal />,
    title: 'modals.title.info-webclient-whitelist',
  },
  'webclient-snippet': {
    component: () => <WebclientSnippetModal />,
    title: 'modals.title.snippet-webclient-connector',
  },
  'create-webclient-config': {
    component: () => <CreateWebclientConfigModal />,
    title: 'modals.title.create-webclient-config',
  },
  'delete-webclient-config': {
    component: () => <DeleteWebclientConfigModal />,
    title: 'modals.title.delete-webclient-config',
  },
  'add-webclient-whitelist': {
    component: () => <AddWebclientWhitelistModal />,
    title: 'modals.title.add-webclient-whitelist',
  },
  'delete-webclient-whitelist': {
    component: () => <RemoveWebclientWhitelistModal />,
    title: 'modals.title.delete-webclient-whitelist',
  },
  'webclient-info': { component: () => <WebclientInfoModal />, title: 'modals.title.info-webclient' },
  'info-label': { component: () => <LabelInfoModal />, title: 'modals.title.info-label' },
  'add-label': { component: () => <CreateLabelModal />, title: 'modals.title.add-label' },
  'delete-label': { component: () => <DeleteLabelModal />, title: 'modals.title.delete-label' },
  'edit-label': { component: () => <EditLabelModal />, title: 'modals.title.edit-label' },
  'setup-webclient': { component: () => <SetupWebclient />, title: 'modals.title.setup-webclient' },
  'facebook-info': { component: () => <FacebookInfoModal />, title: 'modals.title.info-facebook' },
  'open-chat': { component: () => <OpenChatModal />, title: 'modals.title.open-chat' },
  'account-info': { component: () => <AccountInfoModal />, title: 'modals.title.account-info' },
  'facebook-settings': { component: () => <FacebookSettingsModal />, title: 'modals.title.settings-facebook' },
  'add-link': { component: () => <CreateLinkModal />, title: 'modals.title.create-link' },
  'edit-link': { component: () => <EditLinkModal />, title: 'modals.title.edit-link' },
  'delete-link': { component: () => <DeleteLinkModal />, title: 'modals.title.delete-link' },
  'link-info': { component: () => <LinkInfoModal />, title: 'modals.title.info-link' },
  'link-statistics': { component: () => <LinkStatisticsModal />, title: 'modals.title.link-statistics' },
  'enable-2fa': { component: () => <Enable2FaModal />, title: 'modals.title.enable-2fa' },
  'disable-2fa': { component: () => <Disable2FaModal />, title: 'modals.title.disable-2fa' },
};

export const ModalContainer: React.FC = () => {
  const { type, hideModal, title } = useModal();
  const { t } = useTranslation();
  useModalListeners();

  const getTitle = useCallback(
    ({ title, modal }: { title?: string; modal?: TModal | null }): string => {
      if (title) return title;
      if (!modal?.title) {
        return '';
      }
      return t(modal.title);
    },
    [t],
  );

  const { modalInbox, modalHeader } = convertKeysToCamelCase(style);

  const activeModal = type ? modalMap[type] : null;
  const actualTitle = getTitle({ title, modal: activeModal });

  return (
    <Modal show={!!activeModal} aria-labelledby="contained-modal-title-vcenter" centered className={`${modalInbox}`}>
      <Modal.Header className={`${modalHeader}`} onClick={() => hideModal()} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{actualTitle}</h3>
        </Modal.Title>
      </Modal.Header>
      {activeModal?.component()}
    </Modal>
  );
};
