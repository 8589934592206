import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useSidebar } from '../../../hooks/useSidebar';

// Icons
import appBarIcon from '../../../assets/images/logo-blue.svg';

import style from '../../../assets/scss/components/header/AppBar.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { Link } from 'react-router-dom';
import { ProfileDropdown } from './dropdown/ProfileDropdown';

import { MaterialIcon } from '../../common/MaterialIcon';

export const AppBar: React.FC = () => {
  const { toggleSideBar } = useSidebar();
  const {
    appBar,
    cvNavWrapper,
    // cvNavBreak,
    menuLogoWrapper,
    cvLogo,
    cvMenu,
  } = convertKeysToCamelCase(style);

  return (
    <nav className={appBar}>
      <div className={menuLogoWrapper}>
        <div className={cvMenu} role="button" onClick={() => toggleSideBar()}>
          <MaterialIcon icon={'menu'} type={'rounded'} />
        </div>
        <Navbar as={Link} to="/">
          <img src={appBarIcon} className={cvLogo} />
        </Navbar>
      </div>
      <div className={cvNavWrapper}>
        {/* <div>
          <MaterialIcon icon={'notifications'} />
        </div> */}
        {/* <div className={cvNavBreak}></div> */}
        <ProfileDropdown />
      </div>
    </nav>
  );
};
