import React from 'react';
import styles from '../../../assets/scss/components/chat/ChatInput.module.scss';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import CannedResponsesMenu from './CannedResponsesMenu';
import { useChatInput } from '../../../hooks/chat/useChatInput';
import { ChatInputHeader } from './ChatInputHeader';
import { ChatInputFooter } from './ChatInputFooter';
import { useCannedResponsesEvents } from '../../../hooks/chat/useCannedResponsesEvents';
import { useChatInputEvents } from '../../../hooks/chat/useChatInputEvents';

export const ChatInput: React.FC = () => {
  const { chatMode, message, messagingEnabled } = useChatInput();
  const { handleInputChange, handleInputKeydown } = useChatInputEvents();
  useCannedResponsesEvents();

  const { chatInput, note } = convertKeysToCamelCase(styles);

  return (
    <div className={`d-flex flex-column ${chatInput}`}>
      <CannedResponsesMenu />
      <ChatInputHeader />

      {messagingEnabled && (
        <>
          <textarea
            className={`${getCSS(chatMode === 'note', note)} w-100`}
            rows={3}
            onInput={handleInputChange}
            onKeyDown={handleInputKeydown}
            value={message}
          ></textarea>

          <ChatInputFooter />
        </>
      )}
    </div>
  );
};
