import { useCallback } from 'react';
import {
  getCompanyConnectionSettingsApi,
  getCompanyGeneralSettingsApi,
  updateCompanyConnectionSettingsApi,
  updateCompanyGeneralSettingsApi,
  getCompanyMembersApi,
  removeCompanyMemberApi,
} from '../../../api/companyApi';
import { Actions } from '../../../constants/actionTypes';
import { toastErrorTranslated, toastSuccessTranslated } from '../../../helpers/toast';
import { useMainDispatch } from '../../useMainDispatch';
import { createCompanyApi, addCompanyMemberApi, getAllMembersApi } from '../../../api/companyApi';
import {
  CompanyConnectionSettingsDTO,
  CompanyDTO,
  CompanyGeneralSettingsDTO,
  CreateMemberDTO,
} from '@conversed/shared/src/central/dto/company';
import { useHistory } from 'react-router';
import { SELECT_CUSTOMER_ROUTE } from '../../../constants/routes';
import { useCurrentUserActions } from '../../auth/useCurrentUserActions';

export const useCompanySettingsActions = () => {
  const dispatch = useMainDispatch();
  const history = useHistory();
  const { fetchCurrentUser } = useCurrentUserActions();

  const fetchCompanyMembers = useCallback(async () => {
    try {
      const { data: members } = await getCompanyMembersApi();
      dispatch({ type: Actions.SET_COMPANY_SETTINGS, payload: { members } });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  const fetchMemberAutocompleteList = useCallback(
    async (emailPattern: string) => {
      try {
        const { data: membersAutocompleteList } = await getAllMembersApi(emailPattern);
        dispatch({
          type: Actions.SET_COMPANY_SETTINGS,
          payload: { membersAutocompleteList: membersAutocompleteList },
        });
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  const fetchConnectionSettings = useCallback(async () => {
    try {
      const { data: connectionSettings } = await getCompanyConnectionSettingsApi();
      dispatch({ type: Actions.SET_COMPANY_SETTINGS, payload: { connectionSettings } });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  const fetchGeneralSettings = useCallback(async () => {
    try {
      const { data: generalSettings } = await getCompanyGeneralSettingsApi();
      dispatch({ type: Actions.SET_COMPANY_SETTINGS, payload: { generalSettings } });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  const updateConnectionSettings = useCallback(
    async (connectionSettingsData: CompanyConnectionSettingsDTO) => {
      try {
        const { data: connectionSettings } = await updateCompanyConnectionSettingsApi(connectionSettingsData);
        dispatch({ type: Actions.SET_COMPANY_SETTINGS, payload: { connectionSettings } });
        toastSuccessTranslated('Success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  const updateGeneralSettings = useCallback(
    async (generalSettingsData: CompanyGeneralSettingsDTO) => {
      try {
        const { data: generalSettings } = await updateCompanyGeneralSettingsApi(generalSettingsData);
        dispatch({ type: Actions.SET_COMPANY_SETTINGS, payload: { generalSettings } });
        fetchCurrentUser();
        toastSuccessTranslated('Success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch, fetchCurrentUser],
  );

  const createCompany = useCallback(
    async (companyData: CompanyDTO) => {
      try {
        await createCompanyApi(companyData);
        history.push(SELECT_CUSTOMER_ROUTE);
        fetchCurrentUser();
        toastSuccessTranslated('Success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [history, fetchCurrentUser],
  );

  const addMember = useCallback(
    async (memberData: CreateMemberDTO) => {
      try {
        const { data: member } = await addCompanyMemberApi(memberData);
        dispatch({ type: Actions.ADD_COMPANY_MEMBER, payload: member });
        toastSuccessTranslated('Success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  const removeMember = useCallback(
    async (memberId: string) => {
      try {
        const { data: member } = await removeCompanyMemberApi(memberId);
        dispatch({ type: Actions.ADD_COMPANY_MEMBER, payload: member });
        toastSuccessTranslated('Success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  return {
    fetchCompanyMembers,
    fetchConnectionSettings,
    fetchGeneralSettings,
    updateConnectionSettings,
    updateGeneralSettings,
    createCompany,
    removeMember,
    addMember,
    fetchMemberAutocompleteList,
  };
};
