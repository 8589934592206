import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useSignInForm } from '../../hooks/auth/useSignInForm';
import { AuthInput } from './AuthInput';
import { HoverText } from '../common/HoverText';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { EButtonType, GenericButton } from '../common/Button';
import { useHistory } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from '../../constants/routes';
import MaterialIcon from '../common/MaterialIcon';

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { submitSignIn, registerInput, validationErrors } = useSignInForm();
  const history = useHistory();

  const switchToForgotPassword = () => history.push(FORGOT_PASSWORD_ROUTE);

  const {
    loginForm,
    loginFormFrame,
    // loginFormFrameSignup,
    loginFormFrameButtonsOptions,
    loginFormFrameButtonsOptionsForgot,
  } = convertKeysToCamelCase(style);

  return (
    <form className={loginForm} onSubmit={submitSignIn}>
      <AuthInput
        title="auth.input.email"
        name="email"
        autoComplete="username"
        errorMessage={validationErrors?.email?.message}
        registerInput={registerInput}
      />
      <AuthInput
        title="auth.input.password"
        name="password"
        type="password"
        autoComplete="current-password"
        errorMessage={validationErrors?.password?.message}
        registerInput={registerInput}
      />

      <div className={loginFormFrameButtonsOptions}>
        <div className="conversed-checkbox">
          <input type="checkbox" id="remember-me-cbox" {...registerInput('rememberMe')} />
          <label htmlFor="remember-me-cbox">
            <MaterialIcon icon={'check'} classes={['icon']} />
            <p>{t('auth.login.remember-me')}</p>
          </label>
        </div>
        <HoverText
          className={loginFormFrameButtonsOptionsForgot}
          text={t('auth.login.forgot-password')}
          onClick={switchToForgotPassword}
        />
      </div>

      <div className={loginFormFrame}>
        <GenericButton buttonType={EButtonType.PRIMARY} text={t(`auth.login.buttons.login`)} type="submit" />
        {/* <HoverText className={loginFormFrameSignup} text={t('auth.login.signup')} onClick={switchToSignUp} /> */}
      </div>
    </form>
  );
};
