import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useWebclientActions } from '../../../../hooks/settings/webclients/useWebclientActions';
import { useTranslation } from 'react-i18next';

export const SetupWebclient: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { createWebclient } = useWebclientActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <Modal.Body>
      <p className={cvRow}>{t('settings.webclient.config.create.confirmation')}</p>
      <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
        <Button
          onClick={() => {
            createWebclient();
            hideModal();
          }}
        >
          {t('general.buttons.create')}
        </Button>
        <Button
          onClick={() => {
            hideModal();
          }}
        >
          {t('general.buttons.abort')}
        </Button>
      </Modal.Footer>
    </Modal.Body>
  );
};

export default SetupWebclient;
