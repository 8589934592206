import React from 'react';
import InboxDataModal from './InboxDataModal';
import { useInboxes } from '../../../../hooks/settings/inboxes/useInboxes';
import { useInboxActions } from '../../../../hooks/settings/inboxes/useInboxActions';

export const EditInboxModal: React.FC = () => {
  const { editInbox } = useInboxActions();

  const { selectedInbox } = useInboxes();

  const onSubmit = async (inboxData: any) => {
    if (selectedInbox?.identifier) {
      editInbox(selectedInbox.identifier, inboxData);
    }
  };

  return (
    <InboxDataModal
      onSubmit={onSubmit}
      inboxData={{
        name: selectedInbox?.name,
        skills: selectedInbox?.skills,
      }}
    />
  );
};

export default EditInboxModal;
