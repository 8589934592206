import React from 'react';
import { Button, Modal, Form, Row } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { CreateInboxRequestBody } from '@conversed/shared/src/central/dto/inbox.dto';
import { useTranslation } from 'react-i18next';
import ChipInput from '../../../input/chip/ChipInput';

type Props = {
  inboxData?: Partial<CreateInboxRequestBody>;
  onSubmit: (data: CreateInboxRequestBody) => void;
};

interface IInboxFormData {
  name: string;
  skills: string[];
}

export const InboxDataModal: React.FC<Props> = ({ inboxData, onSubmit }) => {
  const { cvRow, input, cvFooter } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { register, handleSubmit, control } = useForm<IInboxFormData>({
    shouldUseNativeValidation: true,
    defaultValues: inboxData,
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: IInboxFormData) => {
    onSubmit(data);
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <Form onSubmit={handleSubmit<IInboxFormData>(hideAndSubmit)}>
          <Row>
            <Form.Group className={cvRow}>
              <p>{t('settings.inbox.name')}</p>
              <Form.Control
                className={input}
                {...register('name', { required: t('settings.inbox.validation.name') })}
              />
            </Form.Group>
            <ChipInput name="skills" label={t('settings.inbox.skills')} register={register} control={control} />
          </Row>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">{t('general.buttons.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default InboxDataModal;
