import { useHistory } from 'react-router-dom';
import { CHAT_PAGE_ROUTE } from '../../constants/routes';
import { useModal } from '../modal/useModal';
import useChat from '../useChat';
import { useSelectedChatStatus } from './useSelectedChatStatus';
import { useLocation } from 'react-router';

export const useChatStatus = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { resolveChat } = useChat();
  const { showModal } = useModal();
  const { chatStatus } = useSelectedChatStatus();

  const setChatToResolved = () => {
    history.push({ pathname: CHAT_PAGE_ROUTE, search });
    resolveChat();
  };

  const setChatToOpen = () => {
    showModal('open-chat');
  };

  return {
    chatStatus,
    setChatToResolved,
    setChatToOpen,
  };
};
