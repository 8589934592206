import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';
import {
  CompanyConnectionSettingsDTO,
  CompanyDTO,
  CompanyGeneralSettingsDTO,
  CompanyMemberDTO,
  CreateMemberDTO,
} from '@conversed/shared/src/central/dto/company';

export const getCompanyMembersApi = (): TGeneralResponse<CompanyMemberDTO[]> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.COMPANY}/selected/members`);

export const getAllMembersApi = (emailFilter: string): TGeneralResponse<string[]> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.COMPANY}/members`, { params: { emailFilter } });

export const getCompanyConnectionSettingsApi = (): TGeneralResponse<CompanyConnectionSettingsDTO> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.COMPANY}/selected/settings/connection`);

export const getCompanyGeneralSettingsApi = (): TGeneralResponse<CompanyGeneralSettingsDTO> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.COMPANY}/selected/settings/general`);

export const updateCompanyConnectionSettingsApi = (
  connectionSettings: CompanyConnectionSettingsDTO,
): TGeneralResponse<CompanyConnectionSettingsDTO> =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.COMPANY}/selected/settings/connection`, connectionSettings);

export const updateCompanyGeneralSettingsApi = (
  generalSettings: CompanyGeneralSettingsDTO,
): TGeneralResponse<CompanyGeneralSettingsDTO> =>
  axiosInterceptAccessTokenExpiry().put(`${ENDPOINTS.COMPANY}/selected/settings/general`, generalSettings);

export const createCompanyApi = (companyData: CompanyDTO): TGeneralResponse<CompanyDTO> =>
  axiosInterceptAccessTokenExpiry().post(`${ENDPOINTS.COMPANY}`, companyData);

export const addCompanyMemberApi = (memberData: CreateMemberDTO): TGeneralResponse<CompanyMemberDTO> =>
  axiosInterceptAccessTokenExpiry().patch(`${ENDPOINTS.COMPANY}/selected/members`, memberData);

export const removeCompanyMemberApi = (memberId: string): TGeneralResponse<CompanyMemberDTO> =>
  axiosInterceptAccessTokenExpiry().delete(`${ENDPOINTS.COMPANY}/selected/members/${memberId}`);
