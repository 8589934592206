import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/ContentTitle.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { useHistory } from 'react-router-dom';

type TProps = {
  title: string;
  onClickInfo?: CallableFunction;
};

export const ContentTitle: React.FC<TProps> = ({ title, onClickInfo }) => {
  const { goBack } = useHistory();

  const { settingsContentTitle } = convertKeysToCamelCase(style);

  return (
    <div className={settingsContentTitle}>
      <MaterialIcon icon={'arrow_back_ios'} onClick={() => goBack()} />
      <h3>{title}</h3>
      <MaterialIcon icon={'info'} classes={['clickable']} onClick={onClickInfo} />
    </div>
  );
};

export default ContentTitle;
