import React, { useEffect } from 'react';
import { useMainSelector } from '../../../../hooks/useMainSelector';
import { ChatPanelSidebar } from '../../../chat/chat-panel/ChatPanelSidebar';
import { useParams } from 'react-router-dom';
import { useChatActions } from '../../../../hooks/useChatActions';

export const ChatInfoPage: React.FC = () => {
  const {
    chat: { selectedConversation },
  } = useMainSelector();

  const { getConversationById } = useChatActions();

  const { id: conversationId } = useParams<{ id?: string }>();

  useEffect(() => {
    if (conversationId) {
      getConversationById(conversationId);
    }
  }, [conversationId, getConversationById]);

  return selectedConversation ? (
    <div className="d-flex flex-row flex-grow-1">
      <ChatPanelSidebar selectedConversation={selectedConversation} />
    </div>
  ) : null;
};
