import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { AuthInput } from './AuthInput';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { use2FAForm } from '../../hooks/auth/use2FAForm';
import { useTranslation } from 'react-i18next';
import { EButtonType, GenericButton } from '../common/Button';
import { useMainSelector } from '../../hooks/useMainSelector';

export const OTPForm: React.FC = () => {
  const { submitSignInAfter2FA, registerInput, validationErrors } = use2FAForm();
  const { t } = useTranslation();
  const {
    user: { email, password },
  } = useMainSelector();

  const { loginForm, loginFormFrame, loginFormDescription } = convertKeysToCamelCase(style);

  return (
    <form className={loginForm} onSubmit={submitSignInAfter2FA({ email, password })}>
      <p className={loginFormDescription}>{t('auth.login.otp.desc')}</p>
      <AuthInput
        title={'auth.input.2fa.title'}
        name="token"
        minLength={6}
        maxLength={6}
        errorMessage={validationErrors?.token?.message}
        registerInput={registerInput}
      />

      <div className={loginFormFrame}>
        <GenericButton buttonType={EButtonType.PRIMARY} text={t('auth.2fa.buttons.verify')} type="submit" />
      </div>
    </form>
  );
};
