import { ALL_PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { useMainSelector } from '../../useMainSelector';

export const useRole = () => {
  const {
    settings: { roles, company },
  } = useMainSelector();

  return {
    roles,
    permissions: ALL_PERMISSIONS,
    assignableRoles: company?.assignableRoles || [],
  };
};

export default useRole;
