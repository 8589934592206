import React from 'react';

import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { Form, Row, Col, Container } from 'react-bootstrap';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { TBlocksFormData } from '../../../../interfaces/TDesigner';
import { useTranslation } from 'react-i18next';

interface IProps {
  register: UseFormRegister<Partial<TBlocksFormData>>;
}

export const BlockCreationForm: React.FC<IProps> = ({ register }) => {
  const { input, cvRow } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <Row>
      <Container fluid>
        <Row>
          <Col>
            <Form.Group className={cvRow}>
              <p>{t('flow.blocks.identifier')}</p>
              <Form.Control
                as={'textarea'}
                className={input}
                {...register(`id`, { required: t('flow.validation.id.required') })}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
