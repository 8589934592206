import { DTORoleGET, DTORolePUT, GetAssignableRolesDTO } from '@conversed/shared/src/central/dto/role.dto';
import { ENDPOINTS } from '../constants/apiEndpoints';
import { axiosInterceptAccessTokenExpiry } from './axios/axiosIntercepted';
import { TGeneralResponse } from './dto/common.dto';

export const getAssignableRolesApi = (): TGeneralResponse<GetAssignableRolesDTO> =>
  axiosInterceptAccessTokenExpiry().get(`${ENDPOINTS.ROLE}/assignable`);

export const getRolesApi = (): TGeneralResponse<DTORoleGET> => axiosInterceptAccessTokenExpiry().get(ENDPOINTS.ROLE);

export const putRolesApi = (roles: DTORolePUT): TGeneralResponse =>
  axiosInterceptAccessTokenExpiry().put(ENDPOINTS.ROLE, roles);
