import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useAgents } from '../../../../hooks/settings/agents/useAgents';
import { useAgentActions } from '../../../../hooks/settings/agents/useAgentActions';
import { useModal } from '../../../../hooks/modal/useModal';
import { useTranslation } from 'react-i18next';

export const DeleteAgentModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { selectedAgent: agent } = useAgents();
  const { deleteAgent } = useAgentActions();
  const { hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      {agent && (
        <Modal.Body>
          <p className={cvRow}>
            {t('settings.agent.delete.confirmation')}{' '}
            <b>
              {agent.firstName} {agent.lastName}
            </b>{' '}
            ({agent.email}).
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteAgent(agent.id);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteAgentModal;
