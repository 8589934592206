import { useQueryState } from '../useQueryState';

const INBOX_QUERY_PARAM_KEY = 'inbox';

export const useSelectedInbox = () => {
  const { query, setQuery } = useQueryState(INBOX_QUERY_PARAM_KEY);

  return {
    selectedInboxId: query === '' ? undefined : query,
    selectInboxId: (inboxId: string | undefined) => setQuery(inboxId ? inboxId : ''),
  };
};
