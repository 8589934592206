import React from 'react';
import { Dropdown } from 'react-bootstrap';
import style from '../../../assets/scss/components/common/DropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { Label } from '../Label';
import { useLabelSelector } from '../../../hooks/chat/useLabelSelector';
import MaterialIcon from '../../common/MaterialIcon';

export const LabelSelectorDropdown: React.FC = () => {
  const { cv, dropdown, toggler, item, menu, custom, addLabel } = convertKeysToCamelCase(style);
  const { labels, selectLabel, selectedLabels } = useLabelSelector();

  return (
    <Dropdown className={`${cv} ${dropdown} ${custom}`}>
      <Dropdown.Toggle className={`${cv} ${toggler} ${custom} ${addLabel}`}>
        <MaterialIcon icon={'add_circle_outline'} />
      </Dropdown.Toggle>

      <Dropdown.Menu className={`${cv} ${menu} ${custom} ${addLabel}`}>
        {labels.map((label) => {
          return (
            <Dropdown.Item
              key={label.identifier}
              className={`${cv} ${item} ${custom}`}
              onClick={() => selectLabel(label)}
            >
              <Label labelName={label.labelName} labelColor={label.labelColor} />
              {selectedLabels.map((label) => label.identifier).includes(label.identifier) && (
                <MaterialIcon icon="done" />
              )}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
