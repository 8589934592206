import { useCallback, useMemo } from 'react';
import { Elements, isNode, Node, useStore, useStoreActions, useZoomPanHelper } from 'react-flow-renderer';
import { ButtonNode } from '../../components/designer/nodes/ButtonNode';
import { DataNode } from '../../components/designer/nodes/DataNode';
import { EntryNode } from '../../components/designer/nodes/EntryNode';
import { FlowNode } from '../../components/designer/nodes/FlowNode';
import { ImageNode } from '../../components/designer/nodes/ImageNode';
import { RouteNode } from '../../components/designer/nodes/RouteNode';
import { TextNode } from '../../components/designer/nodes/TextNode';
import { TBlocksConfig, TBlocksConfigWithPositions, TBlockWithPosition } from '../../interfaces/TDesigner';
import { useFlow } from './flow/useFlow';

type TNodeStyle = {
  nodeColor: string;
  strokeColor: string;
};

export const useFlowNode = () => {
  const { flowSearchQuery } = useFlow();
  const store = useStore();
  const { setCenter } = useZoomPanHelper();
  const setSelectedNodes = useStoreActions(
    (actions) => (elements: Elements<TBlockWithPosition>) =>
      actions.setSelectedElements(elements.filter((element) => isNode(element))),
  );

  const getNodePosition = (node: Node<TBlockWithPosition>) => {
    return {
      x: node.__rf.position.x + node.__rf.width / 2,
      y: node.__rf.position.y + node.__rf.height / 2,
    };
  };

  const getNodes = useCallback((): Array<Node<TBlockWithPosition>> => {
    const { nodes } = store.getState();
    return nodes;
  }, [store]);

  const getBlocksFromNodes = (): TBlocksConfigWithPositions => {
    const nodes = getNodes();
    const blocks = nodes.flatMap((node) => (node.data ? [node.data] : []));
    return blocks.filter((block) => block.type !== 'entry'); // * Omit entry from blocks
  };

  const getBlocksFromNodesWithoutPosition = (): TBlocksConfig =>
    getBlocksFromNodes().map((block: TBlockWithPosition) => ({
      ...block,
      verticalPosition: undefined,
      horizontalPosition: undefined,
    }));

  const focusNode = (node: Node<TBlockWithPosition>, zoomLevel?: number) => {
    const { nodes, transform } = store.getState();
    const [, , zoom] = transform;

    if (!node.id) {
      return;
    }

    const selectedNode = nodes.find((innerNode) => innerNode.id === node.id);

    if (!selectedNode) {
      return;
    }

    const { x, y } = getNodePosition(selectedNode);
    setCenter(x, y, zoomLevel ?? zoom);
  };

  const styleMap: Record<string, TNodeStyle> = {
    textNode: {
      nodeColor: '#333333',
      strokeColor: '#0041d0',
    },
    buttonNode: {
      nodeColor: '#333333',
      strokeColor: '#ff0072',
    },
    routeNode: {
      nodeColor: '#333333',
      strokeColor: '#ff0072',
    },
    dataNode: {
      nodeColor: '#333333',
      strokeColor: '#0041d0',
    },
    flowNode: {
      nodeColor: '#333333',
      strokeColor: '#ff0072',
    },
    default: {
      nodeColor: '#333333',
      strokeColor: '#0041d0',
    },
  };

  const getStyleForNodeType = (node: Node): TNodeStyle => {
    if (!node?.type) {
      return {
        nodeColor: '#333333',
        strokeColor: '#0041d0',
      };
    }

    return styleMap[node.type] ?? styleMap['default'];
  };

  const nodeTypes = {
    textNode: TextNode,
    buttonNode: ButtonNode,
    routeNode: RouteNode,
    dataNode: DataNode,
    flowNode: FlowNode,
    imageNode: ImageNode,
    entryNode: EntryNode,
  };

  const filteredNodes = useMemo(
    () =>
      getNodes()?.filter((node) => flowSearchQuery && node.id.toLowerCase().includes(flowSearchQuery.toLowerCase())) ??
      [],
    [flowSearchQuery, getNodes],
  );

  const filteredNodesCount = useMemo(() => filteredNodes.length, [filteredNodes]);

  return {
    nodeTypes,
    styles: styleMap,
    getStyleForNodeType,
    setSelectedNodes,
    getNodePosition,
    getNodes,
    getBlocksFromNodes,
    getBlocksFromNodesWithoutPosition,
    focusNode,
    filteredNodes,
    filteredNodesCount,
  };
};
