import { DisableMFAReqDTO } from '@conversed/shared/src/central/dto/mfa.dto';
import { useForm } from 'react-hook-form';
import { useMfaActions } from '../../auth/useMFAActions';
import { useModal } from '../../modal/useModal';

export const useMfaDisableForm = () => {
  const { disableMFA } = useMfaActions();
  const { hideModal } = useModal();
  const { register, handleSubmit } = useForm<DisableMFAReqDTO>();

  const submit = (data: DisableMFAReqDTO) => {
    disableMFA(data.password, data.token);
    hideModal();
  };

  return { register, handleSubmit: handleSubmit(submit) };
};
