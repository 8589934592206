import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useCannedResponses } from '../../../../hooks/chat/useCannedResponses';
import { useCannedResponsesActions } from '../../../../hooks/chat/useCannedResponsesActions';
import { useTranslation } from 'react-i18next';

export const DeleteCannedResponseModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { selectedCannedResponse } = useCannedResponses();
  const { deleteCannedResponse } = useCannedResponsesActions();
  const { t } = useTranslation();
  const { hideModal } = useModal();

  return (
    <>
      {selectedCannedResponse && (
        <Modal.Body>
          <p className={cvRow}>
            {t('settings.canned-response.delete.confirmation')}
            <b>{selectedCannedResponse.shorthand}</b>?
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteCannedResponse(selectedCannedResponse.identifier);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteCannedResponseModal;
