import React from 'react';
import { convertKeysToCamelCase, getCSS } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useChatInboxes } from '../../../hooks/chat/useChatInboxes';
import { useTranslation } from 'react-i18next';

export const ChatSidebarGroupDropdown: React.FC = () => {
  const { filteredInboxes, selectInboxId, selectedInbox } = useChatInboxes();
  const { groupDropdown, active } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <DropdownButton
      className={groupDropdown}
      bsPrefix={groupDropdown}
      id="dropdown-basic-button"
      title={selectedInbox?.name ?? 'Inbox'}
    >
      <Dropdown.Item onClick={() => selectInboxId(undefined)} className={getCSS(!selectedInbox, active)}>
        {t('chat.sidebar.inbox.title')}
      </Dropdown.Item>
      {filteredInboxes &&
        filteredInboxes.map((inbox, index) => {
          return (
            <Dropdown.Item onClick={() => selectInboxId(inbox.identifier)} key={index}>
              {inbox.name}
            </Dropdown.Item>
          );
        })}
    </DropdownButton>
  );
};

export default ChatSidebarGroupDropdown;
