import { DragEventHandler, useRef, useState } from 'react';
import {
  removeElements,
  isNode,
  Elements as FlowElements,
  Node,
  FlowElement,
  Connection,
  Edge,
  OnLoadFunc,
  OnLoadParams,
  updateEdge,
  OnEdgeUpdateFunc,
} from 'react-flow-renderer';
import { EFlowDirection } from '../../interfaces/EFlowDirection';
import { TBlockWithPosition, TNodeType } from '../../interfaces/TDesigner';
import { useModal } from '../modal/useModal';
import { useFlow } from './flow/useFlow';
import { useFlowActions } from './flow/useFlowActions';
import { useFlowEdge } from './useFlowEdge';
import { useFlowNode } from './useFlowNode';
import { getBlockTypeByNode } from '../../helpers/flowHelper';

export const useDesignerFlow = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | null>(null);
  const [elements, setElements] = useState<FlowElements<TBlockWithPosition>>();

  const { showModal } = useModal();
  const { nodeTypes } = useFlowNode();
  const { edgeTypes } = useFlowEdge();
  const { flowDirection } = useFlow();
  const { setBlockParamsForCreation, connectBlocksByIds, updateBlockPosition } = useFlowActions();

  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  const handleClickForNode = (element: Node<TBlockWithPosition>) => {
    if (!element) {
      return;
    }
  };

  const onElementClick = (_event: unknown, element: FlowElement<TBlockWithPosition>) => {
    if (isNode(element)) {
      handleClickForNode(element);
    }
  };

  const onElementsRemove = (elementsToRemove: FlowElements<TBlockWithPosition>) => {
    if (!elements) {
      return;
    }

    // * Remove elements from
    setElements(removeElements(elementsToRemove, elements));
  };

  const onConnect = (params: Edge<TBlockWithPosition> | Connection) => {
    if (!elements) {
      return;
    }

    const { source, target } = params;
    connectBlocksByIds(source, target);
  };

  const onFlowLoad: OnLoadFunc = (reactFlowInstance) => {
    reactFlowInstance.fitView();
    setReactFlowInstance(reactFlowInstance);
  };

  const onNodeDragStop = (_event: unknown, node: Node<TBlockWithPosition>) => {
    if (!elements || !node?.data) {
      return;
    }

    const isVertical = flowDirection === EFlowDirection.Vertical;
    const position = isVertical ? { verticalPosition: node.position } : { horizontalPosition: node.position };

    updateBlockPosition({
      ...node.data,
      ...position,
    });
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onEdgeUpdate: OnEdgeUpdateFunc = (oldEdge, newConnection) => {
    if (!elements) {
      return;
    }

    // * Update edge
    setElements(updateEdge(oldEdge, newConnection, elements));
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    if (!reactFlowInstance || !(reactFlowWrapper.current instanceof Element) || !elements) {
      return;
    }

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const blockType = getBlockTypeByNode(type as TNodeType);

    if (!blockType) {
      // * In case node leads to no block type (ex. entry node) then exit early
      return;
    }

    setBlockParamsForCreation({ position, type: blockType });
    showModal('add-block');
  };

  return {
    reactFlowWrapper,
    elements,
    setElements,
    onConnect,
    onDrop,
    onDragOver,
    onElementsRemove,
    onElementClick,
    onNodeDragStop,
    onFlowLoad,
    nodeTypes,
    edgeTypes,
    onEdgeUpdate,
  };
};
