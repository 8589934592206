import { TBlockWithPosition } from '@conversed/shared/src/central/types/block.type';
import { useCallback } from 'react';
import { Actions } from '../../../constants/actionTypes';
import { useMainDispatch } from '../../useMainDispatch';
import { useMainSelector } from '../../useMainSelector';

export const useBlocksUpdates = () => {
  const {
    designer: { blockUpdates },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const addBlock = useCallback(
    (block: TBlockWithPosition) => {
      dispatch({
        type: Actions.UPDATE_BLOCKS,
        payload: [block],
      });
    },
    [dispatch],
  );

  const updateBlocks = useCallback(
    (blocks: TBlockWithPosition[]) => {
      dispatch({
        type: Actions.UPDATE_BLOCKS,
        payload: blocks,
      });
    },
    [dispatch],
  );

  const deleteBlocks = useCallback(
    (blockIds: string[]) => {
      dispatch({
        type: Actions.DELETE_BLOCKS,
        payload: blockIds,
      });
    },
    [dispatch],
  );

  return {
    blockUpdates,
    addBlock,
    updateBlocks,
    deleteBlocks,
  };
};
