import { EnableMFAReqDTO } from '@conversed/shared/src/central/dto/mfa.dto';
import { useForm } from 'react-hook-form';
import { useMfaActions } from '../../auth/useMFAActions';
import { useModal } from '../../modal/useModal';

export const useMfaEnableForm = () => {
  const { enableMFA } = useMfaActions();
  const { hideModal } = useModal();
  const { register, handleSubmit } = useForm<EnableMFAReqDTO>();

  const submit = (data: EnableMFAReqDTO) => {
    enableMFA(data.token);
    hideModal();
  };

  return { register, handleSubmit: handleSubmit(submit) };
};
