import React from 'react';
import { Form, Col, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import { useNewMemberForm } from '../../../hooks/settings/company/useNewMemberForm';
import { useCompanySettings } from '../../../hooks/settings/company/useCompanySettings';
import MemberRoleColumn from './MemberRoleColumn';

export const NewMemberForm: React.FC = () => {
  const {
    submit,
    onNewMemberEmailChange,
    assignableRoles,
    selectedRoles,
    selectRole,
    selectMemberFromAutocomplete,
    register,
  } = useNewMemberForm();

  const { membersAutocompleteList } = useCompanySettings();

  const { t } = useTranslation();
  const {
    sectionCategory,
    inputForm,
    form,
    input,
    cvAddMemberForm,
    cvInputDropdownToggler,
    cvDropdownMenu,
    roleSelector,
  } = convertKeysToCamelCase(style);

  return (
    <div className={`${sectionCategory} ${form}`}>
      <Form onSubmit={submit} className={cvAddMemberForm}>
        <Dropdown>
          <p>{t('members-settings.add-new.email')}</p>
          <Dropdown.Toggle id="dropdown-basic" className={cvInputDropdownToggler}>
            <Form.Group as={Col} className={`${inputForm}`}>
              <Form.Control
                autoComplete="off"
                className={input}
                {...register('email', { required: t('general.validation.required') })}
                onChange={(event) => {
                  event.preventDefault();
                  onNewMemberEmailChange(event.target.value);
                }}
              />
            </Form.Group>
          </Dropdown.Toggle>

          {membersAutocompleteList.length > 0 && (
            <Dropdown.Menu className={cvDropdownMenu}>
              {membersAutocompleteList.map((memberEmail) => (
                <Dropdown.Item key={memberEmail} onClick={() => selectMemberFromAutocomplete(memberEmail)}>
                  {memberEmail}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>

        <div className={roleSelector}>
          <MemberRoleColumn
            currentRoles={selectedRoles}
            availableRoles={assignableRoles}
            toggleRoleForMember={(roleId) => {
              selectRole(roleId);
            }}
          />
        </div>

        <Button type="submit">{t('members-settings.add-new.invite')}</Button>
      </Form>
    </div>
  );
};

export default NewMemberForm;
