import { useCallback } from 'react';
import { useChips } from './useChips';

export const useChipInput = (chips: string[], onChange: (...args: any[]) => void) => {
  const { inputValue, setInputValue, removeChip, assignChip, removeLastChip } = useChips(chips, onChange);

  const onChipChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue],
  );

  const onChipBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      assignChip(e?.target?.value);
    },
    [assignChip],
  );

  const onChipKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (inputValue === '' && e.key === 'Backspace') {
        e.preventDefault();
        return removeLastChip();
      }

      if (e.key === ',' || e.key === 'Tab' || e.key === ' ') {
        e.preventDefault();
        return assignChip(inputValue);
      }
    },
    [assignChip, inputValue, removeLastChip],
  );

  const onClickRemoveChip = useCallback(
    (chip: string) => (_event: React.MouseEvent<HTMLInputElement>) => removeChip(chip),
    [removeChip],
  );

  return {
    inputValue,
    onChipChange,
    onChipBlur,
    onChipKeyDown,
    onClickRemoveChip,
  };
};
