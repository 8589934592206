import { useRef } from 'react';

export const useImageUpload = () => {
  const imageUploadRef = useRef<HTMLInputElement | null>(null);

  const openFileChooser = () => {
    imageUploadRef?.current?.click();
  };

  return {
    imageUploadRef,
    openFileChooser,
  };
};
