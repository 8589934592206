import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from '../../../assets/scss/components/common/DropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import MaterialIcon from '../../common/MaterialIcon';

export const MoreDropDown: React.FC = () => {
  const { cv, dropdown, toggler, item, menu, custom, more } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <Dropdown className={`${cv} ${dropdown} ${custom} ${more}`}>
      <Dropdown.Toggle className={`${cv} ${toggler} ${custom} ${more}`}>
        <p>{t('chat.panel.header.options.more')}</p>
        <MaterialIcon icon={'more_horiz'} />
      </Dropdown.Toggle>

      <Dropdown.Menu className={`${cv} ${menu} ${custom} ${more}`}>
        <Dropdown.Item className={`${cv} ${item} ${custom}`} href="#/" target="_blank" rel="noopener noreferrer">
          <p>{t('chat.panel.header.options.new-tab')}</p>
        </Dropdown.Item>
        {/* <Dropdown.Item className={`${cv} ${item} ${custom}`} href="#/action-1">
          <p>Move to trash</p>
        </Dropdown.Item>
        <Dropdown.Item className={`${cv} ${item} ${custom}`} href="#/action-2">
          <p>Mark as spam</p>
        </Dropdown.Item>
        <Dropdown.Divider className={`${cv} ${divider} ${custom}`} />
        <Dropdown.Item eventKey="4" className={`${cv} ${item} ${custom}`}>
          <p>Block</p>
        </Dropdown.Item>
        <Dropdown.Divider className={divider} /> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreDropDown;
