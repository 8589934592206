import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { SettingsSidebarRoutes } from '../../components/routes/SettingsSidebarRoutes';
import { usePermissions } from '../usePermissions';

export const useSettingsSidebar = () => {
  const { hasPermissions } = usePermissions();

  const sidebarPaths = useMemo(
    () =>
      SettingsSidebarRoutes.filter((route) =>
        route.elements.some((element) => hasPermissions(element.permissions)),
      ).map((sidebarElement) => sidebarElement.path),
    [hasPermissions],
  );

  const { pathname: currentPath } = useLocation();

  const defaultIndex = useMemo(() => {
    const index = sidebarPaths.findIndex((path) => currentPath.includes(`/settings/${path}`));

    if (index <= 0) {
      return '0';
    }

    return index.toString();
  }, [currentPath, sidebarPaths]);

  const [index, setIndex] = useState(defaultIndex);

  return {
    index,
    setIndex,
    currentPath,
  };
};
