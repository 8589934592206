import i18n, * as i18nResources from '../config/i18n';

type TLanguage = 'en' | 'nl';
type TLanguageDisplayTranslations = keyof Pick<
  typeof i18nResources.resources.en.translation,
  'profile.dropdown.languages.english' | 'profile.dropdown.languages.dutch'
>;

export const useLanguageSwitch = () => {
  const switchLanguage = (language: TLanguage) => {
    // * Language is already set, do nothing
    if (i18n.language === language) return;
    i18nResources.switchLanguage(language);
  };

  const languageSwitchers: Array<{ language: TLanguage; displayText: TLanguageDisplayTranslations }> = [
    {
      language: 'en',
      displayText: 'profile.dropdown.languages.english',
    },
    {
      language: 'nl',
      displayText: 'profile.dropdown.languages.dutch',
    },
  ];

  return {
    switchLanguage,
    languageSwitchers,
  };
};
