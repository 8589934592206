import { useCallback } from 'react';
import { getInboxesApi, createInboxApi, deleteInboxApi, updateInboxApi } from '../../../api/inboxApi';
import { Actions } from '../../../constants/actionTypes';
import { toastErrorTranslated } from '../../../helpers/toast';
import { useMainDispatch } from '../../useMainDispatch';

export const useInboxActions = () => {
  const dispatch = useMainDispatch();

  const fetchInboxes = useCallback(async () => {
    try {
      const { data: inboxes } = await getInboxesApi();
      dispatch({ type: Actions.SET_SETTINGS, payload: { inboxes } });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  const createInbox = useCallback(
    async (inbox: any) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await createInboxApi(inbox);
        // TODO do not refetch
        await fetchInboxes();
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchInboxes],
  );

  const editInbox = useCallback(
    async (inboxId: string, inboxData: any) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await updateInboxApi(inboxId, inboxData);
        // TODO do not refetch
        await fetchInboxes();
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchInboxes],
  );

  const deleteInbox = useCallback(
    async (inboxId: string) => {
      try {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
        await deleteInboxApi(inboxId);
        // TODO do not refetch
        await fetchInboxes();
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
      }
    },
    [dispatch, fetchInboxes],
  );

  return {
    createInbox,
    editInbox,
    deleteInbox,
    fetchInboxes,
  };
};
