import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useInboxes } from '../../hooks/settings/inboxes/useInboxes';
import { useInboxesInit } from '../../hooks/settings/inboxes/useInboxesInit';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import { usePermissions } from '../../hooks/usePermissions';

export const InboxSettings: React.FC = () => {
  useInboxesInit();
  const { inboxes, setSelectedInbox } = useInboxes();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  return (
    <>
      <ContentTitle title={t('inbox-settings.title')} onClickInfo={() => showModal('inbox-info')} />
      {inboxes?.length ? (
        <>
          {hasPermissions([PERMISSIONS.INBOX.INBOX_CREATE]) && (
            <AddButton text={t('inbox-settings.add-inbox')} onClickAdd={() => showModal('add-inbox')} />
          )}
          <SettingsCardContainer>
            {inboxes.map((inbox) => {
              const removeAction = hasPermissions([PERMISSIONS.INBOX.INBOX_REMOVE])
                ? [
                    {
                      icon: 'delete',
                      action: () => {
                        showModal('delete-inbox');
                        setSelectedInbox(inbox);
                      },
                    },
                  ]
                : [];
              const editAction = hasPermissions([PERMISSIONS.INBOX.INBOX_EDIT])
                ? [
                    {
                      icon: 'border_color',
                      action: () => {
                        showModal('edit-inbox');
                        setSelectedInbox(inbox);
                      },
                    },
                  ]
                : [];

              return (
                <SettingsCard
                  key={inbox.identifier}
                  title={inbox.name}
                  subtitle={inbox.skills.join(', ')}
                  actions={[...editAction, ...removeAction]}
                />
              );
            })}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState message={t('inbox-settings.empty-state')} addButton={() => showModal('add-inbox')} />
      )}
    </>
  );
};
export default InboxSettings;
