import { useMemo } from 'react';
import { Actions } from '../../constants/actionTypes';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';

export const useLinks = () => {
  const {
    settings: { links, selectedLinkId },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const setSelectedLink = (shortLink: string) => {
    dispatch({ type: Actions.SET_SETTINGS, payload: { selectedLinkId: shortLink } });
  };

  const selectedLink = useMemo(() => links.find((link) => link.shortLink === selectedLinkId), [selectedLinkId, links]);

  return { links, selectedLink, setSelectedLink };
};
