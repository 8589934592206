import { DataKeys } from '../constants/login';
import { IS_MOBILE } from '../hooks/useMedia';
import { EFlowDirection } from '../interfaces/EFlowDirection';
import { TLoginInner } from '../interfaces/redux/TLoginState';
import { TReduxState } from '../interfaces/TReduxState';

// TODO make abstraction for local storage
const getInitialAccessToken = () => localStorage.getItem(DataKeys.ACCESS_TOKEN_DATA_KEY) || undefined;
const getInitialRefreshToken = () => localStorage.getItem(DataKeys.REFRESH_TOKEN_DATA_KEY) || undefined;
const getInitialLoggedIn = () => !!getInitialAccessToken() && !!getInitialRefreshToken();
const sidebarOpenInitialState = !IS_MOBILE && localStorage.getItem(DataKeys.SIDEBAR_OPEN) === 'false' ? false : true;

const getInitialLoginCredentials = (): TLoginInner => ({
  isLoggedIn: getInitialLoggedIn(),
  refreshToken: getInitialRefreshToken(),
  accessToken: getInitialAccessToken(),
});

export const userDefaults = {
  currentUser: undefined,
  email: '',
  password: '',
  permissions: undefined,
};

export const initialState: TReduxState = {
  user: userDefaults,
  ui: {
    profileDropdownOpen: false,
    sideBarOpen: sidebarOpenInitialState,
    isLoading: false,
    activeModal: undefined,
  },
  login: getInitialLoginCredentials(),
  chat: {
    selectedConversation: undefined,
    typingIndicator: false,
    chatInputMessage: '',
    cannedResponses: [],
    cannedResponsesIndex: 0,
    isCannedResponsesMenuOpen: false,
    chatList: [],
    inboxes: undefined,
  },
  settings: {
    agents: [],
    selectedAgent: undefined,
    inboxes: [],
    selectedInbox: undefined,
    selectedCannedResponse: undefined,
    labels: [],
    selectedLabel: undefined,
    webclient: { configs: [] },
    facebookPages: [],
    roles: [],
    links: [],
  },
  designer: {
    selectedBlock: undefined,
    blocksSearchString: '',
    blocksSearchMatchIndex: 0,
    flowDirection: EFlowDirection.Vertical,
    newBlock: undefined,
    entryPoints: [],
    blocks: undefined,
    blockUpdates: {
      created: [],
      updated: [],
      deleted: [],
    },
  },
  colors: {
    default: '#aaaaaa',
  },
};
