import React from 'react';
import style from '../../assets/scss/components/pages/MainPage.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useAlertUserBeforeLeave } from '../../hooks/useAlertUserBeforeLeave';
import { DesignerContent } from '../designer/DesignerContent';

export const DesignerPage: React.FC = () => {
  const { wrapper } = convertKeysToCamelCase(style);
  useAlertUserBeforeLeave();

  return (
    <div className={wrapper}>
      <DesignerContent />
    </div>
  );
};
