import { useCallback } from 'react';
import { Actions } from '../constants/actionTypes';
import { useMainDispatch } from './useMainDispatch';
import { useMainSelector } from './useMainSelector';
import { DataKeys } from '../constants/login';

export const useSidebar = () => {
  const {
    ui: { sideBarOpen },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const toggleSideBar = useCallback(() => {
    localStorage.setItem(DataKeys.SIDEBAR_OPEN, (!sideBarOpen).toString());
    dispatch({
      type: Actions.SET_UI,
      payload: { sideBarOpen: !sideBarOpen },
    });
  }, [dispatch, sideBarOpen]);

  return { sideBarOpen, toggleSideBar };
};
