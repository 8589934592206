import { useCallback, useMemo } from 'react';
import { assignInboxToConversation } from '../../api/chatApi';
import { toastErrorTranslated } from '../../helpers/toast';
import { TChatInbox } from '../../interfaces/TChat';
import { useMainSelector } from '../useMainSelector';
import { useSelectedInbox } from './useSelectedInbox';

export const useChatInboxes = () => {
  const {
    chat: { inboxes, selectedConversation },
  } = useMainSelector();

  const { selectedInboxId, selectInboxId } = useSelectedInbox();

  const filteredInboxes = useMemo(() => inboxes?.filter((inbox) => inbox.isAgentAssigned) ?? [], [inboxes]);

  const assignUserToInbox = useCallback(
    async (inbox: TChatInbox) => {
      try {
        if (selectedConversation) {
          await assignInboxToConversation(selectedConversation.id, inbox.identifier);
        }
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [selectedConversation],
  );

  const selectedInbox = useMemo(() => {
    return inboxes?.find((inbox) => inbox.identifier === selectedInboxId);
  }, [inboxes, selectedInboxId]);

  return {
    inboxes,
    filteredInboxes,
    selectedInbox,
    selectInboxId,
    assignUserToInbox,
  };
};
