import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  retry: () => void;
}

export const FacebookErrorModal: React.FC<Props> = ({ retry }) => {
  const { t } = useTranslation();
  return (
    <Modal.Body>
      <p>{t('settings.facebook.error.title')}</p>
      <br />
      <p>{t('settings.facebook.error.body')}</p>
      <br />
      <strong>{t('settings.facebook.error.try-again')}</strong>
      <Modal.Footer>
        <Button type="submit" onClick={() => retry()}>
          {t('settings.facebook.error.buttons.retry')}
        </Button>
      </Modal.Footer>
    </Modal.Body>
  );
};

export default FacebookErrorModal;
