import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';
import {
  CHAT_PAGE_ROUTE,
  SETTINGS_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  DESIGNER_PAGE_ROUTE,
  ACCOUNT_PAGE_ROUTE,
  REPORTS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from '../../constants/routes';
import { ChatPage } from '../pages/chat/desktop/ChatPage';
import { SettingsPage } from '../pages/SettingsPage';
import { DesignerPage } from '../pages/DesignerPage';
import { IRoute } from '../../interfaces/IRoutes';
import { LogoutPage } from '../pages/LogoutPage';
import { ChatListPage } from '../pages/chat/mobile/ChatListPage';
import { ChatMessagesPage } from '../pages/chat/mobile/ChatMessagesPage';
import { ChatInfoPage } from '../pages/chat/mobile/ChatInfoPage';
import { AccountPage } from '../pages/AccountPage';
import { PrivacyPolicyPage } from '../pages/PrivacyPolicyPage';
import { UnderConstructionPage } from '../pages/UnderConstructionPage';
import { TermsAndConditionsPage } from '../pages/TermsAndConditionsPage';

export const AuthenticatedAppRoutes = (isMobile: boolean): IRoute[] => [
  {
    path: LOGOUT_PAGE_ROUTE,
    component: LogoutPage,
    exact: true,
  },
  {
    path: DESIGNER_PAGE_ROUTE,
    component: DesignerPage,
    exact: true,
  },
  {
    path: REPORTS_PAGE_ROUTE,
    component: UnderConstructionPage,
    exact: true,
  },
  {
    path: DASHBOARD_PAGE_ROUTE,
    component: UnderConstructionPage,
    exact: true,
  },
  {
    path: PRIVACY_POLICY_ROUTE,
    component: PrivacyPolicyPage,
    exact: true,
  },
  {
    path: TERMS_AND_CONDITIONS_ROUTE,
    component: TermsAndConditionsPage,
    exact: true,
  },
  ...(isMobile ? mobileOnlyRoutes : desktopOnlyRoutes),
];

const mobileOnlyRoutes: IRoute[] = [
  {
    path: `${CHAT_PAGE_ROUTE}`,
    component: ChatListPage,
    exact: true,
    type: 'restricted',
    permissions: [PERMISSIONS.CONVERSATION.CONVERSATION_MANAGE],
  },
  {
    path: `${CHAT_PAGE_ROUTE}/:id/info`,
    component: ChatInfoPage,
    exact: true,
    type: 'restricted',
    permissions: [PERMISSIONS.CONVERSATION.CONVERSATION_MANAGE],
  },
  {
    path: `${CHAT_PAGE_ROUTE}/:id`,
    component: ChatMessagesPage,
    exact: true,
    type: 'restricted',
    permissions: [PERMISSIONS.CONVERSATION.CONVERSATION_MANAGE],
  },
  {
    path: SETTINGS_PAGE_ROUTE,
    component: SettingsPage,
    exact: true,
  },
  {
    path: ACCOUNT_PAGE_ROUTE,
    component: AccountPage,
    exact: true,
  },
];

const desktopOnlyRoutes: IRoute[] = [
  {
    path: `${CHAT_PAGE_ROUTE}/:id?`,
    component: ChatPage,
    type: 'restricted',
    permissions: [PERMISSIONS.CONVERSATION.CONVERSATION_MANAGE],
  },
  {
    path: SETTINGS_PAGE_ROUTE,
    component: SettingsPage,
    exact: false,
  },
  {
    path: ACCOUNT_PAGE_ROUTE,
    component: AccountPage,
    exact: true,
  },
];
