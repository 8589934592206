import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const FacebookInfoModal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Modal.Body>
      <p>{t('settings.facebook.info')}</p>
    </Modal.Body>
  );
};

export default FacebookInfoModal;
