import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import { Table } from 'react-bootstrap';
import MaterialIcon from '../../common/MaterialIcon';
import MemberGrantedColumn from './MemberGrantedColumn';
import MemberAccountColumn from './MemberAccountColumn';
import MemberRoleColumn from './MemberRoleColumn';
import { useTranslation } from 'react-i18next';
import { useMembersTable } from '../../../hooks/settings/company/useMembersTable';

export const CompanyMembersTable: React.FC = () => {
  const { members, assignableRoles, toggleRoleForMember, removeMember } = useMembersTable();

  const { t } = useTranslation();

  const { sectionCategory, table, cvDeleteCell, cvDeleteHead, cvMemberRoles } = convertKeysToCamelCase(style);

  return (
    <div className={`${sectionCategory} ${table}`}>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <p>{t('members-settings.table.header.account')}</p>
            </th>
            <th>
              <p>{t('members-settings.table.header.granted')}</p>
            </th>
            <th>
              <p>{t('members-settings.table.header.roles')}</p>
            </th>
            <th className={cvDeleteHead}></th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.email}>
              <td>
                <MemberAccountColumn
                  email={member.email}
                  profilePic={member.profilePic}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  mfaEnabled={member.mfaEnabled}
                  isCurrentUser={member.isCurrentUser}
                />
              </td>
              <td>
                <MemberGrantedColumn grantedAt={member.grantedAt} grantedBy={member.grantedBy} />
              </td>
              <td className={cvMemberRoles}>
                <MemberRoleColumn
                  currentRoles={member.roles}
                  availableRoles={assignableRoles}
                  toggleRoleForMember={(roleId) => toggleRoleForMember(member, roleId)}
                  disabled={member.isCurrentUser}
                />
              </td>
              <td className={cvDeleteCell}>
                {!member.isCurrentUser && (
                  <MaterialIcon role="button" icon="delete" onClick={() => removeMember(member.id)} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CompanyMembersTable;
