import React from 'react';
import { ChatPanelHeader } from '../../../chat/chat-panel-header/ChatPanelHeader';
import chatAvatar from '../../../../assets/images/chat_avatar.png';
import { ChatPanelContent } from '../../../chat/chat-panel/ChatPanelContent';
import { useChatMessagesPage } from '../../../../hooks/chat/useChatMessagesPage';
import { useFileMedia } from '../../../../hooks/useFileMedia';

export const ChatMessagesPage: React.FC = () => {
  const { selectedConversation } = useChatMessagesPage();
  const { getImageByURL } = useFileMedia();

  return selectedConversation ? (
    <div className="d-flex flex-row flex-grow-1">
      <ChatPanelHeader
        name={selectedConversation.name}
        imageUrl={getImageByURL(selectedConversation.imageUrl ?? chatAvatar)}
        id={selectedConversation.id}
      />
      <ChatPanelContent />
    </div>
  ) : null;
};
