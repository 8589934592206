import React from 'react';
import { Dropdown } from 'react-bootstrap';
import style from '../../../assets/scss/components/common/DropDown.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useChatInboxes } from '../../../hooks/chat/useChatInboxes';
import { useTranslation } from 'react-i18next';

export const InboxDropDown: React.FC = () => {
  const { inboxes, assignUserToInbox } = useChatInboxes();
  const { t } = useTranslation();
  const { cv, dropdown, toggler, item, menu, custom, inbox, divider } = convertKeysToCamelCase(style);

  return (
    <Dropdown className={`${cv} ${dropdown} ${custom} ${inbox}`}>
      <Dropdown.Toggle className={`${cv} ${toggler} ${custom} ${inbox}`}>
        <p>{t('chat.panel.header.inbox-dropdown.button')}</p>
      </Dropdown.Toggle>

      <Dropdown.Menu className={`${cv} ${menu} ${custom}`}>
        <Dropdown.Item eventKey="4" className={`${cv} ${item} ${custom}`}>
          <p>{t('chat.panel.header.inbox-dropdown.title')}</p>
        </Dropdown.Item>
        <Dropdown.Divider className={`${cv} ${divider} ${custom}`} />
        {inboxes &&
          inboxes.map((inbox) => {
            return (
              <Dropdown.Item
                key={inbox.identifier}
                className={`${cv} ${item} ${custom}`}
                onClick={() => assignUserToInbox(inbox)}
              >
                <p>{inbox.name}</p>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InboxDropDown;
