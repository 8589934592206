import React from 'react';
import ContentTitle from './common/ContentTitle';
import AddButton from '../common/AddButton';
import SettingsCard from './common/card/SettingsCard';
import { useModal } from '../../hooks/modal/useModal';
import { useAgents } from '../../hooks/settings/agents/useAgents';

import chatAvatar from '../../assets/images/chat_avatar.png';

import EmptyState from '../common/EmptyState';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import { useAgentsInit } from '../../hooks/settings/agents/useAgentsInit';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const AgentSettings: React.FC = () => {
  useAgentsInit();
  const { agents, setSelectedAgent } = useAgents();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  return (
    <>
      <ContentTitle title={t('agent-settings.title')} onClickInfo={() => showModal('agent-info')} />
      {agents?.length ? (
        <>
          {hasPermissions([PERMISSIONS.AGENT.AGENT_CREATE]) && (
            <AddButton text={t('agent-settings.add-agent')} onClickAdd={() => showModal('add-agent')} />
          )}
          <SettingsCardContainer>
            {agents.map((agent) => {
              const title =
                agent.firstName || agent.lastName
                  ? `${agent.firstName || ''} ${agent.lastName || ''}`
                  : agent.email || '';

              const removeAction = hasPermissions([PERMISSIONS.AGENT.AGENT_REMOVE])
                ? [
                    {
                      icon: 'delete',
                      action: () => {
                        showModal('delete-agent');
                        setSelectedAgent(agent);
                      },
                    },
                  ]
                : [];

              const editAction = hasPermissions([PERMISSIONS.AGENT.AGENT_EDIT])
                ? [
                    {
                      icon: 'border_color',
                      action: () => {
                        showModal('edit-agent');
                        setSelectedAgent(agent);
                      },
                    },
                  ]
                : [];

              return (
                <SettingsCard
                  key={agent.email}
                  title={title}
                  subtitle={agent.skills?.join(', ') || ''}
                  avatar={agent?.profilePic || chatAvatar}
                  actions={[...editAction, ...removeAction]}
                />
              );
            })}
          </SettingsCardContainer>
        </>
      ) : (
        <EmptyState message={t('agent-settings.empty-state')} addButton={() => showModal('add-agent')} />
      )}
    </>
  );
};
export default AgentSettings;
