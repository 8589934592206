import { useMainSelector } from '../../useMainSelector';
import { useMainDispatch } from '../../useMainDispatch';
import { Actions } from '../../../constants/actionTypes';
import { useMemo } from 'react';

export const useFacebookPages = () => {
  const {
    settings: { facebookPages, selectedFacebookPageId, facebookPageSettings },
  } = useMainSelector();

  const dispatch = useMainDispatch();

  const setSelectedFacebookPage = (selectedFacebookPageId: string) => {
    dispatch({ type: Actions.SET_SETTINGS, payload: { selectedFacebookPageId } });
  };

  const selectedFacebookPage = useMemo(
    () => facebookPages.find((page) => page.id === selectedFacebookPageId),
    [facebookPages, selectedFacebookPageId],
  );

  return { facebookPages, setSelectedFacebookPage, selectedFacebookPage, facebookPageSettings };
};
