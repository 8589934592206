import React from 'react';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';

interface Props {
  title: string;
  children: any;
}

export const WebclientInputGroup: React.FC<Props> = ({ children, title }) => {
  const { modalSection, sectionTitle } = convertKeysToCamelCase(style);
  return (
    <div className={`${modalSection}`}>
      <h5 className={`${sectionTitle}`}>{title}</h5>
      {children}
    </div>
  );
};

export default WebclientInputGroup;
