import style from '../../../assets/scss/components/sidebar/AppSidebar.module.scss';
import { Link } from 'react-router-dom';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { ISideBarItem } from '../../../interfaces/ISidebar';
import { MaterialIcon } from '../../common/MaterialIcon';
import { useSidebar } from '../../../hooks/useSidebar';
import { EBreakpoint } from '../../../constants/breakpoints';
import { useMedia } from '../../../hooks/useMedia';
import { useTranslation } from 'react-i18next';

export const SidebarItem: React.FC<ISideBarItem> = ({ to, text, icon }) => {
  const { sidebarItem, wrapper } = convertKeysToCamelCase(style);
  const { toggleSideBar } = useSidebar();
  const { t } = useTranslation();
  const { down: isMobile } = useMedia(EBreakpoint.MD);

  return (
    <Link
      to={to}
      onClick={() => {
        isMobile && toggleSideBar();
      }}
    >
      <div className={sidebarItem}>
        <div className={wrapper}>
          <MaterialIcon icon={icon} classes={['icon']} />
          <h5>{t(text)}</h5>
        </div>
      </div>
    </Link>
  );
};

export default SidebarItem;
