import React from 'react';
import style from '../../assets/scss/components/pages/EmptyPage.module.scss';
import underConstruction from '../../assets/images/under-construction.svg';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useTranslation } from 'react-i18next';

export const UnderConstructionPage: React.FC = () => {
  const { emptyPageWrapper, message } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={emptyPageWrapper}>
      <img src={underConstruction} />
      <div className={message}>
        <span>{t('under-construction-settings.state')}</span>
      </div>
    </div>
  );
};

export default UnderConstructionPage;
