import { ICarouselElements } from '@conversed/shared/src/central/types/chat.types';
import styles from '../../../../assets/scss/components/chat/chat-content/messages/ImageCarouselMessage.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import React from 'react';
import Slider from 'react-slick';

interface IImageCarouselMessageProps {
  messageLocation: string;
  elements: ICarouselElements[];
}

const getCarouselElements = (elements: ICarouselElements[]) => {
  const { carouselElement, carouselPillar, pillar } = convertKeysToCamelCase(styles);

  if (!elements) {
    return [];
  }

  return elements.map((element, index) => {
    return (
      <div className={carouselElement} key={index}>
        {element?.image && <img src={element.image}></img>}
        {element?.title && <p>{element.title}</p>}
        {element?.subtitle && <p>{element.subtitle}</p>}
        {element?.buttons && (
          <div className={carouselPillar}>
            {element.buttons?.map((button, index) => {
              return (
                <div className={pillar} key={index}>
                  <p>{button}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  });
};

export const ImageCarouselMessage: React.FC<IImageCarouselMessageProps> = ({ messageLocation, elements }) => {
  const { messageOut, messageIn } = convertKeysToCamelCase(styles);
  const messageClass = messageLocation === 'out' ? messageOut : messageIn;

  // TODO: Optimize these settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const carouselElements = getCarouselElements(elements);

  return (
    <div className={messageClass}>
      <Slider {...settings}>{carouselElements}</Slider>
    </div>
  );
};

export default ImageCarouselMessage;
