import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/SettingsSidebar.module.scss';
import { Accordion, ListGroup } from 'react-bootstrap';
import MaterialIcon from '../../common/MaterialIcon';
import { TSettingsSidebarElement } from '../../../interfaces/ISettings';
import { useLocation } from 'react-router';
import { SETTINGS_PAGE_ROUTE } from '../../../constants/routes';
import { usePermissions } from '../../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ISidebarElementProps extends TSettingsSidebarElement {
  index: string;
  selectedIndex: string;
  setSelectedIndex: (index: string) => void;
}

export const SidebarElement: React.FC<ISidebarElementProps> = ({
  index,
  selectedIndex,
  setSelectedIndex,
  icon,
  title,
  elements,
  path: mainMenuPath,
}) => {
  const {
    sidebarElement,
    accordionTitle,
    listWrapper,
    cvAccordionBody,
    upArrow,
    downArrow,
    active,
    accordionHeaderWrapper,
  } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { pathname: currentPath } = useLocation();
  const { hasPermissions } = usePermissions();

  const accordionTitleStyle = selectedIndex === index ? downArrow : upArrow;

  return (
    <Accordion.Item eventKey={index} className={sidebarElement}>
      <Accordion.Header
        className={`${accordionTitle} ${accordionTitleStyle}`}
        onClick={() => {
          const newIndex = selectedIndex !== index ? index : '';
          return setSelectedIndex(newIndex);
        }}
      >
        <div className={accordionHeaderWrapper}>
          <MaterialIcon icon={icon} /> <p>{t(title)}</p>
        </div>
        <MaterialIcon icon={'arrow_drop_down'} />
      </Accordion.Header>
      <Accordion.Body className={cvAccordionBody}>
        <ListGroup className={listWrapper}>
          {elements
            .filter((element) => hasPermissions(element.permissions))
            .map(({ text, path }) => {
              const subMenuPath = `${SETTINGS_PAGE_ROUTE}/${mainMenuPath}/${path}`;
              return (
                <ListGroup.Item key={subMenuPath} className={currentPath === subMenuPath ? active : ''}>
                  <Link to={subMenuPath}>{t(text)}</Link>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SidebarElement;
