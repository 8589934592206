import { useCallback } from 'react';
import { CompanyMemberDTO } from '@conversed/shared/src/central/dto/company';
import useRole from '../roles/useRole';
import { useCompanySettingsActions } from './useCompanyActions';
import { useCompanySettings } from './useCompanySettings';

export const useMembersTable = () => {
  const { members } = useCompanySettings();
  const { removeMember } = useCompanySettingsActions();
  const { assignableRoles } = useRole();

  const { addMember } = useCompanySettingsActions();

  const toggleRoleForMember = useCallback(
    (member: CompanyMemberDTO, roleId: string) => {
      const { roles: memberRoles } = member;
      const newRoles = memberRoles.includes(roleId)
        ? memberRoles.filter((role) => role !== roleId)
        : [...memberRoles, roleId];

      addMember({
        email: member.email,
        roles: newRoles,
      });
    },
    [addMember],
  );

  return { members, assignableRoles, toggleRoleForMember, removeMember };
};
