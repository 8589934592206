import React from 'react';
import { Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWebclientSnippet } from '../../../../hooks/settings/webclients/useWebclientSnippet';
import style from '../../../../assets/scss/components/WebClientSnippetModal.module.scss';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import MaterialIcon from '../../../common/MaterialIcon';
import { EWebclientSnippet } from '../../../../constants/EWebclientSnippet';

export const WebclientSnippetModal: React.FC = () => {
  const { copySnippetToClipboard, snippetContent, snippetType, setSnippetType } = useWebclientSnippet();
  const { t } = useTranslation();

  const { cvButton, active, passive, toggle, clipboard, copy } = convertKeysToCamelCase(style);

  return (
    <Modal.Body className={`d-flex flex-column`}>
      <div className="d-flex justify-content-between mb-2">
        <div className={`${cvButton} ${clipboard} ${copy}`} role="button" onClick={copySnippetToClipboard}>
          {t('general.buttons.copy')}
          <MaterialIcon icon={'content_copy'} />
        </div>
        <ToggleButtonGroup type="radio" name="snippet" value={snippetType} onChange={setSnippetType}>
          <ToggleButton
            id="tbg-btn-1"
            value={EWebclientSnippet.SIMPLE}
            className={`${cvButton} ${toggle} ${snippetType === EWebclientSnippet.SIMPLE ? active : passive}`}
          >
            {t('settings.webclient.snippet.simple')}
          </ToggleButton>
          <ToggleButton
            id="tbg-btn-2"
            value={EWebclientSnippet.ADVANCED}
            className={`${cvButton} ${toggle} ${snippetType === EWebclientSnippet.ADVANCED ? active : passive}`}
          >
            {t('settings.webclient.snippet.advanced')}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <pre className="container bg-light">{snippetContent}</pre>
    </Modal.Body>
  );
};

export default WebclientSnippetModal;
