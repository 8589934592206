import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { GeneralTooltip } from '../../common/GeneralTooltip';
import { useBlocksActions } from '../../../hooks/flow/block/useBlocksActions';
import { useTranslation } from 'react-i18next';

export const SaveLayoutButton: React.FC = () => {
  const { saveFlow } = useBlocksActions();
  const { t } = useTranslation();
  const { button } = convertKeysToCamelCase(style);

  return (
    <GeneralTooltip id={'tooltip-flow-layout-save-button'} text={t('flow.blocks.save.button')}>
      <div className={button} onClick={() => saveFlow()}>
        <MaterialIcon icon={'save'} />
      </div>
    </GeneralTooltip>
  );
};
