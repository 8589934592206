import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLinks } from '../../../../hooks/inboxes/useLinks';

export const LinkStatisticsModal: React.FC = () => {
  const { selectedLink } = useLinks();

  const { t } = useTranslation();

  return (
    <Modal.Body>
      <Row>
        <Col>
          <strong>{t('settings.sidebar.tools.links.total-clicks-count')}</strong>
        </Col>
        <Col>
          <p>{selectedLink?.totalClicksCount}</p>
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default LinkStatisticsModal;
