import { useCallback, useEffect, useState } from 'react';
import { EApiStatus } from '../../../interfaces/EApiStatus';
import useRoleActions from './useRoleActions';

export const useRoleInit = () => {
  const { getRoles } = useRoleActions();
  const [status, setStatus] = useState<EApiStatus>(EApiStatus.LOADING);

  const getRolesWithStatus = useCallback(async () => {
    const result = await getRoles();
    setStatus(result);
  }, [getRoles]);

  useEffect(() => {
    getRolesWithStatus();
  }, [getRolesWithStatus]);

  return {
    error: EApiStatus.ERROR === status,
    loading: EApiStatus.LOADING === status,
  };
};

export default useRoleInit;
