import { useMainSelector } from '../useMainSelector';
import { ModalType } from '../../interfaces/redux/TUIState';
import { useMainDispatch } from '../useMainDispatch';
import { Actions } from '../../constants/actionTypes';
import { useCallback } from 'react';

export const useModal = () => {
  const {
    ui: { activeModal },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const showModal = useCallback(
    (type: ModalType, title?: string) => {
      dispatch({
        type: Actions.SET_UI,
        payload: {
          activeModal: {
            type,
            title,
          },
        },
      });
    },
    [dispatch],
  );

  const hideModal = useCallback(() => {
    dispatch({ type: Actions.SET_UI, payload: { activeModal: undefined } });
  }, [dispatch]);

  return {
    hideModal,
    type: activeModal?.type,
    title: activeModal?.title,
    showModal,
  };
};
