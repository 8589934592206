import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/designer/tools/CustomControls.module.scss';
import { CommitButton } from './CommitButton';
import { FlipLayoutButton } from './FlipLayoutButton';
import { DownloadBlocksButton } from './DownloadBlocksButton';
import { SaveLayoutButton } from './SaveLayoutButton';
import { FullscreenButton } from './FullscreenButton';

const ControlContainer: React.FC = ({ children }) => {
  const { container } = convertKeysToCamelCase(style);
  return <div className={container}>{children}</div>;
};

export const CustomControls: React.FC = () => {
  return (
    <ControlContainer>
      <CommitButton />
      <FlipLayoutButton />
      <DownloadBlocksButton />
      <SaveLayoutButton />
      <FullscreenButton />
    </ControlContainer>
  );
};
