import React from 'react';
import { Form } from 'react-bootstrap';
import { IDropdownInputProps } from '../../interfaces/IInputProps';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/common/Modal.module.scss';

export const DropDownInput: React.FC<IDropdownInputProps> = ({
  isEnabled = true,
  name,
  register,
  values,
  label,
  horizontal,
}) => {
  const { modalLabel, inputField } = convertKeysToCamelCase(style);

  return horizontal ? (
    <Form.Group>
      <Form.Label className={modalLabel}>{label}</Form.Label>
      <Form.Select disabled={!isEnabled} {...register(name)} className={inputField}>
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  ) : (
    <Form.Group>
      <Form.Label className={modalLabel}>{label}</Form.Label>
      <Form.Select disabled={!isEnabled} className={inputField} {...register(name)}>
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default DropDownInput;
