import { useEffect } from 'react';
import { useBlocksActions } from '../../flow/block/useBlocksActions';
import { useCompanySelector } from '../../useCompanySelector';
import { useWebclientActions } from './useWebclientActions';

export const useWebclientInit = () => {
  const { fetchWebclientConfigs } = useWebclientActions();
  const { fetchFlowEntryPoints } = useBlocksActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchWebclientConfigs();
    fetchFlowEntryPoints();
  }, [fetchFlowEntryPoints, fetchWebclientConfigs, selectedCompany]);
};
