import React from 'react';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { ChatContent } from '../chat-content/ChatContent';
import { ChatInput } from '../chat-input/ChatInput';
import style from '../../../assets/scss/components/chat/ChatPanel.module.scss';

export const ChatPanelContent: React.FC = () => {
  const { cvChatWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={`d-flex flex-column bg-light flex-grow-1 ${cvChatWrapper}`}>
      <ChatContent />
      <ChatInput />
    </div>
  );
};
