import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useForgotPasswordForm } from '../../hooks/auth/useForgotPasswordForm';
import { AuthInput } from './AuthInput';
import { HoverText } from '../common/HoverText';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { useTranslation } from 'react-i18next';
import { EButtonType, GenericButton } from '../common/Button';
import { LOGIN_PAGE_ROUTE } from '../../constants/routes';
import { useHistory } from 'react-router';

export const ForgotPasswordForm: React.FC = () => {
  const { submitForgotPassword, registerInput, validationErrors } = useForgotPasswordForm();
  const { t } = useTranslation();

  const history = useHistory();
  const switchToLogin = () => history.push(LOGIN_PAGE_ROUTE);

  const { loginForm, loginFormFrame, loginFormFrameButtonsOptionsForgot, passwordInstructions } =
    convertKeysToCamelCase(style);

  return (
    <>
      <form className={loginForm} onSubmit={submitForgotPassword}>
        <p className={passwordInstructions}>{t('auth.forgot-password.instructions')}</p>
        <AuthInput
          title="auth.input.email"
          name="email"
          errorMessage={validationErrors?.email?.message}
          registerInput={registerInput}
        />
        <div className={loginFormFrame}>
          <GenericButton
            buttonType={EButtonType.PRIMARY}
            text={t('auth.forgot-password.buttons-send-email')}
            type="submit"
          />
        </div>
      </form>
      <HoverText className={loginFormFrameButtonsOptionsForgot} text={t('auth.login.back')} onClick={switchToLogin} />
    </>
  );
};
