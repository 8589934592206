import { CompanyMemberDTO } from '@conversed/shared/src/central/dto/company';
import { TBlocksConfigWithPositions } from '@conversed/shared/src/central/types/block.type';
import { TRole } from '@conversed/shared/src/central/types/role.types';
import {
  addValueToList,
  replaceValueInList,
  removeValueFromList,
  pushUniques,
  removeObjectValuesFromList,
  replaceValuesInList,
} from '@conversed/shared/src/helpers/data.helpers';
import { Actions } from '../../constants/actionTypes';
import { TCurrentUser } from '../../interfaces/IAuth';
import { TChatHistory } from '../../interfaces/IMessage';
import { TAuthorization } from '../../interfaces/redux/TAuthState';
import { TChatStateInner } from '../../interfaces/redux/TChatState';
import { TColorStateInner } from '../../interfaces/redux/TColorState';
import { TDesignerStateInner } from '../../interfaces/redux/TDesignerState';
import { TLoginInner } from '../../interfaces/redux/TLoginState';
import { TSettingsStateInner, TCompanySettingsState, TLink } from '../../interfaces/redux/TSettingsState';
import { TUIStateInner } from '../../interfaces/redux/TUIState';
import { TUserStateInner } from '../../interfaces/redux/TUserState';
import { TAssignee, TConversation } from '../../interfaces/TChat';
import { TReduxHandler, TReduxState } from '../../interfaces/TReduxState';

const setLogIn = (state: TReduxState, payload: Partial<TLoginInner>): TReduxState => {
  return {
    ...state,
    login: { ...state.login, ...payload },
  };
};

const setChatState = (state: TReduxState, payload: Partial<TChatStateInner>): TReduxState => {
  return {
    ...state,
    chat: { ...state.chat, ...payload },
  };
};

const setUIState = (state: TReduxState, payload: Partial<TUIStateInner>): TReduxState => {
  return {
    ...state,
    ui: { ...state.ui, ...payload },
  };
};

const setUserState = (state: TReduxState, payload: Partial<TUserStateInner>): TReduxState => {
  return {
    ...state,
    user: { ...state.user, ...payload },
  };
};

const setSettingsState = (state: TReduxState, payload: Partial<TSettingsStateInner>): TReduxState => {
  return {
    ...state,
    settings: { ...state.settings, ...payload },
  };
};

const setDesignerState = (state: TReduxState, payload: Partial<TDesignerStateInner>): TReduxState => {
  return {
    ...state,
    designer: { ...state.designer, ...payload },
  };
};

const setColorState = (state: TReduxState, payload: Partial<TColorStateInner>): TReduxState => {
  return {
    ...state,
    colors: { ...state.colors, ...payload },
  };
};

const setConversationAssigneeList = (state: TReduxState, payload: TAssignee[]): TReduxState => {
  return state.chat.selectedConversation
    ? {
        ...state,
        chat: { ...state.chat, selectedConversation: { ...state.chat.selectedConversation, assigneeList: payload } },
      }
    : state;
};

const setConversationMessages = (state: TReduxState, payload: TChatHistory[]): TReduxState => {
  return state.chat.selectedConversation
    ? {
        ...state,
        chat: { ...state.chat, selectedConversation: { ...state.chat.selectedConversation, messages: payload } },
      }
    : state;
};

const setSelectedConversation = (state: TReduxState, payload: Partial<TConversation>): TReduxState => {
  return state.chat.selectedConversation
    ? {
        ...state,
        chat: { ...state.chat, selectedConversation: { ...state.chat.selectedConversation, ...payload } },
      }
    : state;
};

const setCompanySettings = (state: TReduxState, payload: Partial<TCompanySettingsState>): TReduxState => {
  return {
    ...state,
    settings: {
      ...state.settings,
      company: { ...state.settings.company, ...payload },
    },
  };
};

const addCompanyMember = (state: TReduxState, member: CompanyMemberDTO): TReduxState => {
  return {
    ...state,
    settings: {
      ...state.settings,
      company: {
        ...state.settings.company,
        members: replaceValueInList(
          member,
          state.settings.company?.members ?? [],
          (currentMember) => currentMember.id === member.id,
        ),
      },
    },
  };
};

const setAssignableRoles = (state: TReduxState, assignableRoles: TRole[]): TReduxState => {
  return {
    ...state,
    settings: {
      ...state.settings,
      company: {
        ...state.settings.company,
        assignableRoles,
      },
    },
  };
};

const changePermission = (state: TReduxState, role: TRole, permissionList: string[]) => {
  return {
    ...state,
    settings: {
      ...state.settings,
      roles: replaceValueInList(
        { ...role, permissions: permissionList },
        state.settings.roles,
        (roleToReplace) => roleToReplace.id === role.id,
      ),
    },
  };
};

const togglePermissionForRole = (state: TReduxState, authorization: TAuthorization): TReduxState => {
  const { permission, role } = authorization;
  const roleHasPermission = role.permissions.find((roleInPermission) => roleInPermission === permission);

  if (!roleHasPermission) {
    return changePermission(state, role, addValueToList(permission, role.permissions));
  }

  return changePermission(
    state,
    role,
    removeValueFromList((permissionToRemove) => permissionToRemove === permission, role.permissions),
  );
};

const setRoles = (state: TReduxState, roles: TRole[]): TReduxState => {
  return {
    ...state,
    settings: {
      ...state.settings,
      roles,
    },
  };
};

const updateCurrentUser = (state: TReduxState, userData: Partial<TCurrentUser>): TReduxState => {
  return {
    ...state,
    user: {
      ...state.user,
      currentUser: state.user.currentUser ? { ...state.user.currentUser, ...userData } : undefined,
    },
  };
};

const getCreatedBlocks = (state: TReduxState, blocks: TBlocksConfigWithPositions) => {
  const existingBlocks = state.designer.blocks ?? [];
  const currentlyCreatedBlocks = state.designer.blockUpdates.created;

  const blocksToReplace = blocks.filter((block) => {
    const blockInExistingBlocks = existingBlocks.find((existingBlock) => existingBlock.id === block.id);
    const blockInCurrentlyCreated = currentlyCreatedBlocks.find((createdBlock) => createdBlock.id === block.id);

    return !blockInExistingBlocks || blockInCurrentlyCreated;
  });

  return replaceValuesInList(blocksToReplace, currentlyCreatedBlocks, 'id');
};

const getUpdatedBlocks = (state: TReduxState, blocks: TBlocksConfigWithPositions) => {
  const existingBlocks = state.designer.blocks ?? [];
  const currentlyCreatedBlocks = state.designer.blockUpdates.created;
  const currentlyUpdatedBlocks = state.designer.blockUpdates.updated;

  const blocksToReplace = blocks.filter((block) => {
    const blockInExistingBlocks = existingBlocks.find((existingBlock) => existingBlock.id === block.id);
    const blockInCurrentlyCreated = currentlyCreatedBlocks.find((createdBlock) => createdBlock.id === block.id);

    return blockInExistingBlocks || !blockInCurrentlyCreated;
  });

  return replaceValuesInList(blocksToReplace, currentlyUpdatedBlocks, 'id');
};

const updateBlocks = (state: TReduxState, blocks: TBlocksConfigWithPositions): TReduxState => {
  return {
    ...state,
    designer: {
      ...state.designer,
      blockUpdates: {
        ...state.designer.blockUpdates,
        created: getCreatedBlocks(state, blocks),
        updated: getUpdatedBlocks(state, blocks),
      },
      blocks: replaceValuesInList(blocks, state.designer.blocks ?? [], 'id'),
    },
  };
};

const deleteBlocks = (state: TReduxState, blockIds: string[]): TReduxState => {
  return {
    ...state,
    designer: {
      ...state.designer,
      blockUpdates: {
        ...state.designer.blockUpdates,
        deleted: pushUniques(state.designer.blockUpdates.deleted, blockIds),
      },
      blocks: removeObjectValuesFromList(blockIds, state.designer.blocks ?? [], 'id'),
    },
  };
};

const clearBlockUpdates = (state: TReduxState): TReduxState => {
  return {
    ...state,
    designer: {
      ...state.designer,
      blockUpdates: {
        ...state.designer.blockUpdates,
        deleted: [],
        created: [],
        updated: [],
      },
    },
  };
};

const setLinkStatistics = (state: TReduxState, statistics: Partial<TLink>): TReduxState => {
  const links = state.settings.links.map((link) =>
    link.shortLink === statistics.shortLink ? { ...link, totalClicksCount: statistics.totalClicksCount } : link,
  );
  return {
    ...state,
    settings: {
      ...state.settings,
      links,
    },
  };
};

export const mainHandler: TReduxHandler = {
  [Actions.SET_LOGIN]: setLogIn,
  [Actions.SET_CHAT]: setChatState,
  [Actions.SET_UI]: setUIState,
  [Actions.SET_USER]: setUserState,
  [Actions.SET_SETTINGS]: setSettingsState,
  [Actions.SET_DESIGNER]: setDesignerState,
  [Actions.SET_COLOR]: setColorState,
  [Actions.SET_CONVERSATION_ASSIGNEE_LIST]: setConversationAssigneeList,
  [Actions.SET_CHAT_MESSAGES]: setConversationMessages,
  [Actions.SET_SELECTED_CONVERSATION]: setSelectedConversation,
  [Actions.SET_COMPANY_SETTINGS]: setCompanySettings,
  [Actions.ADD_COMPANY_MEMBER]: addCompanyMember,
  [Actions.TOGGLE_PERMISSION_FOR_ROLE]: togglePermissionForRole,
  [Actions.SET_ROLES]: setRoles,
  [Actions.SET_ASSIGNABLE_ROLES]: setAssignableRoles,
  [Actions.UPDATE_CURRENT_USER]: updateCurrentUser,
  [Actions.UPDATE_BLOCKS]: updateBlocks,
  [Actions.DELETE_BLOCKS]: deleteBlocks,
  [Actions.BLOCK_UPDATES_CLEAR]: clearBlockUpdates,
  [Actions.SET_LINK_STATISTICS]: setLinkStatistics,
};
