import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useFlowActions } from '../../../hooks/flow/flow/useFlowActions';
import { useModal } from '../../../hooks/modal/useModal';
import { TBlock } from '../../../interfaces/TDesigner';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: TBlock;
}

export const NodeOptions: React.FC<IProps> = ({ data }) => {
  const { setSelectedBlock } = useFlowActions();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { nodeOptions } = convertKeysToCamelCase(style);

  const block = data;

  return (
    <DropdownButton className={nodeOptions} bsPrefix={nodeOptions} title={''} id="dropdown-basic-button">
      <Dropdown.Item
        onClick={() => {
          // * Ignore non text-blocks for now
          if (!block || !['text', 'textWithButtons', 'button', 'StandAloneImage'].includes(block.type)) {
            return;
          }

          setSelectedBlock(block);
          showModal('edit-block');
        }}
      >
        {t('flow.blocks.options.edit')}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          setSelectedBlock(block);
          showModal('delete-block');
        }}
      >
        {t('flow.blocks.options.delete')}
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default NodeOptions;
