import React from 'react';
import { TAuthState } from '../../interfaces/TAuthState';
import { OTPForm } from './OTPForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginForm } from './LoginForm';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { ResetPasswordForm } from './ResetPasswordForm';
import conversedLogo from '../../assets/images/logo_black.png';
import style from '../../assets/scss/components/auth/LoginForm.module.scss';
import { useMainSelector } from '../../hooks/useMainSelector';

interface Props {
  authState: TAuthState;
}

interface IRestrictChecKParams {
  state: {
    email: string;
    password: string;
  };
  auth: TAuthState;
}

const isAuthStateOTPWithNoCredentials = ({ state, auth }: IRestrictChecKParams) => {
  const { email, password } = state;
  // Check if no credentials are present, and if auth state is 'otp'
  return (!email || !password) && auth === 'otp';
};

export const AuthComponent: React.FC<Props> = ({ authState }) => {
  const {
    user: { email, password },
  } = useMainSelector();

  const { t } = useTranslation();

  const authComponents: Record<
    TAuthState,
    {
      component: () => any;
      titleKey: 'auth.login.title' | 'auth.forgot-password.title' | 'auth.reset-password.title' | 'auth.otp.title';
    }
  > = {
    login: {
      component: () => <LoginForm />,
      titleKey: 'auth.login.title',
    },
    'forgot-password': {
      component: () => <ForgotPasswordForm />,
      titleKey: 'auth.forgot-password.title',
    },
    'password-reset': {
      component: () => <ResetPasswordForm />,
      titleKey: 'auth.reset-password.title',
    },
    otp: {
      component: () => <OTPForm />,
      titleKey: 'auth.otp.title',
    },
  };

  const { title, authCard } = convertKeysToCamelCase(style);

  // restrict the otp authState to only work if credentials are in appState
  const componentToUse = isAuthStateOTPWithNoCredentials({ state: { email, password }, auth: authState })
    ? 'login'
    : authState;
  const authComponent = authComponents[componentToUse];

  return (
    <div className={authCard}>
      <img src={conversedLogo} />
      <div className={title}>{t(authComponent.titleKey)}</div>
      {authComponent.component()}
    </div>
  );
};
