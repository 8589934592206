import domPurify from 'dompurify';

export const splitIntoLines = (text?: string) => {
  if (!text) {
    return '';
  }

  return text.split('\n').map((str, index) => {
    if (str !== '') {
      return <p key={index}>{str}</p>;
    }
    return <br key={index} />;
  });
};

export const purify = (text: string) => {
  // * Copied from webclient
  const dompurifyConfig = {
    ALLOWED_TAGS: ['br', 'a', 'img', 'li', 'ul', 'b', 'p'],
    ADD_ATTR: ['target', 'src'],
  };

  domPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    // TODO: Add hook for _parent if the page is in the same webpage, _blank otherwise
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  const purifiedText = domPurify.sanitize(text, dompurifyConfig);
  return purifiedText;
};
