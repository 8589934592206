import { useForm } from 'react-hook-form';
import { TCurrentUser } from '../../interfaces/IAuth';
import { useCurrentUserActions } from '../auth/useCurrentUserActions';
import { useMainSelector } from '../useMainSelector';
import chatAvatar from '../../assets/images/chat_avatar.png';
import { useMemo } from 'react';

type TUserAccountData = Pick<TCurrentUser, 'email' | 'firstName' | 'lastName'>;

export const useAccountSettingsForm = () => {
  const {
    user: { currentUser },
  } = useMainSelector();

  const { updateProfile, updateProfilePicture } = useCurrentUserActions();

  const { register, handleSubmit } = useForm<TUserAccountData>({
    shouldUseNativeValidation: true,
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      email: currentUser?.email,
    },
  });

  const uploadProfilePicture: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    const image = event.target?.files?.length ? event.target.files[0] : null;
    if (image) {
      updateProfilePicture(image);
    }
  };

  const profilePic = useMemo(() => currentUser?.profilePic || chatAvatar, [currentUser?.profilePic]);

  return {
    uploadProfilePicture,
    submit: handleSubmit<TUserAccountData>(updateProfile),
    register,
    profilePic,
    mfaEnabled: currentUser?.mfaEnabled,
  };
};
