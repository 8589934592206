import { useEffect } from 'react';
import useRoleActions from './useRoleActions';

export const useAssignableRolesInit = () => {
  const { fetchAssignableRoles } = useRoleActions();

  useEffect(() => {
    fetchAssignableRoles();
  }, [fetchAssignableRoles]);
};
