import { pushUnique } from '@conversed/shared/src/helpers/data.helpers';
import { useState, useCallback } from 'react';

export const useChips = (chips: string[], onChange: (...args: any[]) => void) => {
  const [inputValue, setInputValue] = useState('');
  const removeChip = useCallback(
    (chipToRemove: string) => {
      const remainingChips = chips.filter((chip) => chip !== chipToRemove);
      onChange(remainingChips);
    },
    [chips, onChange],
  );

  const removeLastChip = useCallback(() => {
    const remainingChips = chips.slice(0, -1);
    onChange(remainingChips);
  }, [chips, onChange]);

  const assignChip = useCallback(
    (chip?: string | null) => {
      if (!chip) return;
      setInputValue('');
      const values = pushUnique(chip.trim(), chips);
      onChange(values);
    },
    [chips, onChange],
  );

  return { inputValue, setInputValue, removeChip, removeLastChip, assignChip };
};
