import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useModal } from '../../../../hooks/modal/useModal';
import { useLabels } from '../../../../hooks/settings/labels/useLabels';
import { useLabelActions } from '../../../../hooks/settings/labels/useLabelActions';
import { useTranslation } from 'react-i18next';

export const DeleteLabelModal: React.FC = () => {
  const { cvRow, cvFooter, multipleButtons } = convertKeysToCamelCase(style);
  const { hideModal } = useModal();
  const { selectedLabel } = useLabels();
  const { t } = useTranslation();
  const { deleteLabel } = useLabelActions();

  return (
    <>
      {selectedLabel && (
        <Modal.Body>
          <p className={cvRow}>
            {t('settings.label.delete.confirmation')} <b>{selectedLabel.labelName}</b>?
          </p>
          <Modal.Footer className={`${cvFooter} ${multipleButtons}`}>
            <Button
              onClick={() => {
                deleteLabel(selectedLabel.identifier);
                hideModal();
              }}
            >
              {t('general.buttons.delete')}
            </Button>
            <Button
              onClick={() => {
                hideModal();
              }}
            >
              {t('general.buttons.abort')}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      )}
    </>
  );
};

export default DeleteLabelModal;
