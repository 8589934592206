import React, { useMemo } from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/Main.module.scss';
import { SettingsSidebarRoutes } from '../routes/SettingsSidebarRoutes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SETTINGS_PAGE_ROUTE } from '../../constants/routes';
import { usePermissions } from '../../hooks/usePermissions';

const useSettingsRoutes = () => {
  const { hasPermissions } = usePermissions();

  const routes = useMemo(
    () =>
      SettingsSidebarRoutes.flatMap((routeGroups) =>
        routeGroups.elements.map((route) => ({
          ...route,
          path: `${SETTINGS_PAGE_ROUTE}/${routeGroups.path}/${route.path}`,
        })),
      ).filter((route) => hasPermissions(route.permissions)),
    [hasPermissions],
  );

  return routes;
};

export const SettingsSidebarRouter = () => {
  const { settingsContentWrapper } = convertKeysToCamelCase(style);
  const routes = useSettingsRoutes();

  return (
    <div className={settingsContentWrapper}>
      {routes.map((route) => {
        return <Route key={route.path} path={route.path} component={route.component} exact={true} />;
      })}
    </div>
  );
};

export const SettingsContent: React.FC = () => {
  const routes = useSettingsRoutes();

  return (
    <>
      <Switch>
        <SettingsSidebarRouter />
      </Switch>
      <Route path={SETTINGS_PAGE_ROUTE} exact>
        <Redirect to={routes.length ? routes[0].path : '/'} />
      </Route>
    </>
  );
};

export default SettingsContent;
