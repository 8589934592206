import React from 'react';
import chatAvatar from '../../../assets/images/chat_avatar.png';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/MembersPage.module.scss';
import { Label } from '../../chat/Label';
import { useTranslation } from 'react-i18next';

interface Props {
  email: string;
  profilePic: string | null;
  firstName: string;
  lastName: string;
  mfaEnabled: boolean;
  isCurrentUser: boolean;
}

export const MemberAccountColumn: React.FC<Props> = ({
  email,
  profilePic,
  firstName,
  lastName,
  mfaEnabled,
  isCurrentUser,
}) => {
  const { userAvatar, infoWrapper } = convertKeysToCamelCase(style);

  const { t } = useTranslation();

  return (
    <div className={infoWrapper}>
      <div>
        <img src={profilePic || chatAvatar} className={userAvatar} />
      </div>
      <div>
        <p>
          {firstName} {lastName}
          {isCurrentUser && <Label labelColor="#0d532a" labelName={t('members-settings.labels.you')} />}
          {mfaEnabled && <Label labelColor="#064787" labelName={t('members-settings.labels.2fa')} />}
        </p>
        <p>{email}</p>
      </div>
    </div>
  );
};

export default MemberAccountColumn;
