import React from 'react';
import { UpdateLinkReqDTO } from '@conversed/shared/src/central/dto/link.dto';
import { useLinks } from '../../../../hooks/inboxes/useLinks';
import LinkDataModal from './LinkDataModal';
import { useLinkActions } from '../../../../hooks/inboxes/useLinkActions';

export const EditLinkModal: React.FC = () => {
  const { editLink } = useLinkActions();

  const { selectedLink } = useLinks();

  const onSubmit = async (linkData: UpdateLinkReqDTO) => {
    if (selectedLink?.shortLink) {
      editLink(selectedLink.shortLink, linkData);
    }
  };

  return (
    <LinkDataModal
      onSubmit={onSubmit}
      linkData={{
        shortLink: selectedLink?.shortLink,
        originalLink: selectedLink?.originalLink,
      }}
    />
  );
};

export default EditLinkModal;
