import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/settings-page/MembersPage.module.scss';
import MaterialIcon from '../../common/MaterialIcon';
import { RoleType } from '@conversed/shared/src/central/types/role.types';

interface Props {
  currentRoles: string[];
  availableRoles: { id: string; title: string; type: RoleType }[];
  toggleRoleForMember: (roleId: string) => void;
  disabled?: boolean;
}

export const MemberRoleColumn: React.FC<Props> = ({ currentRoles, availableRoles, toggleRoleForMember, disabled }) => {
  const { switchRoleDropdown, cvDropdownMenu, cvCurrentRolesWrapper, cvToggler } = convertKeysToCamelCase(style);

  return (
    <Dropdown as={ButtonGroup} className={switchRoleDropdown} bsPrefix={switchRoleDropdown}>
      <div className={cvCurrentRolesWrapper}>
        {currentRoles.map((roleId, index) => (
          <div key={index}>{availableRoles.find((role) => role.id === roleId)?.title}</div>
        ))}
      </div>
      {!disabled && <Dropdown.Toggle split id="dropdown-split-basic" className={cvToggler} />}

      <Dropdown.Menu className={cvDropdownMenu}>
        {availableRoles
          .filter((role) => role.type !== 'global')
          .map((role) => {
            return (
              <Dropdown.Item
                key={role.id}
                className={`d-flex justify-content-between`}
                onClick={() => toggleRoleForMember(role.id)}
              >
                <p>{role.title}</p>
                {currentRoles.includes(role.id) && <MaterialIcon icon="done" />}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MemberRoleColumn;
