import { useEffect } from 'react';
import { useCompanySelector } from '../../useCompanySelector';
import { useCompanySettingsActions } from './useCompanyActions';

export const useConnectionSettingsInit = () => {
  const { fetchConnectionSettings } = useCompanySettingsActions();
  const { selectedCompany } = useCompanySelector();

  useEffect(() => {
    fetchConnectionSettings();
  }, [fetchConnectionSettings, selectedCompany]);
};
