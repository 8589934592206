import React from 'react';

import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import { Form, Row, Col, Container } from 'react-bootstrap';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { TBlocksFormData } from '../../../../interfaces/TDesigner';
import { useTranslation } from 'react-i18next';

interface IProps {
  register: UseFormRegister<Partial<TBlocksFormData>>;
  blockData?: Partial<TBlocksFormData>;
  fieldName?: string;
}

export const BlockTextForm: React.FC<IProps> = ({ register, blockData, fieldName }) => {
  const { inputForm, input } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  if (!blockData?.text) return <></>;

  const { text } = blockData;
  const languages = Object.keys(text);

  return (
    <Row>
      <Container fluid>
        {languages.map((language, index) => {
          return (
            <Row key={index}>
              <Col>
                <Form.Group className={`${inputForm}`}>
                  <p>
                    {fieldName} - {language.toUpperCase()}
                  </p>
                  <Form.Control
                    as={'textarea'}
                    className={input}
                    {...register(`text.${language}`, { required: t('flow.validation.text.required') })}
                  />
                </Form.Group>
              </Col>
            </Row>
          );
        })}
      </Container>
    </Row>
  );
};
