import { TRole } from '@conversed/shared/src/central/types/role.types';
import { useCallback } from 'react';
import { getAssignableRolesApi, getRolesApi, putRolesApi } from '../../../api/role.api';
import { Actions } from '../../../constants/actionTypes';
import { toastErrorTranslated } from '../../../helpers/toast';
import { EApiStatus } from '../../../interfaces/EApiStatus';
import { useMainDispatch } from '../../useMainDispatch';
import { useMainSelector } from '../../useMainSelector';

export const useRoleActions = () => {
  const {
    settings: { roles },
  } = useMainSelector();
  const dispatch = useMainDispatch();

  const togglePermissionForRole = useCallback(
    (permission: string, role: TRole) => {
      dispatch({ type: Actions.TOGGLE_PERMISSION_FOR_ROLE, payload: { permission, role } });
    },
    [dispatch],
  );

  const getRoles = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: roles } = await getRolesApi();
      dispatch({ type: Actions.SET_ROLES, payload: roles });
      return EApiStatus.SUCCESS;
    } catch (error) {
      toastErrorTranslated('roles-settings.error.cannot-fetch');
      return EApiStatus.ERROR;
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const fetchAssignableRoles = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const { data: roles } = await getAssignableRolesApi();
      dispatch({
        type: Actions.SET_ASSIGNABLE_ROLES,
        payload: roles.map((role) => ({
          id: role.id,
          type: role.type,
          title: role.title,
        })),
      });
    } catch (error) {
      toastErrorTranslated('roles-settings.error.cannot-fetch');
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch]);

  const setRoles = useCallback(async () => {
    try {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: true } });
      const rolesWithPermissions = roles.map((role) => ({ id: role.id, permissions: role.permissions }));
      await putRolesApi(rolesWithPermissions);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: Actions.SET_UI, payload: { isLoading: false } });
    }
  }, [dispatch, roles]);

  return {
    fetchAssignableRoles,
    togglePermissionForRole,
    getRoles,
    setRoles,
  };
};

export default useRoleActions;
