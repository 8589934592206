import React from 'react';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import style from '../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import ContentTitle from './common/ContentTitle';
import { useTranslation } from 'react-i18next';
import { useRoleInit } from '../../hooks/settings/roles/useRoleInit';
import { RolesTable } from './roles-settings/RolesTable';
import { Button } from 'react-bootstrap';
import useRoleActions from '../../hooks/settings/roles/useRoleActions';

export const RolesSettings: React.FC = () => {
  const { error, loading } = useRoleInit();
  const { setRoles } = useRoleActions();

  const { settingsForm } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  if (error || loading) {
    return <></>;
  }

  return (
    <div className={settingsForm}>
      <ContentTitle title={t('roles-settings.title')} />
      <RolesTable />
      <Button onClick={() => setRoles()}>{t('general.buttons.save')}</Button>
    </div>
  );
};

export default RolesSettings;
