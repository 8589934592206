import React from 'react';
import chatAvatar from '../../../assets/images/chat_avatar.png';
import { Label } from '../Label';
import { TConversation } from '../../../interfaces/TChat';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { useTime } from '../../../hooks/useTime';
import { useFileMedia } from '../../../hooks/useFileMedia';

interface Props {
  chatUser: TConversation;
  isSelected: boolean;
}

export const ChatCard: React.FC<Props> = ({ chatUser, isSelected }) => {
  const { cvCardWrapper, cvCardName, active } = convertKeysToCamelCase(style);

  const { name, imageUrl, date, labels } = chatUser;

  const { relativeDate } = useTime(date);

  const { getImageByURL } = useFileMedia();
  const image = getImageByURL(imageUrl ?? chatAvatar);

  return (
    <div className={`${cvCardWrapper} ${isSelected ? active : 'bg-white'}`} role="button">
      <div className="d-flex flex-row align-items-center">
        <img className="circle" src={image} width={40} height={40} />
        <div className={cvCardName}>
          {name}
          <div className="d-flex flex-row flex-wrap">
            {labels?.length > 0 &&
              labels.map((label) => (
                <Label key={label.identifier} labelName={label.labelName} labelColor={label.labelColor} />
              ))}
          </div>
        </div>
      </div>
      <small className={`align-self-start m-1 text-muted`}>{relativeDate}</small>
    </div>
  );
};
