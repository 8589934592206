import { TFile } from '@conversed/shared/src/central/types/common.type';
import { useCallback } from 'react';
import { putBlockMediaApi } from '../api/flow.api';

export const useFileUpload = () => {
  const uploadFlowMediaFileAndGetURL = useCallback(async (file: TFile) => {
    const result = await putBlockMediaApi({ file });
    return result.data;
  }, []);

  return {
    uploadFlowMediaFileAndGetURL,
  };
};
