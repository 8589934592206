import { Button } from '@conversed/shared/src/central/types/blocks/Button';
import { StandAloneImage } from '@conversed/shared/src/central/types/blocks/StandAloneImage';
import { Text } from '@conversed/shared/src/central/types/blocks/Text';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocks } from '../../../hooks/flow/block/useBlocks';
import { useBlocksUpdates } from '../../../hooks/flow/block/useBlocksUpdates';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { EFlowDirection } from '../../../interfaces/EFlowDirection';
import { TBlock, TBlocksFormData, TBlockWithPosition } from '../../../interfaces/TDesigner';
import { BlockDataModal } from './BlockDataModal';

const blockTemplatesByType = {
  text: {
    id: '',
    type: 'text',
    textToSend: { en: '' },
    destination: [''],
  } as Text,
  StandAloneImage: {
    id: '',
    type: 'StandAloneImage',
    imageUrl: '',
    imageSource: 'url',
    destination: [''],
  } as StandAloneImage,
  default: {
    id: '',
    type: 'button',
    buttonText: { en: '' },
    destination: [''],
  } as Button,
} as const;

const isBlockTemplate = (type: string): type is keyof typeof blockTemplatesByType => {
  return ['text', 'StandAloneImage'].includes(type);
};

export const CreateBlockModal: React.FC = () => {
  const { addBlock } = useBlocksUpdates();
  const { flowDirection } = useFlow();
  const { newBlock } = useBlocks();
  const { t } = useTranslation();

  const addPositionDataToBlock = useCallback(
    (block: TBlock): TBlockWithPosition => {
      if (flowDirection === EFlowDirection.Vertical) {
        return {
          ...block,
          verticalPosition: newBlock?.position,
        };
      }

      return {
        ...block,
        horizontalPosition: newBlock?.position,
      };
    },
    [newBlock, flowDirection],
  );

  const getBlockTemplateByType = useCallback((type?: string) => {
    if (!type || !isBlockTemplate(type)) {
      return blockTemplatesByType['default'];
    }

    return blockTemplatesByType[type];
  }, []);

  const onSubmit = useCallback(
    async (blockData: TBlocksFormData) => {
      const block = getBlockTemplateByType(newBlock?.type);
      const blockWithPosition = addPositionDataToBlock(block);

      if (!blockData.id) {
        return;
      }

      addBlock({ ...blockWithPosition, id: blockData.id });
    },
    [getBlockTemplateByType, newBlock?.type, addPositionDataToBlock, addBlock],
  );

  return (
    <>
      <BlockDataModal type={'creation'} description={t('flow.blocks.id.desc')} onSubmit={onSubmit} />
    </>
  );
};

export default CreateBlockModal;
