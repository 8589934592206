import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { getMessagesOfConversationApi, getConversationByIdApi, getConversationByInboxApi } from '../api/chatApi';
import { getInboxesOfAgentApi } from '../api/inboxApi';
import { Actions } from '../constants/actionTypes';
import { CHAT_PAGE_ROUTE } from '../constants/routes';
import { toastErrorTranslated } from '../helpers/toast';
import { useSelectedChatStatus } from './chat/useSelectedChatStatus';
import { useMainDispatch } from './useMainDispatch';
import { useSelectedInbox } from './chat/useSelectedInbox';

export const useChatActions = () => {
  const { chatStatus } = useSelectedChatStatus();
  const { selectedInboxId } = useSelectedInbox();

  const dispatch = useMainDispatch();
  const history = useHistory();

  const getConversationsList = useCallback(async () => {
    try {
      const { data: chatUsers } = await getConversationByInboxApi(chatStatus, selectedInboxId);
      dispatch({
        type: Actions.SET_CHAT,
        payload: {
          chatList: chatUsers,
        },
      });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch, selectedInboxId, chatStatus]);

  const getConversationMessages = useCallback(
    async (conversationId: string) => {
      try {
        const { data: messages } = await getMessagesOfConversationApi(conversationId);
        dispatch({
          type: Actions.SET_CHAT_MESSAGES,
          payload: messages,
        });
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  const getConversationById = useCallback(
    async (conversationId: string) => {
      try {
        const { data: conversation } = await getConversationByIdApi(conversationId);

        dispatch({
          type: Actions.SET_CHAT,
          payload: {
            selectedConversation: conversation,
          },
        });
      } catch (error) {
        history.push(CHAT_PAGE_ROUTE);
      }
    },
    [dispatch, history],
  );

  const getAvailableInboxesForConversations = useCallback(async () => {
    try {
      const { data: inboxes } = await getInboxesOfAgentApi();

      dispatch({
        type: Actions.SET_CHAT,
        payload: { inboxes },
      });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  return {
    getConversationsList,
    getConversationMessages,
    getConversationById,
    getAvailableInboxesForConversations,
  };
};
