import React from 'react';
import { ChatCard } from './ChatCard';
import { useChatList } from '../../../hooks/chat/useChatList';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import style from '../../../assets/scss/components/chat/chat-sidebar/ChatSidebar.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { CHAT_PAGE_ROUTE } from '../../../constants/routes';
import { EBreakpoint } from '../../../constants/breakpoints';
import { useMedia } from '../../../hooks/useMedia';

export const ChatList: React.FC = () => {
  const { chatlistMessageWrapper } = convertKeysToCamelCase(style);

  const { search } = useLocation();

  const { chatList, selectedConversation } = useChatList();

  const { down: isMobile } = useMedia(EBreakpoint.MD);

  return (
    <div className={chatlistMessageWrapper}>
      {chatList.map((chatUser) => (
        <div key={chatUser.id} className="mt-1">
          <Link to={{ pathname: `${CHAT_PAGE_ROUTE}/${chatUser.id}`, search }}>
            <ChatCard chatUser={chatUser} isSelected={!isMobile && chatUser?.id === selectedConversation?.id} />
          </Link>
        </div>
      ))}
    </div>
  );
};
