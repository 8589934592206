import React from 'react';
import { ChatPanelContent } from './ChatPanelContent';
import styles from '../../../assets/scss/components/chat/ChatPanel.module.scss';
import { useMainSelector } from '../../../hooks/useMainSelector';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { MaterialIcon } from '../../common/MaterialIcon';
import { EmptyState } from '../../common/EmptyState';
import { useTranslation } from 'react-i18next';

interface IChatPanelProps {
  toggleSidebar: () => void;
  sidebarState: boolean;
}

export const ChatPanel: React.FC<IChatPanelProps> = ({ sidebarState, toggleSidebar }) => {
  const {
    chat: { selectedConversation },
  } = useMainSelector();
  const { t } = useTranslation();
  const { sidebarButton, toggleButton, toggleButtonClose, emptyChatState } = convertKeysToCamelCase(styles);
  const sideBarButtonClass = sidebarState ? toggleButton : toggleButtonClose;
  const sideBarButtonIcon = sidebarState ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left';

  return selectedConversation ? (
    <div className={'d-flex bg-light flex-column justify-content-between flex-grow-1'}>
      <div className={`d-flex flex-row flex-grow-1`}>
        <ChatPanelContent />
        <div
          className={`justify-content-center align-items-center ${sidebarButton} ${sideBarButtonClass}`}
          role="button"
          onClick={() => toggleSidebar()}
        >
          <MaterialIcon icon={sideBarButtonIcon} />
        </div>
      </div>
    </div>
  ) : (
    <div className={emptyChatState}>
      <EmptyState message={t('chat.panel.empty-state')} />
    </div>
  );
};
