import { CompanyDTO } from '@conversed/shared/src/central/dto/company';
import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import settingsStyle from '../../assets/scss/components/settings-page/SettingsPlatform.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelpers';
import { useCompanySettingsActions } from '../../hooks/settings/company/useCompanyActions';
import GeneralSettings from '../settings-page/GeneralSettings';
import companySelectorStyle from '../../assets/scss/components/pages/CompanySelectorPage.module.scss';
import conversedLogo from '../../assets/images/logo_black.png';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../common/MaterialIcon';
import { useHistory } from 'react-router-dom';

export const NewCustomerPage: React.FC = () => {
  const { createCompany } = useCompanySettingsActions();

  const { register, handleSubmit } = useForm<CompanyDTO>({
    shouldUseNativeValidation: true,
  });

  const hideAndSubmit = (customer: CompanyDTO) => {
    createCompany(customer);
  };

  const { t } = useTranslation();
  const history = useHistory();

  const { wrapper, content, copyright, title, cardContainer, contentWrapper } =
    convertKeysToCamelCase(companySelectorStyle);

  const { sectionCategory, inputForm, form, inputGroup, input } = convertKeysToCamelCase(settingsStyle);

  return (
    <div className={`${wrapper} `}>
      <div className="m-2">
        <MaterialIcon role="button" icon="close" onClick={() => history.goBack()} />
      </div>
      <div>
        <div className={`w-100 d-flex justify-content-center align-items-center ${contentWrapper}`}>
          <div className={`w-100 h-100 d-flex justify-content-center flex-column align-items-center ${content}`}>
            <img src={conversedLogo} />
            <div className={`${title} mx-3`}>{t('new-customer.add-customer')}</div>
            <div className={`w-100 ${cardContainer} `}>
              <div className={`${sectionCategory} ${form}`}>
                <h5>{t('new-customer.company.title')}</h5>
                <Form onSubmit={handleSubmit<CompanyDTO>(hideAndSubmit)}>
                  <Row className={`${inputGroup}`}>
                    <Form.Group as={Col} className={`${inputForm}`}>
                      <p>{t('new-customer.company.id')}</p>
                      <Form.Control
                        {...register('identifier', { required: t('general.validation.required') })}
                        className={input}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className={`${inputForm}`}>
                      <p>{t('new-customer.company.name')}</p>
                      <Form.Control
                        {...register('name', { required: t('general.validation.required') })}
                        className={input}
                      />
                    </Form.Group>

                    <Form.Group as={Col} className={`${inputForm}`}>
                      <p>{t('new-customer.company.website')}</p>
                      <Form.Control
                        {...register('websiteUrl', { required: t('general.validation.required') })}
                        className={input}
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group as={Col} className={`${inputForm} mb-2`}>
                    <p>{t('new-customer.company.logo')}</p>
                    <Form.Control
                      {...register('icon', { required: t('general.validation.required') })}
                      className={input}
                    />
                  </Form.Group>

                  <h5>{t('new-customer.connection.title')}</h5>

                  <Form.Group as={Col} className={`${inputForm} mt-2`}>
                    <p>{t('new-customer.connection.database')}</p>
                    <Form.Control
                      className={input}
                      {...register('dbConnection', { required: t('general.validation.required') })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className={`${inputForm}`}>
                    <p>{t('new-customer.connection.backend')}</p>
                    <Form.Control
                      className={input}
                      {...register('backendConnection', { required: t('general.validation.required') })}
                    />
                  </Form.Group>

                  <Button type="submit" className={`mt-4`}>
                    <span>{t('general.buttons.create')}</span>
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={copyright}>{t('general.copyright.label', { date: new Date().getFullYear().toString() })}</div>
    </div>
  );
};

export default GeneralSettings;
