import React from 'react';
import ContentTitle from './common/ContentTitle';

import EmptyState from '../common/EmptyState';
import { useFacebookPagesInit } from '../../hooks/settings/facebook/useFacebookPagesInit';
import { useFacebookPages } from '../../hooks/settings/facebook/useFacebookPages';
import { useFacebookPagesActions } from '../../hooks/settings/facebook/useFacebookPagesActions';
import { useModal } from '../../hooks/modal/useModal';
import { SettingsCardContainer } from './common/card/SettingsCardContainer';
import SettingsCard from './common/card/SettingsCard';
import { useHandleFacebookConnectionError } from '../../hooks/settings/facebook/useHandleFacebookConnectionError';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../hooks/usePermissions';
import { PERMISSIONS } from '@conversed/shared/src/central/constants/permissions';

export const FacebookSettings: React.FC = () => {
  useHandleFacebookConnectionError();
  useFacebookPagesInit();
  const { facebookPages, setSelectedFacebookPage } = useFacebookPages();
  const { connectFacebookPage } = useFacebookPagesActions();
  const { getSelectedFacebookPageSettings } = useFacebookPagesActions();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();

  return (
    <>
      <ContentTitle title={t('facebook-settings.title')} onClickInfo={() => showModal('facebook-info')} />
      {facebookPages?.length ? (
        <SettingsCardContainer>
          {facebookPages.map((facebookPage) => {
            const editAction = hasPermissions([PERMISSIONS.FACEBOOK.FACEBOOK_EDIT])
              ? [
                  {
                    icon: 'border_color',
                    action: () => {
                      setSelectedFacebookPage(facebookPage.id);
                      getSelectedFacebookPageSettings(facebookPage.id);
                      showModal('facebook-settings', facebookPage.pageName);
                    },
                  },
                ]
              : [];
            return <SettingsCard key={facebookPage.pageName} title={facebookPage.pageName} actions={[...editAction]} />;
          })}
        </SettingsCardContainer>
      ) : (
        <EmptyState
          message={t('facebook-settings.empty-state')}
          addButton={hasPermissions([PERMISSIONS.FACEBOOK.FACEBOOK_CREATE]) ? () => connectFacebookPage() : undefined}
        />
      )}
    </>
  );
};
export default FacebookSettings;
