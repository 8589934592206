import { useCallback } from 'react';
import { meApi, updateProfileApi, updateProfilePictureApi } from '../../api/userApi';
import { Actions } from '../../constants/actionTypes';
import { DataKeys } from '../../constants/login';
import { TCompany } from '../../interfaces/IAuth';
import { useMainDispatch } from '../useMainDispatch';
import { UserProfileUpdateDTO } from '@conversed/shared/src/central/dto/user.dto';
import { toastErrorTranslated, toastSuccessTranslated } from '../../helpers/toast';
import { resizeProfilePic } from '../../helpers/imageHelper';

const getSelectedCompany = (companies: TCompany[]) => {
  if (companies.length === 1) {
    const [company] = companies;
    localStorage.setItem(DataKeys.SELECTED_COMPANY_DATA_KEY, company.identifier);
    return company;
  }

  const selectedCompanyId = localStorage.getItem(DataKeys.SELECTED_COMPANY_DATA_KEY);

  return companies.find((company) => company.identifier === selectedCompanyId);
};

export const useCurrentUserActions = () => {
  const dispatch = useMainDispatch();

  const fetchCurrentUser = useCallback(async () => {
    const { data: userData } = await meApi();

    const selectedCompany = getSelectedCompany(userData.companies);
    dispatch({
      type: Actions.SET_USER,
      payload: {
        currentUser: {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          profilePic: userData.profilePic,
          selectedCompanyId: selectedCompany?.identifier,
          mfaEnabled: userData.mfaEnabled,
          companies: userData.companies.map((company) => ({
            identifier: company.identifier,
            name: company.name,
            permissions: company.permissions,
            skills: company.skills,
            icon: company.icon,
            websiteUrl: company.websiteUrl,
          })),
        },
        permissions: selectedCompany?.permissions || [],
      },
    });
  }, [dispatch]);

  const updateProfile = useCallback(
    async (profile: UserProfileUpdateDTO) => {
      try {
        const { data: profileUpdated } = await updateProfileApi(profile);
        dispatch({
          type: Actions.UPDATE_CURRENT_USER,
          payload: {
            firstName: profileUpdated.firstName,
            lastName: profileUpdated.lastName,
            email: profileUpdated.email,
          },
        });
        toastSuccessTranslated('account-settings.profile.update-profile.toast.success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  const updateProfilePicture = useCallback(
    async (profilePicFile: File) => {
      dispatch({
        type: Actions.SET_UI,
        payload: {
          isLoading: true,
        },
      });
      try {
        const profilePicBase64 = await resizeProfilePic(profilePicFile);
        const { data: profileUpdated } = await updateProfilePictureApi({ profilePicBase64 });
        dispatch({
          type: Actions.UPDATE_CURRENT_USER,
          payload: {
            profilePic: profileUpdated?.profilePic,
          },
        });
        toastSuccessTranslated('account-settings.profile.update-profile-picture.toast.success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      } finally {
        dispatch({
          type: Actions.SET_UI,
          payload: {
            isLoading: false,
          },
        });
      }
    },
    [dispatch],
  );

  return { fetchCurrentUser, updateProfile, updateProfilePicture };
};
