import { ArrowHeadType, Edge, Node, Position } from 'react-flow-renderer';
import { EFlowDirection } from '../interfaces/EFlowDirection';
import { TGetLoopBackStepEdge, TNodeType } from '../interfaces/TDesigner';

export const getBlockTypeByNode = (nodeType: TNodeType) => {
  switch (nodeType) {
    case 'textNode':
      return 'text';
    case 'buttonNode':
      return 'button';
    case 'dataNode':
      return 'databaseUpdate';
    case 'imageNode':
      return 'StandAloneImage';
    case 'routeNode':
      return 'Decider';
    case 'flowNode':
      return 'text';
  }
};
export const getHandlePositionByFlowDirection = (flowDirection: EFlowDirection) => {
  if (flowDirection === EFlowDirection.Vertical) {
    return {
      start: Position.Top,
      end: Position.Bottom,
      middle: Position.Right,
    };
  }

  return {
    start: Position.Left,
    end: Position.Right,
    middle: Position.Bottom,
  };
};

const getNodeDimensions = (nodes: Node[]) =>
  nodes.map((node) => {
    if (!node?.__rf) {
      return {
        height: 1,
        width: 1,
      };
    }

    return {
      height: node.__rf.height ?? 1,
      width: node.__rf.width ?? 1,
    };
  });

export const getLoopbackStepEdge = (options: TGetLoopBackStepEdge) => {
  const { source, target, sourceXYP, targetXYP } = options;
  const { x: sourceX, y: sourceY } = sourceXYP;
  const { x: targetX, y: targetY } = targetXYP;

  const sourceIsOnLeftSide = sourceX > targetX;

  const padding = 24;
  const startPoint = `${sourceX},${sourceY}`;
  const endPoint = `${targetX},${targetY}`;

  const [{ width: sourceWidth, height: sourceHeight }, { height: targetHeight }] = getNodeDimensions([source, target]);

  //*p1 is slightly under source
  const p1 = sourceIsOnLeftSide
    ? `${sourceX},${sourceY + sourceHeight / 2 + padding}`
    : `${sourceX},${sourceY + sourceHeight / 2 + padding}`;

  //*p2 is on either the bottom left or bottom right side from source
  const p2 = sourceIsOnLeftSide
    ? `${sourceX - sourceWidth / 2 - padding},${sourceY + sourceHeight / 2 + padding}`
    : `${sourceX + sourceWidth / 2 + padding},${sourceY + sourceHeight / 2 + padding}`;

  //*p3 is on either the top left or top right side from target
  const p3 = sourceIsOnLeftSide
    ? `${sourceX - sourceWidth / 2 - padding},${targetY - targetHeight / 2 - padding}`
    : `${sourceX + sourceWidth / 2 + padding},${targetY - targetHeight / 2 - padding}`;

  //*p4 is slightly on top of target
  const p4 = sourceIsOnLeftSide
    ? `${targetX},${targetY - targetHeight / 2 - padding}`
    : `${targetX},${targetY - targetHeight / 2 - padding}`;

  //* Draw 4 perpendicular lines from startPoint to endpoint
  const d1 = `M${startPoint} ${p1} ${p2}`;
  const d2 = `M${p2} ${p3} ${p3}`;
  const d3 = `M${p3} ${p4} ${endPoint}`;

  return `${d1}${d2}${d3}`;
};

const edgeStyles = {
  entry: {
    style: { stroke: '#000' },
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
  destination: {
    style: { stroke: '#000' },
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
  contains: {
    style: { stroke: '#f6ab6c' },
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
  decider: {
    style: { stroke: '#000' },
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
  validator: {
    style: { stroke: '#33daca' },
    arrowHeadType: ArrowHeadType.ArrowClosed,
  },
} as const;

export const parseEdge = (
  id: string,
  source: string,
  destination: string,
  style: keyof typeof edgeStyles,
  handle?: 'end' | 'mid',
): Edge => {
  const { arrowHeadType, style: edgeStyle } = edgeStyles[style];

  return {
    id: id,
    type: 'loopbackStep',
    source,
    sourceHandle: handle ?? 'end',
    style: edgeStyle,
    arrowHeadType: arrowHeadType,
    target: destination,
  };
};
