import { useCallback } from 'react';
import { useMainSelector } from './useMainSelector';

export const usePermissions = () => {
  const {
    user: { permissions },
  } = useMainSelector();

  const hasPermissions = useCallback(
    (requiredPermissions?: string[]) => {
      if (!permissions) {
        return false;
      }
      if (!requiredPermissions) {
        return true;
      }
      return requiredPermissions.every((requiredPermission) => permissions.includes(requiredPermission));
    },
    [permissions],
  );

  return { hasPermissions };
};
