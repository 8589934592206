import React from 'react';
import { Control } from 'react-hook-form';
import BooleanInput from '../../../../input/BooleanInput';
import { TFacebookSettings } from '../../../../../interfaces/TFacebook';
import FacebookCallToActionList from './FacebookCallToActionList';
import { Form } from 'react-bootstrap';
import DropDownInput from '../../../../input/DropDownInput';
import { FB_SUPPORTED_LOCALES } from '@conversed/shared/src/central/constants/locales';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../../../../helpers/baseHelpers';
import style from '../../../../../assets/scss/components/common/Modal.module.scss';

type Props = {
  name: string;
  control: Control<TFacebookSettings>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const FacebookPersistentMenuItem: React.FC<Props> = ({ name, control, ...rest }) => {
  const { t } = useTranslation();

  const { pseudoSectionTitle, sectionTitle } = convertKeysToCamelCase(style);

  return (
    <div {...rest}>
      <p className={pseudoSectionTitle}>{t('settings.facebook.persistent-menu.menu-title')}</p>
      <DropDownInput
        horizontal
        label={t('settings.facebook.persistent-menu.locale')}
        name={name + 'locale'}
        register={control.register}
        values={FB_SUPPORTED_LOCALES}
      />
      <BooleanInput
        label={t('settings.facebook.persistent-menu.input-disabled')}
        name={name + 'inputDisabled'}
        register={control.register}
      />
      <Form.Label className={`${sectionTitle} mt-4`}>{t('settings.facebook.persistent-menu.actions')}</Form.Label>
      <FacebookCallToActionList name={`${name}actions`} control={control} />
    </div>
  );
};

export default FacebookPersistentMenuItem;
