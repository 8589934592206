import { useCallback } from 'react';
import { enableMFAApi, initMFAApi, disableMFAApi } from '../../api/userApi';
import { Actions } from '../../constants/actionTypes';
import { toastErrorTranslated, toastSuccessTranslated } from '../../helpers/toast';
import { useMainDispatch } from '../useMainDispatch';

export const useMfaActions = () => {
  const dispatch = useMainDispatch();

  const initMFA = useCallback(async () => {
    try {
      const { data } = await initMFAApi();
      dispatch({
        type: Actions.UPDATE_CURRENT_USER,
        payload: {
          mfaData: {
            qrCode: data.qrCode,
            name: data.name,
            key: data.key,
          },
        },
      });
    } catch (error) {
      console.error(error);
      toastErrorTranslated(error);
    }
  }, [dispatch]);

  const enableMFA = useCallback(
    async (token: string) => {
      try {
        await enableMFAApi({ token });
        dispatch({
          type: Actions.UPDATE_CURRENT_USER,
          payload: {
            mfaEnabled: true,
          },
        });
        toastSuccessTranslated('account-settings.2fa.modals.enable.success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  const disableMFA = useCallback(
    async (password: string, token: string) => {
      try {
        await disableMFAApi({ password, token });
        dispatch({
          type: Actions.UPDATE_CURRENT_USER,
          payload: {
            mfaEnabled: false,
          },
        });
        toastSuccessTranslated('account-settings.2fa.modals.disable.success');
      } catch (error) {
        console.error(error);
        toastErrorTranslated(error);
      }
    },
    [dispatch],
  );

  return { initMFA, enableMFA, disableMFA };
};
