import React from 'react';
import { Button, Modal, Form, Row, Col, Container } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../../../../helpers/baseHelpers';
import style from '../../../../assets/scss/components/common/Modal.module.scss';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks/modal/useModal';
import { CreateAgentRequestDTO } from '@conversed/shared/src/central/dto/agent.dto';
import { useTranslation } from 'react-i18next';
import ChipInput from '../../../input/chip/ChipInput';

type Props = {
  agentData?: Partial<CreateAgentRequestDTO>;
  onSubmit: (data: CreateAgentRequestDTO) => void;
};

interface IAgentFormData {
  firstName: string;
  lastName: string;
  email: string;
  skills: string[];
}

export const AgentDataModal: React.FC<Props> = ({ agentData, onSubmit }) => {
  const { inputForm, input, cvRow, cvFooter } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const { register, handleSubmit, control } = useForm<IAgentFormData>({
    shouldUseNativeValidation: true,
    defaultValues: agentData,
  });

  const { hideModal } = useModal();

  const hideAndSubmit = (data: IAgentFormData) => {
    onSubmit(data);
    hideModal();
  };

  return (
    <>
      <Modal.Body>
        <Form onSubmit={handleSubmit<IAgentFormData>(hideAndSubmit)}>
          <Row>
            <Container fluid>
              <Row className={cvRow}>
                <Col>
                  <Form.Group className={`${inputForm}`}>
                    <p>{t('settings.agent.first-name')}</p>
                    <Form.Control
                      className={input}
                      {...register('firstName', { required: t('settings.agent.validation.first-name') })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className={`${inputForm}`}>
                    <p>{t('settings.agent.last-name')}</p>
                    <Form.Control
                      className={input}
                      {...register('lastName', { required: t('general.validation.required') })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
            <Form.Group className={`${cvRow}`}>
              <p>{t('settings.agent.email')}</p>
              <Form.Control className={input} {...register('email', { required: t('general.validation.required') })} />
            </Form.Group>
            <ChipInput name={'skills'} label={t('settings.agent.skills')} register={register} control={control} />
          </Row>
          <Modal.Footer className={cvFooter}>
            <Button type="submit">{t('general.buttons.save')}</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </>
  );
};

export default AgentDataModal;
