import { Handle } from 'react-flow-renderer';
import { convertKeysToCamelCase } from '../../../helpers/baseHelpers';
import { getHandlePositionByFlowDirection } from '../../../helpers/flowHelper';
import style from '../../../assets/scss/components/designer/nodes/FlowNode.module.scss';
import { useFlow } from '../../../hooks/flow/flow/useFlow';
import { useTranslation } from 'react-i18next';

export const EntryNode: React.FC = () => {
  const { nodeEntry, nodeSeparator, nodeBody } = convertKeysToCamelCase(style);

  const { flowDirection } = useFlow();
  const { t } = useTranslation();
  const { start, end } = getHandlePositionByFlowDirection(flowDirection);

  return (
    <div className={nodeEntry}>
      <Handle type="target" position={start} id="start" />
      <hr className={nodeSeparator} />
      <div className={nodeBody}>
        <p>{t('flow.blocks.entry.text')}</p>
      </div>
      <Handle type="source" position={end} id="end" />
    </div>
  );
};
