import { useCallback, useMemo } from 'react';
import { Actions } from '../../constants/actionTypes';
import { TChatListFilter, TConversation } from '../../interfaces/TChat';
import { useMainDispatch } from '../useMainDispatch';
import { useMainSelector } from '../useMainSelector';
import { useChatInboxes } from './useChatInboxes';
import { useChatStatus } from './useChatStatus';
import { SEARCH_LABEL_ALL, useSearchLabel } from './useSearchLabel';
import { useSearchString } from './useSearchString';
import { useSelectedChatList } from './useSelectedChatList';

export const useChatList = () => {
  const {
    chat: { chatList, selectedConversation },
    user: { currentUser },
  } = useMainSelector();
  const { selectedInbox, filteredInboxes } = useChatInboxes();
  const { chatStatus } = useChatStatus();
  const dispatch = useMainDispatch();
  const { searchLabel, setSearchLabel } = useSearchLabel();
  const { searchString, setSearchString } = useSearchString();

  const { selectedChatList, setSelectedChatList } = useSelectedChatList();

  const chatTabFilters: Record<TChatListFilter, (chat: TConversation) => boolean> = useMemo(
    () => ({
      'assigned-to-me': (chat: TConversation) => chat.assignedAgent?.id === currentUser?.id,
      unassigned: (chat: TConversation) => !chat.assignedAgent,
    }),
    [currentUser?.id],
  );

  // * Check if queued users should be shown to the current agent
  // * Filter queued users based on, search param and selected chatlist
  const filteredChatList = useMemo(
    () =>
      chatList
        .filter(
          // * Filter on inbox
          ({ inbox: userInbox }) =>
            !userInbox || // * Accept also users without a queue (for now)
            selectedInbox?.identifier === userInbox || // * If agent is in queue mode, check if user belongs to this queue
            (!selectedInbox && filteredInboxes?.find((inbox) => inbox.identifier === userInbox)), // * Else check if user is in any queue the agent has access to
        )
        .filter(
          // * Filter on search label
          (conversation) =>
            searchLabel === SEARCH_LABEL_ALL || conversation.labels.find((label) => label.identifier === searchLabel),
        )
        // * Filter on search query
        .filter(({ name }) => name.toLowerCase().includes(searchString.toLowerCase()))
        // * Filter on selected chat status
        .filter((conversation) => conversation.status === chatStatus),
    [chatList, selectedInbox, filteredInboxes, searchLabel, searchString, chatStatus],
  );

  // * Filter on selected opened tab
  const filteredChatListByTab = useMemo(
    () => filteredChatList.filter(chatTabFilters[selectedChatList]),
    [chatTabFilters, filteredChatList, selectedChatList],
  );

  // * User counts for sidebar panel
  // ? Consider which count to show on search queries (search filtered or unfiltered)
  const assignedUserCount = useMemo(
    () => filteredChatList.filter(chatTabFilters['assigned-to-me']).length,
    [chatTabFilters, filteredChatList],
  );
  const unassignedUserCount = useMemo(
    () => filteredChatList.filter(chatTabFilters['unassigned']).length,
    [chatTabFilters, filteredChatList],
  );

  const setSelectedChatUser = useCallback(
    (chatUserId: string) => {
      const chatUser = chatList.find(({ id }) => id === chatUserId);
      dispatch({
        type: Actions.SET_CHAT,
        payload: {
          selectedConversation: chatUser,
        },
      });
    },
    [chatList, dispatch],
  );

  return {
    chatList: filteredChatListByTab,
    unassignedUserCount,
    assignedUserCount,
    selectedChatList,
    setSelectedChatList,
    setSearchString,
    searchString,
    selectedConversation,
    setSelectedChatUser,
    searchLabel,
    setSearchLabel,
  };
};
